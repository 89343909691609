import axios from 'axios'

export default function use() {

    const index = async(treatmentId = '') => {
        const lang = window.localStorage.getItem('lang');

        const queryString = treatmentId ? '?treatment_uuid=' + treatmentId + `&lang=${lang}` : `?lang=${lang}`

        console.log(queryString)

        let response = await axios.get('/api/documents' + queryString ).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let documents = response.data

        console.log(documents);

        return Promise.resolve(documents)
    }

    return {
        index
    }

}