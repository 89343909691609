<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 flex flex-col surface-2 rounded p-3">
            <h1 class="heading-3-medium text-1">{{ $t('testOrderView.orderOfTests') }}</h1>
            <div class="h-1 hr-divider mb-4"></div>

            <div class="flex-1 flex flex-col surface-1 rounded">
                <div class="wrapper rounded flex-1 p-3">

                    <div class="test-order-panel rounded lg:w-1/2 xl:w-1/3 p-1">
                        <draggable
                            v-model="tests"
                            :animation="200"
                            ghost-class="moving-item"
                            handle=".handle">
                            <div v-for="(element, index) in tests" :key="index" class="test-order-item">
                                <h1 class="heading-5-bold-s p-2 test-item-title text-1">{{ getTestIndexLabel(index + 1, $t('testOrderView.Schritt')) }} </h1>

                                <div class="m-2 p-2 item-outter-wrapper rounded-lg ">
                                    <div class="item-inner-wrapper h-16 px-3 py-2 rounded-lg relative shadow-md">
                                        <div class="item-title heading-6-bold-s text-light w-10/12">{{ element.test.name }}</div>
                                        <div class="handle text-light absolute top-0 right-0 p-3 cursor-move flex item justify-center"><IconDrag /></div>

                                    </div>
                                </div>
                            </div>
                        </draggable>
                    </div>

                </div>

                <div class="input-group-btn flex items-center mt-3">
                    <button @click="goToFastTestSequentce()" class="button-sm-outlined flex items-center ml-3 px-4 py-2 radius-sm">
                      {{ $t('testOrderView.quickEntry') }}
                    </button>
                    <button @click="showSaveAsStandardConfirmationModal()" class="button-sm button-warning ml-2 flex items-center text-1 px-4 py-2 radius-sm leading-4">
                      {{ $t('testOrderView.saveAsDefault') }}
                    </button>
                </div>

                <div class="mt-4 p-3">
                    <AppWizard step="3" stepRoute="test-sequence-test-order" @switched="handleStepSwitch" />
                </div>
            </div>
        </div>
    </div>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="saveAsStandardConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">{{ $t('testOrderView.sequenceOfMeasurementProcedures') }}</h1>
                <p class="w-80 mx-auto mt-7 leading-4">
                  {{ $t('testOrderView.confirmOrderAndNote') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="saveAsStandard()" class="button-primary w-96 mx-auto">
                      {{ $t('testOrderView.confirm') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeSaveAsStandardConfirmationModal()" class="button-primary-outlined w-96 mx-auto">
                        {{ $t('testOrderView.back') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>
</template>
<script setup>

import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { VueDraggableNext as draggable} from 'vue-draggable-next'
import { useI18n } from 'vue-i18n';

import AppTooltip from '@/views/components/common/AppTooltip.vue'
import AppWizard from '@/views/components/common/AppWizard.vue'
import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

import IconDrag from '@/views/components/icons/IconDrag.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const lang = route?.query?.lang || 'de';

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

const saveAsStandardConfirmationModal = ref()

let getTestIndexLabel = function(index, suffix) {
    const orderedNumbers = {
        '1': 'Erster',
        '2': 'Zweiter',
        '3': 'Dritter',
        '4': 'Vierter',
        '5': 'Fünfter',
        '6': 'Sechster',
        '7': 'Siebter',
        '8': 'Achter',
        '9': 'Neunter',
        '10': 'Zehnter',
    }

    return t('testOrderView.' + orderedNumbers[index]) + ' ' + suffix
}

let tests = computed({
    get: () => store.getters['tests/all'],
    set: (value) => {
        value = toRaw(value)

        let uuids = value.map(function(step) { return step.uuid })

        store.dispatch('tests/updateTestSortOrder', { patientId, treatmentId, uuids })
    }
})

let handleStepSwitch = async function({ routeName }) {

    //await store.dispatch('questions/clearQuestions')

    router.push({
        name: routeName,
        params: {
            ...route.params,
            patientId,
            treatmentId,
            test: 1
        },
        query: {
          lang
        }
    })
}

let saveAsStandard = async function() {

    let uuids = tests.value.map(function(step) { return step.uuid })

    await store.dispatch('tests/updateTestSortOrder', { patientId, treatmentId, uuids, remember: true })

    closeSaveAsStandardConfirmationModal()
}

const showSaveAsStandardConfirmationModal = () => {
    saveAsStandardConfirmationModal.value.show()
}

const closeSaveAsStandardConfirmationModal = () => {
    saveAsStandardConfirmationModal.value.close()
}

let goToFastTestSequentce = function() {
    router.push({
        name: 'test-sequence-fast',
        params: {
            ...route.params,
            patientId,
            treatmentId
        },
        query: {
          lang
        }
    })
}

onMounted(async () => {
    console.log(treatmentId)
    console.log('test order')

    await store.dispatch('tests/all', { patientId, treatmentId, lang })
})

</script>
<style scoped>
    .test-order-panel {
        border: 1px solid var(--color-primary-400);
        background: var(--color-primary-50);
    }
    .test-item-title {
        border-bottom: 2px dotted var(--color-shade-0);
    }

    .item-outter-wrapper {
        /*background-color: #e3f5ff;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");*/
        background: var(--color-primary-1);
    }

    .item-inner-wrapper {
        background: var(--color-shade-1);
    }

    .moving-item {
        opacity: 0.8;
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-400);
    }

    .sortable-drag {
        opacity: 0;
    }

    .button-sm-outlined {
        font-family: 'Overpass', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        border-radius: 4px;
        line-height: 19px;
        border-radius: 8px;
        color: var(--color-primary-500);
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-500);
        transition: all .13s ease-in;

    }
</style>