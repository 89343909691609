import axios from 'axios'

export default function use() {

    const index = async(patientId) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.get(`/api/patients/${patientId}/treatments?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatments = response.data

        console.log(treatments)

        return Promise.resolve(treatments)
    }

    const show = async(patientId, treatmentId) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.get(`/api/patients/${patientId}/treatments/${treatmentId}?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatment = response.data

        console.log(treatment)

        return Promise.resolve(treatment)
    }

    const update = async(patientId, treatmentId, payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.put(`/api/patients/${patientId}/treatments/${treatmentId}?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatment = response.data.data

        return Promise.resolve(treatment)
    }

    const finalize = async(patientId, treatmentId, payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.patch(`/api/patients/${patientId}/treatments/${treatmentId}?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatment = response.data.data

        return Promise.resolve(treatment)
    }

    const store = async(patientId, payload) => {
        console.log(payload)
        const lang = window.localStorage.getItem('lang');

        let response = await axios.post(`/api/patients/${patientId}/treatments?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatment = response.data.data
    
        return Promise.resolve(treatment)
    }

    const destroy = async(patientId, treatmentId) => {
   
        let response = await axios.delete(`/api/patients/${patientId}/treatments/${treatmentId}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let treatment = response.data.data
    
        return Promise.resolve(treatment)
    }
  
    return {
        index,
        show,
        update,
        store,
        destroy,
        finalize,
    }

}