<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 flex flex-col surface-2 rounded p-3">
            <h1 class="heading-3-medium text-1">{{ currentTitle }}</h1>
            <div class="h-1 hr-divider mb-4"></div>

            <div class="flex-1 flex flex-col surface-1 rounded">
                <div class="wrapper rounded flex-1 p-3 flex flex-col items-center justify-between">
                    <component :is="c" :test="currentTest"/>
                </div>
                <div class="mt-4 p-3">
                    <!--{{ isWizardDisabled ? 'DISABLED' : 'ENABLED' }} -->
                    <AppWizard :step="getStep()" @switched="handleStepSwitch" :disabled="isWizardDisabled" />
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>

import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import AppWizard from '@/views/components/common/AppWizard.vue'

//test step pages
import VisusFerne from '@/views/pages/tests/VisusFerneView.vue'
import Stereopsis from '@/views/pages/tests/StereopsisView.vue'
import Akkommodationsbreite from '@/views/pages/tests/AkkommodationsbreiteView.vue'
import Konvergenznahpunkt from '@/views/pages/tests/KonvergenznahpunktView.vue'
import FernophorieNahphorie from '@/views/pages/tests/FernophorieNahphorieView.vue'
import Monokulare from '@/views/pages/tests/MonokulareView.vue'



const store = useStore()
const router = useRouter()
const route = useRoute()

const lang = route?.query?.lang || 'de';

let c = ref(null)

let currentMethod = computed(() => {
    let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
    console.log(toRaw(methods))

    return methods[0]
})

const componentMap = {
    'VisusFerne': VisusFerne,
    'Stereopsis': Stereopsis,
    'Akkommodationsbreite': Akkommodationsbreite,
    'Konvergenznahpunkt': Konvergenznahpunkt,
    'FernophorieNahphorie': FernophorieNahphorie,
    'monokulare-akkommodations': Monokulare
}

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

const subview = route.params.subview

const test = route.params.test
let currentTest = ref(null)

let testStepStart = 3

let getStep = function() {
    return Number(test) + testStepStart
}

let tests = computed(() => store.getters[`tests/all`])
let isWizardDisabled = computed(() => store.getters[`app/isWizardDisabled`])

let currentTitle = computed(() => store.getters[`tests/currentTitle`])

let handleStepSwitch = async function({ routeName, step }) {
    let testNumber = Number(step) - testStepStart

    //await store.dispatch('questions/clearQuestions')

    router.push({
        name: routeName,
        params: {
            ...route.params,
            patientId,
            treatmentId,
            test: testNumber,
            subview: ''
        },
        query: {
          lang
        }
    })
}


onMounted(async () => {
    console.log(treatmentId)
    console.log('test order controller route params')
    console.log( route.params)


    await store.dispatch(`tests/all`, { patientId, treatmentId, lang })

    let t = toRaw(tests.value)

    currentTest.value = t[test - 1];

    let testComponentName = t[test - 1].test.label

    //alert(testComponentName)

    c.value = markRaw(componentMap[testComponentName])

    console.log(t[test - 1].test.label)
})

</script>
<style scoped>
    .test-order-panel {
        border: 1px solid var(--color-primary-400);
        background: var(--color-primary-50);
    }
    .test-item-title {
        border-bottom: 2px dotted var(--color-shade-0);
    }

    .item-outter-wrapper {
        background: var(--color-shade-1);

    }

    .moving-item {
        opacity: 0.8;
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-400);
    }

    .sortable-drag {
        opacity: 0;
    }

    .button-sm-outlined {
        font-family: 'Overpass', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        border-radius: 4px;
        line-height: 19px;
        border-radius: 8px;
        color: var(--color-primary-500);
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-500);
        transition: all .13s ease-in;
    }

</style>