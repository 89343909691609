export default {
    namespaced: true,

    state () {
        return {
            showMenu: true,
            isWizardDisabled: false,
        }
    },

    getters: {
        showMenu (state) {
            return state.showMenu 
        },

        isWizardDisabled (state) {
            return state.isWizardDisabled
        }
    },

    actions: {
        async showMenu ({commit}, show) {
            commit('SHOW_MENU', show)
        },
        async setWizardDisabled ({commit}, isDisabled) {
            commit('SET_WIZARD_DISABLED', isDisabled)
        }
    },

    mutations: {
        SHOW_MENU (state, show) {
            state.showMenu = show
        },
        SET_WIZARD_DISABLED (state, isDisabled) {
            state.isWizardDisabled = isDisabled
        }
    }
}