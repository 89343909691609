<template>
   <!--<pre> {{ currentTest.properties }} </pre>

   <pre>{{ localProperties }}</pre>
   <pre>{{ customInputRefs }}</pre> -->

    <div :class="{ 'test-panel-forbidden': isForbidden }" class="test-panel pt-2 text-center w-full xl:w-5/6">

        <div class="test-item rounded-lg text-left">
            <p class="subheading-bold text-1 py-2 text-left">{{ $t('visusFerneFastView.selectMeasurementMethod') }}</p>

            <template v-for="(method, index) in currentTest.test.methods" >
                <button @click="onMethodSelect(method)"
                    :class="{ 'active': method.id == currentTest.method_id }"
                    class="button-primary button-sm mt-3 mr-3 px-5 py-2 radius-sm">{{ method.name }}</button>
            </template>

        </div>

        <div class="test-item mt-3 rounded">
            <template v-for="(method, index) in currentTest.test.methods">

                <template v-if="currentTest.method_id == method.id">

                    <input v-if="method.custom == 1"
                        v-model="customMethodName"
                        @blur="handleCustomMethodUpdate(method)" @keyup.enter="handleCustomMethodUpdate(method)"
                        type="text"
                        class="input input-sm input-border w-60" :placeholder="$t('visusFerneFastView.enterTheMethod')">

                    <p class="subheading-bold text-1 py-2 text-left">{{ $t('visusFerneFastView.enterValuesForVisualAcuity') }}</p>

                    <div class="flex-col xl:flex xl:flex-row mr-3">

                        <!-- {{ localProperties }} -->
                        <div v-for="(property, index) in currentTest.properties" class="text-left mr-3">
                            <!--{{ localProperties[property.uuid] }}
                            {{ property.uuid }} -->
                            <div class="text-caption-upper text-left text-1 py-2 mt-2">{{ property.property.name }}</div>

                            <input :tabindex="index" v-if="method.custom == 1"
                                v-model="localProperties[property.uuid]"
                                @blur="handleCustomProperty(property.uuid, index)" @keyup.enter="handleCustomProperty(property.uuid, index)"
                                type="text"
                                class="input input-sm input-border w-60" placeholder="Bitte geben Sie die Werte ein"
                                ref="customInputRefs">

                            <select v-else v-model="localProperties[property.uuid]" @change="selectProperty(property.uuid, $event)" class="input-select w-60 mr-6 surface-1 shadow-md">
                                <option :value="null" :key="idx">{{ $t('visusFerneFastView.notSpecified') }}</option>
                                <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                            </select>

                            <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                        </div>

                    </div>
                </template>

            </template>

            <!--
                <button v-if="currentTest.saved" @click="resetSaved()" class="mt-4 label-underline-sm text-1">Standard zurücksetzen</button>
            <   button v-else="currentTest.saved" @click="resetTest()" class="mt-4 text-p-3-underline text-1">Zurück zu Methodenauswahl</button>
            -->
        </div>

    </div>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="methodConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">{{ $t('visusFerneFastView.saveAsDefault') }}</h1>
                <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                    {{ $t('visusFerneFastView.saveMethodText') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmMethod(true)" class="button-primary w-7/12 mx-auto">
                      {{ $t('visusFerneFastView.saveAsDefaultAndContinue') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmMethod(false)" class="button-primary-outlined  w-7/12 mx-auto">
                      {{ $t('visusFerneFastView.notSaveSelectionAndContinue') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''

    let testLabel = 'Visuswerte Ferne'

    let currentTest = computed(() => props.test)

    const customInputRefs = ref([])

    const methodConfirmationModal = ref()

    let selectedMethod = ref()

    let customMethodName = ref('')

    let localProperties = ref({})

    let strabismusQuestions = computed(() => store.getters['strabismusquestions/all'])

    let isForbidden = computed(() => {

        let forbidden = false

        if (strabismusQuestions.value.length) {
            forbidden = strabismusQuestions.value[0]['forbidden_answers'].indexOf(strabismusQuestions.value[0]['answer']) > -1
        }

        return forbidden
    })

    let selectType = async function(type) {

       let t = toRaw(currentTest.value)

       console.log(t)

       //alert(t.uuid)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               distance_type: type,
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'methods'
    //        }
    //    })
    }

    let onMethodSelect = function(method) {
        selectedMethod.value = method

        methodConfirmationModal.value.show()
    }

    let confirmMethod = function(remember) {

        if(!selectedMethod.value) return

        selectMethod(selectedMethod.value, remember)

        methodConfirmationModal.value.close()

        selectedMethod.value = null
    }

    let selectMethod = async function(method, remember = false) {

        let t = toRaw(currentTest.value)

        //alert(t.distance_type)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                distance_type: t.distance_type,
                remember
            }
        })

        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: 'properties'
        //     }
        // })
        reloadProperties()
    }

    let handleCustomMethodUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name: customMethodName.value,
                distance_type: t.distance_type,
            }
        })
    }

    let selectProperty = async function(propertyUuid, event) {

       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let handleCustomProperty = async function(propertyUuid, index) {

       let t = toRaw(currentTest.value)

       let value = localProperties.value[propertyUuid];

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

       //console.log(customInputRefs.value[index + 1])

       customInputRefs.value[index + 1] && customInputRefs.value[index + 1].focus()
    }

    let resetTest = function() {
        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: 'methods'
        //     }
        // })
    }

    let resetSaved = async function() {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: null,
                distance_type: t.distance_type,
                remember: false
            }
        })

        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: ''
        //     }
        // })
    }

    let reloadProperties = function () {
        currentTest.value.properties.forEach((p) => {
            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value === null ? p.default_value : p.value
            }
        })
    }

    onMounted(async () => {
        console.log(treatmentId)
        console.log('VISUS FERNE COMPONENT FAST')
        console.log(route.params)

        await store.dispatch(`tests/setCurrentTitle`, testLabel)

        reloadProperties()

        customMethodName.value = currentTest.value.method_name

        console.log(localProperties.value)

    })

</script>
<style scoped>

    .method-button.active {
        background: var(--color-primary-700);
    }

    .input-select {
        border: 1px solid var(--color-primary-500);
        padding: 8px 12px;
        border-radius: var(--radius-sm);
        min-width: 120px;
    }
</style>