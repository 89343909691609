<template>
    <!-- <pre>{{ tests[5] }} </pre> -->
    <div class="surface-4 p-3 w-full">
        <div class="flex-1 p-3 surface-2 rounded">
            <h1 class="heading-3-medium text-1">{{ $t('fastTestSquenceView.header') }}</h1>

            <div class="h-1 hr-divider mb-4"></div>

            <div class="surface-1 rounded pb-20">

                <AnamneseView compact="true"/>

                <div v-if="treatment" class="p-3" >

                    <h1 v-if="!compact" class="heading-4-medium text-8">{{ $t('fastTestSquenceView.header') }}</h1>
                    <div class="h-1 hr-divider mb-4"></div>


                    <div class="patient-info test-item item-body surface-brand-lighter rounded-lg">
                        <div class="table-wrapper p-2">
                            <table class="treatement-course-table table-auto overflow-x-scroll xl:overflow-x-auto w-full block ext-p-3-bold">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header2') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header3') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header4') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header5') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header6') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header7') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header8') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header9') }}</th>
                                        <th scope="col">{{ $t('fastTestSquenceView.table.header10') }}</th>
                                        <th class="font-bold" scope="col">{{ $t('fastTestSquenceView.table.header11') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">R</th>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.sphere_r">{{ treatment.sphere_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.sphere_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'sphere_r')" tabindex="1" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.cylinder_r">{{ treatment.cylinder_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.cylinder_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'cylinder_r')" tabindex="2" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.axis_r">{{ treatment.axis_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.axis_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'axis_r')" tabindex="3" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.add_r">{{ treatment.add_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.add_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'add_r')" tabindex="4"  type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_horizontal_r">{{ treatment.prism_horizontal_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_horizontal_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_r')" tabindex="5" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_horizontal_base_r">{{ treatment.prism_horizontal_base_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_horizontal_base_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_base_r')" tabindex="6" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_vertical_r">{{ treatment.prism_vertical_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_vertical_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_r')" tabindex="7" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_vertical_base_r">{{ treatment.prism_vertical_base_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_vertical_base_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_base_r')" tabindex="8" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.hsa_r">{{ treatment.hsa_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.hsa_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'hsa_r')" type="text" tabindex="9"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.pd_r">{{ treatment.pd_r }}</span>
                                            <template v-else><input v-model="forms.update_treatment.pd_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'pd_r')" tabindex="10" type="text"></template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">L</th>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.sphere_l">{{ treatment.sphere_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.sphere_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'sphere_l')" tabindex="11" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.cylinder_l">{{ treatment.cylinder_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.cylinder_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'cylinder_l')" tabindex="12" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.axis_l">{{ treatment.axis_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.axis_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'axis_l')" tabindex="13" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.add_l">{{ treatment.add_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.add_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'add_l')" tabindex="14" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_horizontal_l">{{ treatment.prism_horizontal_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_horizontal_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_l')" tabindex="15" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_horizontal_base_l">{{ treatment.prism_horizontal_base_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_horizontal_base_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_base_l')" tabindex="16" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_vertical_l">{{ treatment.prism_vertical_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_vertical_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_l')" tabindex="17" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.prism_vertical_base_l">{{ treatment.prism_vertical_base_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.prism_vertical_base_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_base_l')" tabindex="18" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.hsa_l">{{ treatment.hsa_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.hsa_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'hsa_l')" tabindex="19" type="text"></template>
                                        </td>
                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                            <span v-if="!treatementEditMode.pd_l">{{ treatment.pd_l }}</span>
                                            <template v-else><input v-model="forms.update_treatment.pd_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'pd_l')" tabindex="20" type="text"></template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

                <StrabismusView compact="true"/>

                <template v-if="renderTests" v-for="(test, index) in tests" :key="index">
                    <div class="p-3">

                        <h1 v-if="!compact" class="heading-4-medium text-8">{{ test.test.name }} {{ test.test.is_mandatory ? '*' : '' }}</h1>
                        <div class="h-1 hr-divider mb-3"></div>

                        <component :is="componentMap[test.test.label]" :test="test" />
                    </div>
                </template>
                <!-- {{ !testsValid  ? 'DISABLED' : ' ENABLED'}} -->

                <!-- <pre>isContinueButtonDisabled {{ isContinueButtonDisabled ? 'DISABLED' : 'ENABLED' }} </pre><br> -->
                <!-- <pre>answersValid {{ answersValid ? 'TRUE': 'FALSE' }}</pre><br> -->
                <!-- <pre>testsValid {{ testsValid ? 'TRUE': 'FALSE' }}</pre><br> -->
                <!-- <pre>isForbidden {{ isForbidden ? 'TRUE': 'FALSE' }}</pre>  -->


                <button @click="saveAndContinue()" :disabled="isContinueButtonDisabled" class="button-primary w-11/12 xl:w-5/12 mx-auto block xl:inline-block xl:ml-3 mt-5 px-5 py-2 radius-sm uppercase">Weiter</button>

            </div>

        </div>

    </div>

</template>
<script setup>
    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, watch, nextTick } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AnamneseView from '@/views/pages/AnamneseView.vue'
    import StrabismusView from '@/views/pages/StrabismusView.vue'

    //test step components
    import VisusFerneFast from '@/views/pages/tests/VisusFerneFastView.vue'
    import StereopsisFast from '@/views/pages/tests/StereopsisFastView.vue'
    import AkkommodationsbreiteFast from '@/views/pages/tests/AkkommodationsbreiteFastView.vue'
    import KonvergenznahpunktFast from '@/views/pages/tests/KonvergenznahpunktFastView.vue'
    import FernophorieNahphorieFast from '@/views/pages/tests/FernophorieNahphorieFastView.vue'
    import MonokulareFast from '@/views/pages/tests/MonokulareFastView.vue'


    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const componentMap = {
        'VisusFerne': VisusFerneFast,
        'Stereopsis': StereopsisFast,
        'Akkommodationsbreite': AkkommodationsbreiteFast,
        'Konvergenznahpunkt': KonvergenznahpunktFast,
        'FernophorieNahphorie': FernophorieNahphorieFast,
        'monokulare-akkommodations': MonokulareFast
    }

    const patientId = route.params.patientId
    const treatmentId = route.params.treatmentId

    let treatment = computed(() => store.getters['treatments/treatment'].data)
    let tests = computed(() => store.getters[`tests/all`])

    let renderTests = ref(false)

    let isContinueButtonDisabled = computed(() => {
        return !answersValid.value || !testsValid.value || isForbidden.value
    })

    let strabismusQuestions = computed(() => store.getters['strabismusquestions/all'])


    let questions = computed(() => store.getters['anamnesequestions/all'])

    let answersValid = ref(false)
    let testsValid = ref(false)
    let isForbidden = computed(() => strabismusQuestions.value[0] && strabismusQuestions.value[0]['forbidden_answers'].indexOf(strabismusQuestions.value[0]['answer']) > -1)

    let validateTests = function () {
        //alert('validateTests')

        let testsArray = toRaw(tests.value)

        console.log(testsArray)

        let invalid = false

        for (const test of testsArray) {

            if (!test.test.is_mandatory) continue

            console.log('VALIDATE ' + test.test.name)
            //alert(test.test.name)

            invalid = test.method_id == 0

            if (test.test.label == 'FernophorieNahphorie') { // see
                //alert ( 'Fernphorie und Nahphorie check')
                if (test.fixation_far == null || test.fixation_near == null) {
                    invalid = true
                } else { // check properties

                    for (const p of test.properties) {


                        if (p.value === null && test.fixation_far == 'exo' && p.distance_type == 'far' && p.property.name == 'Exophorie') {

                            testsValid.value = false
                            //alert(test.test.name + ' property is invalid')

                            return

                        } else if (p.value === null && test.fixation_far == 'eso' && p.distance_type == 'far' && p.property.name == 'Esophorie') {

                            testsValid.value = false
                            //alert(test.test.name + ' property is invalid')

                            return
                        }


                        if (p.value === null && test.fixation_near == 'exo' && p.distance_type == 'near' && p.property.name == 'Exophorie') {

                            testsValid.value = false
                            //alert(test.test.name + ' property is invalid')

                            return

                        } else if (p.value === null && test.fixation_near == 'eso' && p.distance_type == 'near' && p.property.name == 'Esophorie') {

                            testsValid.value = false
                            //alert(test.test.name + ' property is invalid')

                            return
                        }


                    }

                }

            } else {

                if (test.test.label == 'monokulare-akkommodations') {
                    let Z_VALUE_TRESHOLD = -1

                    if (test.method_option === null) {
                        testsValid.value = false

                        return

                    } else if (test.method_option == 1) { // Akk.-Breite > 5.5 dpt

                        let monopInvalid = false

                        for (const p of test.properties) {

                            if (p.value === null || monopInvalid) {
                                monopInvalid = true
                            }

                            console.log( p.property.name + ' p.delayed: ' + p.delayed  + ', z_value: ' + p.z_value)
                            if (p.delayed == 'none' && p.z_value < Z_VALUE_TRESHOLD) {
                                monopInvalid = true
                            }

                        }

                        if (monopInvalid) {
                            testsValid.value = false
                            //alert(test.test.name + ' property is invalid')

                            return
                        }

                    } else { // Akk.-Breite <= 5.5 dpt (test.method_option == 2)
                        continue
                    }
                }

                for (const p of test.properties) {
                    let pInvalid = p.value === null

                    if (pInvalid) {
                        testsValid.value = false
                        //alert(test.test.name + ' property is invalid')

                        return
                    }
                }
            }

            if (invalid) {
                testsValid.value = false

                return
            }
        }

        testsValid.value = true
    }

    watch(() => toRaw(questions), (i, questions) => {

        let questionsArray = toRaw(questions.value)

        let invalid = false

        let firstQuestion = questionsArray[0]

        let firstQuestionAnswered = false

        if (firstQuestion && firstQuestion.possible_answers && firstQuestion.possible_answers.length &&
            firstQuestion.answer && firstQuestion.possible_answers.indexOf(firstQuestion.answer) > -1) {

            firstQuestionAnswered = true
        }


        for (const question of questionsArray) {

            invalid = /*(question.answer == 0 || question.answer == null) ||*/
                    (question.answer && question.possible_answers.indexOf(question.answer) !== 0 && question.possible_additional_answers.length && question.additional_answer == 0) ||
                    (question.question_index == 1 && (question.answer == 'Nein' || question.answer == 'No' || question.answer === 'Non') && question.additional_answer == 0)

            if (invalid) {
                //alert('invalid')
                answersValid.value = false

                return
            }
        }

        answersValid.value = true

    }, { deep: true })


    watch(() => toRaw(tests), (i, tests) => {
        // alert('watch tests ')

        console.log('watch tests')

        validateTests()

    }, { deep: true })


    const forms = reactive({
        create_treatment: {
            sphere_l: '',
            sphere_r: '',
            cylinder_l: '',
            cylinder_r: '',
            axis_l: '',
            axis_r: '',
            add_l: '',
            add_r: '',
            prism_horizontal_l: '',
            prism_horizontal_r: '',
            prism_horizontal_base_l: '',
            prism_horizontal_base_r: '',
            prism_vertical_l: '',
            prism_vertical_r: '',
            hsa_l: '',
            hsa_r: '',
            prism_vertical_base_l: '',
            prism_vertical_base_r: '',
            pd_l: '',
            pd_r: '',
        },
        update_treatment: {
            uuid: '',
            sphere_l: '',
            sphere_r: '',
            cylinder_l: '',
            cylinder_r: '',
            axis_l: '',
            axis_r: '',
            add_l: '',
            add_r: '',
            prism_horizontal_l: '',
            prism_horizontal_r: '',
            prism_horizontal_base_l: '',
            prism_horizontal_base_r: '',
            prism_vertical_l: '',
            prism_vertical_r: '',
            hsa_l: '',
            hsa_r: '',
            prism_vertical_base_l: '',
            prism_vertical_base_r: '',
            pd_l: '',
            pd_r: '',
        }
    })

    let errors = reactive({
        update_treatment: {
            uuid: [],
            sphere_l: [],
            sphere_r: [],
            cylinder_l: [],
            cylinder_r: [],
            axis_l: [],
            axis_r: [],
            add_l: [],
            add_r: [],
            prism_horizontal_l: [],
            prism_horizontal_r: [],
            prism_horizontal_base_l: [],
            prism_horizontal_base_r: [],
            prism_vertical_l: [],
            prism_vertical_r: [],
            hsa_l: [],
            hsa_r: [],
            prism_vertical_base_l: [],
            prism_vertical_base_r: [],
            pd_l: [],
            pd_r: [],
        }
    })

    let treatementEditMode = reactive({
            sphere_l: false,
            sphere_r: false,
            cylinder_l: false,
            cylinder_r: false,
            axis_l: false,
            axis_r: false,
            add_l: false,
            add_r: false,
            prism_horizontal_l: false,
            prism_horizontal_r: false,
            prism_horizontal_base_l: false,
            prism_horizontal_base_r: false,
            prism_vertical_l: false,
            prism_vertical_r: false,
            hsa_l: false,
            hsa_r: false,
            prism_vertical_base_l: false,
            prism_vertical_base_r: false,
            pd_l: false,
            pd_r: false,
    })


    let handleUpdateTreatment = async function () {
        console.log('handleUpdateTreatment')

        let payload = toRaw(forms.update_treatment)

        console.log(payload)

        let treatment = await store.dispatch('treatments/updateTreatment', {
            patientId: patientId,
            data: payload
        }).catch((err) => {
            console.log('error')
            Object.assign(errors.update_treatment, err.errors)
        })

        console.log("treatment updated")
        console.log(treatment)

        if(treatment) {
            reset('update_treatment', 'uuid')
            reset('update_treatment', 'sphere_l')
            reset('update_treatment', 'sphere_r')
            reset('update_treatment', 'cylinder_l')
            reset('update_treatment', 'cylinder_r')
            reset('update_treatment', 'axis_l')
            reset('update_treatment', 'axis_r')
            reset('update_treatment', 'add_l')
            reset('update_treatment', 'add_r')
            reset('update_treatment', 'prism_horizontal_l')
            reset('update_treatment', 'prism_horizontal_r')
            reset('update_treatment', 'prism_horizontal_base_l')
            reset('update_treatment', 'prism_horizontal_base_r')
            reset('update_treatment', 'prism_vertical_l')
            reset('update_treatment', 'prism_vertical_r')
            reset('update_treatment', 'hsa_l')
            reset('update_treatment', 'hsa_r')
            reset('update_treatment', 'prism_vertical_base_l')
            reset('update_treatment', 'prism_vertical_base_r')
            reset('update_treatment', 'pd_l')
            reset('update_treatment', 'pd_r')

            forms.update_treatment.uuid = ''
            forms.update_treatment.sphere_l = ''
            forms.update_treatment.sphere_r = ''
            forms.update_treatment.cylinder_l = ''
            forms.update_treatment.cylinder_r = ''
            forms.update_treatment.axis_l = ''
            forms.update_treatment.axis_r = ''
            forms.update_treatment.add_l = ''
            forms.update_treatment.add_r = ''
            forms.update_treatment.prism_horizontal_l = ''
            forms.update_treatment.prism_horizontal_r = ''
            forms.update_treatment.prism_horizontal_base_l = ''
            forms.update_treatment.prism_horizontal_base_r = ''
            forms.update_treatment.prism_vertical_l = ''
            forms.update_treatment.prism_vertical_r = ''
            forms.update_treatment.hsa_l = ''
            forms.update_treatment.hsa_r = ''
            forms.update_treatment.prism_vertical_base_l = ''
            forms.update_treatment.prism_vertical_base_r = ''
            forms.update_treatment.pd_l = ''
            forms.update_treatment.pd_r = ''

            resetTreatmentEdit()
        }
    }


    let setTreatmentAllEditMode = function (value) {
        treatementEditMode.sphere_l = value
        treatementEditMode.sphere_r = value
        treatementEditMode.cylinder_l = value
        treatementEditMode.cylinder_r = value
        treatementEditMode.axis_l = value
        treatementEditMode.axis_r = value
        treatementEditMode.add_l = value
        treatementEditMode.add_r = value
        treatementEditMode.prism_horizontal_l = value
        treatementEditMode.prism_horizontal_r = value
        treatementEditMode.prism_horizontal_base_l = value
        treatementEditMode.prism_horizontal_base_r = value
        treatementEditMode.prism_vertical_l = value
        treatementEditMode.prism_vertical_r = value
        treatementEditMode.hsa_l = value
        treatementEditMode.hsa_r = value
        treatementEditMode.prism_vertical_base_l = value
        treatementEditMode.prism_vertical_base_r = value
        treatementEditMode.pd_l = value
        treatementEditMode.pd_r = value
    }

    let populateTreatmentForm = function(treatment) {
        forms.update_treatment.uuid = treatment.uuid
        forms.update_treatment.sphere_l = treatment.sphere_l
        forms.update_treatment.sphere_r = treatment.sphere_r
        forms.update_treatment.cylinder_l = treatment.cylinder_l
        forms.update_treatment.cylinder_r = treatment.cylinder_r
        forms.update_treatment.axis_l = treatment.axis_l
        forms.update_treatment.axis_r = treatment.axis_r
        forms.update_treatment.add_l = treatment.add_l
        forms.update_treatment.add_r = treatment.add_r
        forms.update_treatment.prism_horizontal_l = treatment.prism_horizontal_l
        forms.update_treatment.prism_horizontal_r = treatment.prism_horizontal_r
        forms.update_treatment.prism_horizontal_base_l = treatment.prism_horizontal_base_l
        forms.update_treatment.prism_horizontal_base_r = treatment.prism_horizontal_base_r
        forms.update_treatment.prism_vertical_l = treatment.prism_vertical_l
        forms.update_treatment.prism_vertical_r = treatment.prism_vertical_r
        forms.update_treatment.hsa_l = treatment.hsa_l
        forms.update_treatment.hsa_r = treatment.hsa_r
        forms.update_treatment.prism_vertical_base_l = treatment.prism_vertical_base_l
        forms.update_treatment.prism_vertical_base_r = treatment.prism_vertical_base_r
        forms.update_treatment.pd_l = treatment.pd_l
        forms.update_treatment.pd_r = treatment.pd_r

    }

    const reset = (form, field) => {
        console.log(field)
        errors[form][field] = [];
    }

    const lang = route?.query?.lang || 'de';

    let goToTestEvaluationPage = function () {
        router.push({
            name: 'test-evaluation',
            params: {
                ...route.params,
                patientId,
                treatmentId,
            },
          query: {
            lang
          }
        })
    }

    let saveAndContinue = function() {
        goToTestEvaluationPage()
    }

    watch(forms.update_treatment, async (oldValue, newValue) => {

        let intregex = /[^\-?0-9]/g
        let decregex = /[^\-?\d+(\.\d{1,2})$]/g

        forms.update_treatment.sphere_l = newValue.sphere_l ? newValue.sphere_l.replace(decregex, '') : newValue.sphere_l

        forms.update_treatment.sphere_r = newValue.sphere_r ? newValue.sphere_r.replace(decregex, '') : newValue.sphere_r

        forms.update_treatment.cylinder_l = newValue.cylinder_l ? newValue.cylinder_l.replace(decregex, '') : newValue.cylinder_l

        forms.update_treatment.cylinder_r = newValue.cylinder_r ? newValue.cylinder_r.replace(decregex, '') : newValue.cylinder_r

        forms.update_treatment.axis_l = newValue.axis_l ? newValue.axis_l.replace(intregex, '') : newValue.axis_l

        forms.update_treatment.axis_r = newValue.axis_r ? newValue.axis_r.replace(intregex, '') : newValue.axis_r

        forms.update_treatment.add_l = newValue.add_l ? newValue.add_l.replace(decregex, '') : newValue.add_l

        forms.update_treatment.add_r = newValue.add_r ? newValue.add_r.replace(decregex, '') : newValue.add_r

        forms.update_treatment.prism_horizontal_l = newValue.prism_horizontal_l ? newValue.prism_horizontal_l.replace(decregex, '') : newValue.prism_horizontal_l

        forms.update_treatment.prism_horizontal_r = newValue.prism_horizontal_r ? newValue.prism_horizontal_r.replace(decregex, '') : newValue.prism_horizontal_r

        forms.update_treatment.prism_horizontal_base_l = newValue.prism_horizontal_base_l ? newValue.prism_horizontal_base_l.replace(intregex, '') : newValue.prism_horizontal_base_l

        forms.update_treatment.prism_horizontal_base_r = newValue.prism_horizontal_base_r ? newValue.prism_horizontal_base_r.replace(intregex, '') : newValue.prism_horizontal_base_r

        forms.update_treatment.prism_vertical_l = newValue.prism_vertical_l ? newValue.prism_vertical_l.replace(decregex, '') : newValue.prism_vertical_l

        forms.update_treatment.prism_vertical_r = newValue.prism_vertical_r ? newValue.prism_vertical_r.replace(decregex, '') : newValue.prism_vertical_r

        forms.update_treatment.hsa_l = newValue.hsa_l ? newValue.hsa_l.replace(decregex, '') : newValue.hsa_l

        forms.update_treatment.hsa_r = newValue.hsa_r ? newValue.hsa_r.replace(decregex, '') : newValue.hsa_r

        forms.update_treatment.prism_vertical_base_l = newValue.prism_vertical_base_l ? newValue.prism_vertical_base_l.replace(intregex, '') : newValue.prism_vertical_base_l

        forms.update_treatment.prism_vertical_base_r = newValue.prism_vertical_base_r ? newValue.prism_vertical_base_r.replace(intregex, '') : newValue.prism_vertical_base_r

        forms.update_treatment.pd_l = newValue.pd_l ? newValue.pd_l.replace(decregex, '') : newValue.pd_l

        forms.update_treatment.pd_r = newValue.pd_r ? newValue.pd_r.replace(decregex, '') : newValue.pd_r

    })

    watch(forms.create_treatment, async (oldValue, newValue) => {

        let intregex = /[^\-?0-9]/g
        let decregex = /[^\-?\d+(\.\d{1,2})$]/g

        forms.create_treatment.sphere_l = newValue.sphere_l.replace(decregex, '')

        forms.create_treatment.sphere_r = newValue.sphere_r.replace(decregex, '')

        forms.create_treatment.cylinder_l = newValue.cylinder_l.replace(decregex, '')

        forms.create_treatment.cylinder_r = newValue.cylinder_r.replace(decregex, '')

        forms.create_treatment.axis_l = newValue.axis_l.replace(intregex, '')

        forms.create_treatment.axis_r = newValue.axis_r.replace(intregex, '')

        forms.create_treatment.add_l = newValue.add_l.replace(decregex, '')

        forms.create_treatment.add_r = newValue.add_r.replace(decregex, '')

        forms.create_treatment.prism_horizontal_l = newValue.prism_horizontal_l.replace(decregex, '')

        forms.create_treatment.prism_horizontal_r = newValue.prism_horizontal_r.replace(decregex, '')

        forms.create_treatment.prism_horizontal_base_l = newValue.prism_horizontal_base_l.replace(intregex, '')

        forms.create_treatment.prism_horizontal_base_r = newValue.prism_horizontal_base_r.replace(intregex, '')

        forms.create_treatment.prism_vertical_l = newValue.prism_vertical_l.replace(decregex, '')

        forms.create_treatment.prism_vertical_r = newValue.prism_vertical_r.replace(decregex, '')

        forms.create_treatment.hsa_l = newValue.hsa_l.replace(decregex, '')

        forms.create_treatment.hsa_r = newValue.hsa_r.replace(decregex, '')

        forms.create_treatment.prism_vertical_base_l = newValue.prism_vertical_base_l.replace(intregex, '')

        forms.create_treatment.prism_vertical_base_r = newValue.prism_vertical_base_r.replace(intregex, '')

        forms.create_treatment.pd_l = newValue.pd_l.replace(decregex, '')

        forms.create_treatment.pd_r = newValue.pd_r.replace(decregex, '')
    })

    const group = 'strabismus'

    onMounted(async () => {
        console.log(treatmentId)
        console.log('fast test')
        console.log(tests.value)

        await store.dispatch('strabismusquestions/all', { patientId, treatmentId, group, lang })

        await store.dispatch(`tests/all`, { patientId, treatmentId, lang })

        await store.dispatch('treatments/treatment', { patientId, treatmentId, lang })

        populateTreatmentForm(treatment.value)

        renderTests.value = true

        validateTests()
    })


</script>