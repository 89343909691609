<template>
    <!-- {{ opened }}
    {{ typeof(opened) }}
    {{ hovered }} -->
        <div :class="{ 
            'tooltip-hover': triggerType == 'hover',
            'top': position == 'top',
            'right': position == 'right',
            'bottom': position == 'bottom',
            'left': position == 'left',
        }"
        @mouseover.native="handleTooltipHover(true)" 
        @mouseleave.native="handleTooltipHover(false)"
        @click="handleTooltipClick()"
        class="tooltip">
            <slot name="trigger">tooltip trigger<!-- fallback trigger --></slot>
            <span v-if="opened" class="tooltiptext"><slot name="content">tooltip content<!-- fallback content --></slot></span>
        </div>
</template>

<script setup>

import { computed, onMounted, ref, reactive } from 'vue'

const props = defineProps({
    triggerType:  {
        validator(value) {
            return ['click', 'hover'].includes(value)
        },
        required: false,
        default: 'hover'
    },
    opened: {
        type: Boolean,
        default: false,
        required: false
    },
    position: {
        validator(value) {
            return ['top', 'right', 'bottom', 'left'].includes(value)
        },
        required: true,
        default: 'bottom'
    }
})

const isMobile= () =>( window.innerWidth <= 768 )

let triggerType = reactive(props.triggerType)
let position = reactive(props.position)
let opened = ref(props.opened)

let hovered = ref(false)

let handleTooltipHover = function(hover) {
    console.log('handleTooltipHover')
    if(triggerType != 'hover') return

    if(isMobile()) return

    opened.value = hover
    hovered.value = hover
}

let handleTooltipClick = function() {
    console.log('handleTooltipClick')
    //if(triggerType != 'hover') return

    opened.value = !opened.value
}


onMounted(() => {
    console.log({triggerType})
    console.log({position})
    console.log(opened.value)
})
</script>
<style scoped>
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
    color: var(--color-shade-1);
    background: var(--color-shade-0);
    box-shadow: 0 10px 15px -3px rgb(0 0 0/0.1),0 4px 6px -4px rgb(0 0 0/0.1);
    text-align: center;
    padding: 10px;
    width: 280px;
    border-radius: var(--radius-lg);
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    transition: all 0.3 ease-in !important;
}

/* Show the tooltip text when you mouse over the tooltip container 
.tooltip .tooltip-hover:hover .tooltiptext {
    visibility: visible;
}
*/
.tooltip.top .tooltiptext {
  width: 120px;
  bottom: 120%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip.right .tooltiptext {
  left: 135%;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.bottom .tooltiptext {
  width: 120px;
  top: 120%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip.left .tooltiptext {
  right: 135%;
  top: 50%;
  transform: translateY(-50%);
}

/* tooltip arrows */
.tooltip.top .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip.right .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}
.tooltip.bottom .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.tooltip.left .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}


</style>