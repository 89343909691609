import { toRaw } from 'vue'
import store from '@/store'

const before = (to, from, next) => {
    //console.log({to})
    
    let meta = to.meta
    //let user = toRaw(store.getters['auth/user'].data)
    let authenticated = toRaw(store.getters['auth/authenticated'])

    //console.log(authenticated)

    if(meta.auth != undefined ) {


        if(meta.auth == true && !authenticated) {
            sessionStorage && sessionStorage.setItem('intended', JSON.stringify({
                name: to.name,
                params: to.params,
                query: to.query
            }))
            return next({ name: 'login' })
        } else {
            sessionStorage && sessionStorage.setItem('intended', '')
        }
        
        store.dispatch('app/showMenu', true)


        return next()

    } else if(meta.guest != undefined) {
        if(meta.guest == true && authenticated) {
            return next({ name: 'home' })
        }
    }

    return next()
}

export default before