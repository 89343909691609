<template>
  <form
    enctype="multipart/form-data"
    novalidate
    class="h-32 z-10 surface-1 text-lg text-8 bg-transparent border-2 border-gray-300 border-dashed rounded-lg p-10 flex items-center justify-center mb-6 hover:border-gray-400 relative"
    :class="{
      'border-gray-600': dragging
    }"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    ref="form"
  >
    <input
      id="fileInput"
      type="file"
      multiple
      class="absolute opacity-0 w-full h-full top-0 left-0 z-10"
      @change="handleFilesChosen"
    >
    <template v-if="dragging">
      <div class="text-gray-700 select-none -z-10">
        Nearly there. Let go to upload {{ draggingCount > 1 ? 'items': 'item' }}!
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col z-10">
        <label for="fileInput" class="flex items-center flex-1">
          <IconCloudUpload class="w-10 h-10 text-4 mr-3"/>
          <span class="font-medium text-center">
              Drag & drop files here or
              <span class="brand underline cursor-pointer">browse</span> for upload<br>
          </span>
        </label>
      </div>
      <div v-if="allowedTypes.length" class="absolute bottom-3 text-sm text-5 text-center z-0">Allowed: {{ allowedTypes.join(', ') }}</div>
    </template>
  </form>
</template>

<script>
  import IconCloudUpload from '@/views/components/icons/IconCloudUpload.vue'

  export default {
    components: {
      IconCloudUpload
    },

    data () {
      return {
        dragging: false,
        draggingCount: 0,
      }
    },


    props: {
      allowedTypes: {
        required: false,
        type: Array,
        default: []
      },
    },

    methods: {
      reset () {
        this.$refs['form'].reset()
      },

      handleFilesChosen (e) {
        this.dragging = false
        this.$emit('chosen', e.target.files)
      },

      handleDragOver (e) {
        this.dragging = true
        this.draggingCount = e.dataTransfer.items.length
      },

      handleDragLeave () {
        this.dragging = false
        this.draggingCount = 0
      }
    }
  }
</script>
