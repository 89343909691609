import axios from 'axios'

export default function use() {

    const index = async(params) => {
        const lang = window.localStorage.getItem('lang');
        const newParams = {...params, lang}
        const queryString = '?' + new URLSearchParams(newParams).toString()

        console.log(queryString)

        let response = await axios.get('/api/patients' + queryString ).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let patients = response.data

        console.log(patients)

        return Promise.resolve(patients)
    }

    const show = async(id) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.get(`/api/patients/${id}?lang=${lang}` ).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let patient = response.data

        console.log(patient)

        return Promise.resolve(patient)
    }

    const update = async(id, payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.patch(`/api/patients/${id}?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let patient = response.data.data

        return Promise.resolve(patient)
    }

    const store = async(payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.post(`/api/patients?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let patient = response.data.data
    
        return Promise.resolve(patient)
    }

    const destroy = async(id) => {

        let response = await axios.delete(`/api/patients/${id}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let patient = response.data.data
    
        return Promise.resolve(patient)
    }

    const startDataConsent = async(id) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.post(`/api/patients/${id}/start-data-consent?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data.data

        return Promise.resolve(data)
    }

    const commitDataConsent = async(id) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.post(`/api/patients/${id}/commit-data-consent?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data.data

        return Promise.resolve(data)
    }

    const deleteDataConsent = async(id) => {

        let response = await axios.delete(`/api/patients/${id}/delete-data-consent`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data.data

        return Promise.resolve(data)
    }

    return {
        index,
        show,
        update,
        store,
        destroy,
        startDataConsent,
        commitDataConsent,
        deleteDataConsent
    }

}