import axios from 'axios'

export default function use() {

    const index = async(patientId, treatmentId) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.get(`/api/patients/${patientId}/treatments/${treatmentId}/tests?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let tests = response.data

        console.log(tests)

        return Promise.resolve(tests)
    }

    const update = async(patientId, treatmentId, testId, payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.put(`/api/patients/${patientId}/treatments/${treatmentId}/tests/${testId}?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let tests = response.data

        return Promise.resolve(tests)
    }

    const updateProperty = async(patientId, treatmentId, testId, propertyId, payload) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.put(`/api/patients/${patientId}/treatments/${treatmentId}/tests/${testId}/properties/${propertyId}?lang=${lang}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let property = response.data

        return Promise.resolve(property)
    }
    
  
    const updateOrder = async(patientId, treatmentId, uuids, remember = false) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.post(`/api/patients/${patientId}/treatments/${treatmentId}/update-test-sort-order?lang=${lang}`, {
            uuids,
            remember
        }).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let tests = response.data

        return Promise.resolve(tests)
    }

    const evaluationData = async(patientId, treatmentId) => {
        const lang = window.localStorage.getItem('lang');
        let response = await axios.get(`/api/patients/${patientId}/treatments/${treatmentId}/evaluation-data?lang=${lang}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data

        return Promise.resolve(data)
    }

   
    return {
        index,
        update,
        updateProperty,
        updateOrder,
        evaluationData
    }

}