<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 surface-2 rounded-sm">

            <div class="panel panel-default">
                <template v-if="!fullscreen">
                    <div class="panel-heading">
                        <div class="heading-5-medium px-6 pt-6 pb-3 text-1">{{ title }}</div>
                    </div>

                    <div class="panel panel-default flex flex-wrap item-center px-6 pb-3">

                        <form action="#" @submit.prevent="handleSearch()">
                            <div class="flex items-center">
                                <div class="relative">
                                    <input type="text" id="search-number" @keyup="debouncedHandleSearch()" v-model="forms.search.search_patient_id" class="input input-sm input-border input-search pr-8" :placeholder="$t('patientsDataTable.patientNumber')">
                                    <IconSearch class="absolute right-3 top-1/2 -translate-y-1/2 text-3" />
                                </div>
                                <div class="relative">
                                    <input type="text" id="search-name" @keyup="debouncedHandleSearch()" v-model="forms.search.search_name" class="input input-sm input-border input-search ml-2" :placeholder="$t('patientsDataTable.name')">
                                    <IconSearch class="absolute right-3 top-1/2 -translate-y-1/2 text-3" />
                                </div>

                                <div class="filter-date-picker">

                                    <datepicker
                                        locale="de" :cancelText="$t('patientsDataTable.cancel')" :selectText="$t('patientsDataTable.choose')"
                                        autoApply
                                        :maxDate="new Date()"
                                        :minDate="new Date(1,1,1900)"
                                        v-model="forms.search.search_birthday"
                                        format="dd/MM/yyyy"
                                        class="search-birthday ml-2"
                                        :placeholder="$t('patientsDataTable.birthDate')"
                                    />


                                </div>

                                <div class="input-group-btn ml-2">
                                    <button class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4" type="submit">
                                        <IconSearch class="mr-2" />
                                      {{ $t('patientsDataTable.seek') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="input-group inline-flex xl:ml-3 mt-3 xl:mt-0">
                            <button @click="showPatientCreate()" class="button-primary button-sm flex items-center px-4 py-2 radius-sm overflow-ellipsis w-full">
                                <IconPlus class="mr-2" />
                              {{ $t('patientsDataTable.recordPatient') }}
                            </button>
                            <button @click="todo()" class="button-primary button-sm flex items-center ml-3 px-4 py-2 radius-sm">
                              {{ $t('patientsDataTable.import') }}
                            </button>
                        </div>

                    </div>

                    <AppDataTable v-if="patients.data.length"
                        resource="patients"
                        :autoloadable="false"
                        :selectable="selectable"
                        :clickable="true"
                        :padrows="false"
                        @record-click="onRecordClick"
                        @changePerPage="onChangePerPage"
                        class="table-condensed">
                        <template v-slot:th>
                            <th></th>
                        </template>

                        <template v-slot:td="{ record }">
                            <td>
                                <div class="btn-group">
                                    <button @click="showDeletePatientModal(record)" class="px-5 py-2" type="submit">
                                        <IconBasket />
                                    </button>
                                </div>
                            </td>
                        </template>

                        <template v-slot:bottom-start>
                        </template>
                    </AppDataTable>

                    <template v-if="!patients.data.length">
                        <table class="table w-full">
                          <thead>
                            <tr class="text-4">
                              <th class="px-6 pt-2">
                                <a href="#" class="flex items-center heading-6-bold text-4 sortable">
                                  {{ $t('patientsDataTable.patientId') }} <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="sort-icon w-4 ml-2"><path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></a></th>
                              <th class="px-6 pt-2">
                                <a href="#" class="flex items-center heading-6-bold text-4 sortable">
                                  {{ $t('patientsDataTable.firstName') }} <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="sort-icon w-4 ml-2"><path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></a></th>
                              <th class="px-6 pt-2"><a href="#" class="flex items-center heading-6-bold text-4 sortable">
                                {{ $t('patientsDataTable.lastName') }} <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="sort-icon w-4 ml-2"><path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></a></th>
                              <th class="px-6 pt-2"><a href="#" class="flex items-center heading-6-bold text-4 sortable">
                                {{ $t('patientsDataTable.birthday') }} <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="sort-icon w-4 ml-2"><path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></a></th>
                              <th class="px-6 pt-2"><a href="#" class="flex items-center heading-6-bold text-4 sortable">
                                {{ $t('patientsDataTable.status') }} <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="sort-icon w-4 ml-2"><path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></a></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                            <tr><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td><td class="px-6 py-6"></td></tr>
                        </tbody>
                        </table>
                    </template>
                </template>

                <template v-if="currentPatient">
                    <div class="panel patient-info px-12 py-3">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <div>
                                    <div v-show="!editPatientStatusMode"
                                        @click="enableEditInput('editPatientStatusMode')"
                                        :class="{ 'tag-success' : currentPatient.status >= 1, 'tag-error': currentPatient.status < 0  }"
                                        class="tag  heading-6-medium-s mr-2 cursor-pointer">{{ currentPatient.status >= 1 ? 'Aktiv' : 'Verstorben' }}</div>
                                    <select v-show="editPatientStatusMode"
                                        v-model="forms.update_patient.status" @blur="handleUpdatePatient()" @change="handleUpdatePatient()" @focus="reset('update_patient', 'status')" ref="patientStatusInput"
                                        class="h-7 rounded mr-2 surface-4">
                                        <option value="1">{{ $t('patientsDataTable.active') }}</option>
                                        <option value="-1">{{ $t('patientsDataTable.deceased') }}</option>
                                    </select>
                                </div>

                                <div v-show="!editNameMode" @click="enableEditInput('editNameMode')" class="heading-5-medium-s mr-2">{{ currentPatient.last_name + ' ' + currentPatient.first_name }}</div>
                                <div v-show="editNameMode" :class="{ 'has-error': errors.update_patient.name[0] }" class="-heading-5-medium-s">
                                    <input v-model="forms.update_patient.name" @blur="handleUpdatePatient()" @keyup="handlePatientEditMode($event)" @focus="reset('update_patient', 'name')" ref="patientNameInput" type="text" :placeholder="$t('patientsDataTable.lastAndFirstName')" class="border h-7 px-3 mr-2">
                                </div>

                                <!--{{ forms.update_patient }} -->

                                <div v-show="!editBirthdayMode" @click="enableEditInput('editBirthdayMode')" class="tag tag-neutral heading-6-medium-s mr-2 h-6">{{ currentPatient.birthday }}</div>
                                <div v-show="editBirthdayMode" :class="{ 'has-error': errors.update_patient.birthday[0] }">

                                    <!--<input v-----model="forms.update_patient.birthday_ymd" @blur="handleUpdatePatient()" @keyup="handlePatientEditMode($event)" @focus="reset('update_patient', 'birthday_ymd')" ref="patientBirthdayInput" type="date" placeholder="Geburtsdatum" class="border h-7 px-3 mr-2"> -->
                                    <datepicker
                                        locale="de" :cancelText="$t('patientsDataTable.cancel')" :selectText="$t('patientsDataTable.choose')"
                                        autoApply
                                        :maxDate="new Date()"
                                        :minDate="new Date(1,1,1900)"
                                        v-model="forms.update_patient.birthday_date"
                                        format="dd/MM/yyyy"
                                        @blur="handleUpdatePatient()"
                                        @keyup="handlePatientEditMode($event)"
                                        @focus="reset('update_patient', 'birthday_ymd')"
                                        class="mr-2"
                                        clllass="border h-7 w-32 text-center align-middle px-3 mr-2 rounded"
                                        :ref="refs.birthdayEditDatepicker"
                                    />


                                </div>

                                <select v-model="forms.update_patient.gender" @change="handleUpdatePatient()" class="input-select surface-1 mr-2">
                                    <option value="male">{{ $t('patientsDataTable.masculine') }}</option>
                                    <option value="female">{{ $t('patientsDataTable.female') }}</option>
                                    <option value="various">{{ $t('patientsDataTable.various') }}</option>
                                </select>

                                <div v-show="!editPatientIdMode" @click="enableEditInput('editPatientIdMode')" class="tag tag-neutral heading-6-medium-s h-6 mr-2">{{ currentPatient.patient_id }}</div>
                                <div v-show="editPatientIdMode" :class="{ 'has-error': errors.update_patient.patient_id[0] }">
                                    <input v-model="forms.update_patient.patient_id" @blur="handleUpdatePatient()" @keyup="handlePatientEditMode($event)" @focus="reset('update_patient', 'patient_id')" ref="patientIdInput" type="text" :placeholder="$t('patientsDataTable.patientId')" class="border h-7 px-3 mr-2">
                                </div>

                                <AppTooltip
                                    :trigger-type="hover"
                                    :opened="false"
                                    position="right">
                                <template v-slot:trigger><IconInfo class="tooltip-icon inline-flex" /></template>
                                <template v-slot:content><div class="text-left whitespace-pre-line">{{ $t('patientsDataTable.editInputFields') }}</div></template>
                                </AppTooltip>
                                <a @click="showConsentFileUploadModal()"
                                    :class="{
                                        'text-indigo-400': currentPatient.data_consent_status < 0,
                                        'text-danger': currentPatient.data_consent_status === 0,
                                        'text-yellow-300': currentPatient.data_consent_status === 1,
                                        'text-success': currentPatient.data_consent_status === 2,
                                    }"
                                    class="inline-block ml-3"
                                    href="javascript:">
                                        <!--<IconCheckIconic v-if="currentPatient.data_consent_status === 2"/>
                                        <IconCloseIconic v-else/>-->
                                        <span class="flex items-center leading-0 align-baseline"><IconContract class="w-5 h-5 relative mr-1 -top-0.5"/>{{ $t('patientsDataTable.dateProtection') }}</span>
                                    </a>

                            </div>
                            <div class="flex items-center">
                                <select v-model="forms.update_patient.ethnic" @change="handleUpdatePatient()" class="input-select surface-1">
                                    <option value="caucasian">{{ $t('patientsDataTable.caucasian') }}</option>
                                    <option value="asian">{{ $t('patientsDataTable.asians') }}</option>
                                    <option value="african">{{ $t('patientsDataTable.african') }}</option>
                                    <option value="hispanic">{{ $t('patientsDataTable.hispanic') }}</option>
                                    <option value="other">{{ $t('patientsDataTable.additional') }}</option>
                                </select>

                                <AppTooltip
                                    class="ml-2"
                                    :trigger-type="hover"
                                    :opened="false"
                                    position="left">
                                <template v-slot:trigger><IconInfo class="inline-flex" /></template>
                                <template v-slot:content><div class="text-left whitespace-pre-line">{{ $t('patientsDataTable.editInputFields') }} </div></template>
                                </AppTooltip>

                                <button @click="toggleFullscreen()" v-if="!fullscreen" class="enlarge-btn ml-2 flex items-center">
                                  {{ $t('patientsDataTable.enlarge') }} <IconEnlarge class="w-4 ml-2" /></button>
                                <button @click="toggleFullscreen()" v-else class="shrink-btn ml-2 flex items-center">
                                  {{ $t('patientsDataTable.zoomOut') }} <IconShrink class="w-4 ml-2" /></button>
                            </div>
                        </div>
                        <div v-if="treatments.length" v-for="treatment in treatments" :key="treatment.uuid" class="items">
                            <div class="item py-1">
                                <div @click="onTreatmentClick(treatment)" class="item-header flex items-center justify-between text-light surface-brand-4 px-4 py-2">
                                    <span>{{ $t('patientsDataTable.courseOfTreatment') }} | {{ moment(treatment.taken_at).format('DD/MM/YYYY') }}</span>
                                    <button><IconChevron class="w-2"/></button>
                                </div>
                                <div v-if="treatmentsOpened.indexOf(treatment.uuid) > -1" class="item-body surface-1 animate__animated animate__fadeIn">
                                    <div class="table-wrapper surface-brand-lighter p-2">
                                        <!-- {{ treatementEditMode }} -->
                                        <table class="treatement-course-table text-p-3-bold">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">{{ $t('patientsDataTable.sphere') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.cylinder') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.axis') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.addWithDot') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.prismaHorWithDot') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.prismaVertWithDot') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                    <th scope="col">{{ $t('patientsDataTable.HSA') }}</th>
                                                    <th class="font-bold" scope="col">{{ $t('patientsDataTable.PDMM') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">R</th>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.sphere_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.sphere_r !== null && treatment.sphere_r !== '' ? treatment.sphere_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.sphere_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'sphere_r')" tabindex="1" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.cylinder_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.cylinder_r !== null && treatment.cylinder_r !== '' ? treatment.cylinder_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.cylinder_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'cylinder_r')" tabindex="2" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.axis_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.axis_r !== null && treatment.axis_r !== '' ? treatment.axis_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.axis_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'axis_r')" tabindex="3" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.add_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.add_r !== null && treatment.add_r !== '' ? treatment.add_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.add_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'add_r')" tabindex="4"  type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_horizontal_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_horizontal_r !== null && treatment.prism_horizontal_r !== '' ? treatment.prism_horizontal_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_horizontal_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_r')" tabindex="5" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_horizontal_base_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_horizontal_base_r !== null && treatment.prism_horizontal_base_r !== '' ? treatment.prism_horizontal_base_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_horizontal_base_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_base_r')" tabindex="6" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_vertical_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_vertical_r !== null && treatment.prism_vertical_r !== '' ? treatment.prism_vertical_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_vertical_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_r')" tabindex="7" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_vertical_base_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_vertical_base_r !== null && treatment.prism_vertical_base_r !== '' ? treatment.prism_vertical_base_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_vertical_base_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_base_r')" tabindex="8" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.hsa_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.hsa_r !== null && treatment.hsa_r !== '' ? treatment.hsa_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.hsa_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'hsa_r')" type="text" tabindex="9"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.pd_r || forms.update_treatment.uuid != treatment.uuid">{{ treatment.pd_r !== null && treatment.pd_r !== '' ? treatment.pd_r : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.pd_r" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'pd_r')" tabindex="10" type="text"></template>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">L</th>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.sphere_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.sphere_l !== null && treatment.sphere_l !== '' ? treatment.sphere_l : '-'  }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.sphere_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'sphere_l')" tabindex="11" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.cylinder_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.cylinder_l !== null && treatment.cylinder_l !== '' ? treatment.cylinder_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.cylinder_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'cylinder_l')" tabindex="12" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.axis_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.axis_l !== null && treatment.axis_l !== '' ? treatment.axis_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.axis_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'axis_l')" tabindex="13" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.add_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.add_l !== null && treatment.add_l !== '' ? treatment.add_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.add_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'add_l')" tabindex="14" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_horizontal_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_horizontal_l !== null && treatment.prism_horizontal_l !== '' ? treatment.prism_horizontal_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_horizontal_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_l')" tabindex="15" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_horizontal_base_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_horizontal_base_l == null && treatment.prism_horizontal_base_l !== '' ? treatment.prism_horizontal_base_l : '-'  }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_horizontal_base_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_horizontal_base_l')" tabindex="16" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_vertical_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_vertical_l !== null && treatment.prism_vertical_l !== '' ? treatment.prism_vertical_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_vertical_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_l')" tabindex="17" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.prism_vertical_base_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.prism_vertical_base_l !== null && treatment.prism_vertical_base_l !== '' ? treatment.prism_vertical_base_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.prism_vertical_base_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'prism_vertical_base_l')" tabindex="18" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.hsa_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.hsa_l !== null && treatment.hsa_l !== '' ? treatment.hsa_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.hsa_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'hsa_l')" tabindex="19" type="text"></template>
                                                    </td>
                                                    <td class="surface-brand-light" @click="setTreatmentAllEditMode(true, treatment)">
                                                        <span v-if="!treatementEditMode.pd_l || forms.update_treatment.uuid != treatment.uuid">{{ treatment.pd_l !== null && treatment.pd_l !== '' ? treatment.pd_l : '-' }}</span>
                                                        <template v-else><input v-model="forms.update_treatment.pd_l" @blur="handleUpdateTreatment()" @focus="reset('update_treatment', 'pd_l')" tabindex="20" type="text"></template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="pl-6 pr-2 py-2">
                                        <div class="flex">
                                            <div class="flex items-center">
                                                <a v-if="treatment.finished_at" :href="getEvaluationDownloadLink(treatment.uuid)" target="_blank" class="button-primary-outlined cursor-pointer px-6 ml-2 flex items-center"><IconDownload class="mr-1"/>
                                                {{ $t('patientsDataTable.downloadEvaluation') }}</a>
                                            </div>
                                            <!--div class="flex items-center color-paul">
                                                <a v-if="treatment.finished_at" :href="getEvaluationLink(treatment.uuid)" target="_blank" class="button-primary-outlined cursor-pointer px-6 ml-2 flex items-center"><IconDownload class="mr-1"/>
                                                See Again the Page</a>
                                            </div-->
                                        </div>
                                        <div v-if="treatment.finished_at" class="flex items-center justify-end">

                                            <button v-if="treatment.taken_at >= moment().subtract(14, 'd').toISOString()" @click="showDeleteTreatmentModal(treatment)"
                                                class="button-danger button-sm flex items-center mr-3 px-4 py-2 radius-sm">
                                              {{ $t('patientsDataTable.deleteDataset') }}
                                            </button>

                                            <button @click="handleCopyTreatment(treatment)" :disabled="currentPatient.status != 1" class="button-primary button-sm radius-sm py-2 px-3">
                                              {{ $t('patientsDataTable.acceptDataRecord') }}</button>
                                            <button @click="handleCreateTreatment()" :disabled="currentPatient.status != 1" class="button-primary button-sm radius-sm ml-3 py-2 px-3">
                                              {{ $t('patientsDataTable.captureDataSet') }}</button>

                                        </div>
                                        <div v-else class="flex items-center justify-end">

                                            <button v-if="treatment.taken_at >= moment().subtract(14, 'd').toISOString()" @click="showDeleteTreatmentModal(treatment)"
                                                class="button-danger button-sm flex items-center mr-3 px-4 py-2 radius-sm">
                                              {{ $t('patientsDataTable.deleteDataset') }}
                                            </button>

                                            <button :disabled="(treatment.pd_l === null || treatment.pd_l === '') || (treatment.pd_r === null || treatment.pd_r === '')"
                                                @click="goToFastTestSequentce(treatment.uuid)"
                                                :class="{ 'opacity-25': (treatment.pd_l === null || treatment.pd_l === '') || (treatment.pd_r === null || treatment.pd_r === '') }"
                                                class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4">
                                                {{ $t('patientsDataTable.quickEntryWithValue', { valueContinue: treatment.started_at ? ` ${$t('patientsDataTable.continue')}` : '' }) }}
                                            </button>

                                            <button v-if="(treatment.pd_l === null || treatment.pd_l === '') || (treatment.pd_r === null || treatment.pd_r === '')"
                                                :class="{ 'opacity-25': (treatment.pd_l === null || treatment.pd_l === '') || (treatment.pd_r === null || treatment.pd_r === '') }"
                                                class="button-primary button-sm flex items-center ml-3 px-4 py-2 radius-sm" style="cursor: not-allowed;">
                                                {{ treatment.started_at ? ` ${$t('patientsDataTable.continue')}` : $t('patientsDataTable.restartTestSequence') }}
                                            </button>
                                            <RouterLink v-else :to="{ name: 'test-sequence-anamnese', params: { patientId: currentPatient.uuid, treatmentId: treatment.uuid }, query: { lang }}"
                                            :class="{ 'opacity-25': (treatment.pd_l === null || treatment.pd_l === '') || (treatment.pd_r === null || treatment.pd_r === '') }"
                                                class="button-primary button-sm flex items-center ml-3 px-4 py-2 radius-sm">
                                                {{ treatment.started_at ? ` ${$t('patientsDataTable.continue')}` : $t('patientsDataTable.restartTestSequence') }}
                                            </RouterLink>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-else>
                            <div class="items">
                                <div class="item py-1">
                                    <div class="item-header flex items-center justify-between text-light surface-brand-4 px-4 py-2">
                                        <span>{{ $t('patientsDataTable.courseOfTreatment') }} | DD/MM/YYYY</span>
                                        <button><IconChevron class="w-2"/></button>
                                    </div>
                                    <div class="item-body surface-1">
                                        <div class="table-wrapper surface-brand-lighter p-2">
                                            <table class="treatement-course-table text-p-3-bold">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">{{ $t('patientsDataTable.sphere') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.cylinder') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.axis') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.addWithDot') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.prismaHorWithDot') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.prismaVertWithDot') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                        <th scope="col">{{ $t('patientsDataTable.HSA') }}</th>
                                                        <th scope="col" class="font-bold">{{ $t('patientsDataTable.PDMM') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">R</th>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.sphere_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.sphere_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'sphere_r')" tabindex="1" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.cylinder_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.cylinder_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'cylinder_r')" tabindex="2" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.axis_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.axis_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'axis_r')" tabindex="3" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.add_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.add_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'add_r')" tabindex="4" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_r')" tabindex="5" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_base_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_base_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_base_r')" tabindex="6" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_r')" tabindex="7" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_base_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_base_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_base_r')" tabindex="8" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.hsa_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.hsa_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'hsa_r')" tabindex="9" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.pd_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.pd_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'pd_r')" tabindex="10" type="text"></template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">L</th>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.sphere_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.sphere_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'sphere_l')" tabindex="11" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.cylinder_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.cylinder_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'cylinder_l')" tabindex="12" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.axis_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.axis_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'axis_l')" tabindex="13" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.add_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.add_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'add_l')" tabindex="14" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_l')" tabindex="15" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_base_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_base_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_base_l')" tabindex="16" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_l')" tabindex="17" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_base_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_base_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_base_l')" tabindex="18" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.hsa_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.hsa_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'hsa_l')" tabindex="19" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.pd_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.pd_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'pd_l')" tabindex="20" type="text"></template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="pl-6 pr-2 py-2">
                                            <div class="flex items-center">
                                                <span class="subheading">{{ $t('patientsDataTable.remarks') }}</span>
                                                <!--<button class="button-primary-outlined px-6 ml-2 flex items-center"><IconDownload class="mr-1"/> Auswertung herunterladen</button>-->
                                            </div>
                                            <p class="opacity-25 mt-3 w-4/6">{{ $t('patientsDataTable.noRegisteredTherapies') }}</p>
                                            <div class="opacity-25 flex items-center justify-end">
                                                <button :disabled="true" class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4">
                                                  {{ $t('patientsDataTable.quickEntry') }}
                                                </button>

                                                <button :disabled="true" class="button-primary button-sm flex items-center ml-3 px-4 py-2 radius-sm">
                                                  {{ $t('patientsDataTable.restartTestSequence') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>
                    </div>
                </template>

            </div>

            <div v-if="createPatientMode" class="panel">

                <div class="relative px-12">
                    <button v-if="!fullscreen" @click="cancelPatientCreate()" class="btn-close absolute -top-5 right-12 text-3xl" type="button" title="Close"> × </button>

                    <div class="panel patient-info py-3">

                        <form @submit.prevent="">
                            <div class="flex items-center justify-between">
                                <div class="flex items-center">
                                    <div><div class="tag tag-success heading-6-medium-s mr-2">{{ $t('patientsDataTable.active') }}</div></div>
                                    <div :class="{ 'has-error': errors.create_patient.name[0] }" class="-heading-5-medium-s">
                                        <input v-model="forms.create_patient.name" @blur="handleStorePatient()" @keyup.enter="handleStorePatient()" @focus="reset('create_patient', 'name')" type="text" :placeholder="$t('patientsDataTable.lastAndFirstName')" class="border h-7 px-3" ref="nameInput">
                                    </div>
                                    <div :class="{ 'has-error': errors.create_patient.birthday[0] }" class="pl-2">

                                        <datepicker
                                            locale="de" :cancelText="$t('patientsDataTable.cancel')" :selectText="$t('patientsDataTable.choose')"
                                            autoApply
                                            :max-date="new Date()"
                                            :min-Date="new Date(Date.parse('1900-01-01T00:00:00.000'))"
                                            format="dd/MM/yyyy"
                                            v-model="forms.create_patient.birthday_ymd"
                                            :start-date="birthdayStartDate"
                                            focus-start-date
                                            @blur="handleStorePatient()"
                                            @keyup.enter="handleStorePatient()"
                                            @focus="reset('create_patient', 'birthday_ymd')"
                                            @open="onBirthdayDatepickerOpen"
                                            @closed="onBrithdayDatepickerClosed"
                                            :year-range="[1900, (new Date()).getFullYear()]"
                                            class="mr-2"
                                        />

                                    </div>

                                    <select v-model="forms.create_patient.gender" @change="handleStorePatient()" class="input-select surface-1 mr-2">
                                      <option value="male">{{ $t('patientsDataTable.masculine') }}</option>
                                      <option value="female">{{ $t('patientsDataTable.female') }}</option>
                                      <option value="various">{{ $t('patientsDataTable.various') }}</option>
                                    </select>

                                    <div :class="{ 'has-error': errors.create_patient.patient_id[0] }" class="pl-2">
                                        <input v-model="forms.create_patient.patient_id" @blur="handleStorePatient()" @keyup.enter="handleStorePatient()" @focus="reset('create_patient', 'patient_id')" type="text" :placeholder="$t('patientsDataTable.patientId')" class="border h-7 px-3">
                                    </div>
                                    <div class="pl-2"><IconInfo /></div>
                                </div>
                                <div :class="{ 'has-error': errors.create_patient.ethnic[0] }" class="flex items-center">
                                    <select v-model="forms.create_patient.ethnic" @change="handleStorePatient()" @focus="reset('create_patient', 'ethnic')" class="input-select surface-1">
                                      <option value="caucasian">{{ $t('patientsDataTable.caucasian') }}</option>
                                      <option value="asian">{{ $t('patientsDataTable.asians') }}</option>
                                      <option value="african">{{ $t('patientsDataTable.african') }}</option>
                                      <option value="hispanic">{{ $t('patientsDataTable.hispanic') }}</option>
                                      <option value="other">{{ $t('patientsDataTable.additional') }}</option>
                                    </select>
                                    <div class="ml-2"><IconInfo /></div>

                                    <button type="button" @click="toggleFullscreen()" v-if="!fullscreen" class="enlarge-btn ml-2 flex items-center">
                                      {{ $t('patientsDataTable.enlarge') }} <IconEnlarge class="w-4 ml-2" /></button>
                                    <button type="button" @click="toggleFullscreen()" v-else class="shrink-btn ml-2 flex items-center">
                                      {{ $t('patientsDataTable.zoomOut') }} <IconShrink class="w-4 ml-2" /></button>

                                </div>
                            </div>
                            <div class="items">
                                <div class="item py-1">
                                    <div class="item-header flex items-center justify-between text-light surface-brand-4 px-4 py-2">
                                        <span>{{ $t('patientsDataTable.courseOfTreatment') }} | DD/MM/YYYY</span>
                                        <button><IconChevron class="w-2"/></button>
                                    </div>
                                    <div class="item-body surface-1">
                                        <div class="table-wrapper surface-brand-lighter p-2">
                                            <table class="text-p-3-bold treatement-course-table">
                                                <thead>
                                                    <tr>
                                                      <th scope="col"></th>
                                                      <th scope="col">{{ $t('patientsDataTable.sphere') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.cylinder') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.axis') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.addWithDot') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.prismaHorWithDot') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.prismaVertWithDot') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.basis') }}</th>
                                                      <th scope="col">{{ $t('patientsDataTable.HSA') }}</th>
                                                      <th class="font-bold" scope="col">{{ $t('patientsDataTable.PDMM') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">R</th>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.sphere_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.sphere_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'sphere_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.cylinder_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.cylinder_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'cylinder_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.axis_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.axis_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'axis_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.add_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.add_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'add_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_base_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_base_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_base_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_base_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_base_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_base_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.hsa_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.hsa_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'hsa_r')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.pd_r">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.pd_r" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'pd_r')" type="text"></template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">L</th>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.sphere_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.sphere_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'sphere_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.cylinder_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.cylinder_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'cylinder_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.axis_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.axis_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'axis_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.add_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.add_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'add_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_horizontal_base_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_horizontal_base_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_horizontal_base_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.prism_vertical_base_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.prism_vertical_base_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'prism_vertical_base_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.hsa_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.hsa_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'hsa_l')" type="text"></template>
                                                        </td>
                                                        <td class="surface-brand-light" @click="setTreatmentAllEditMode(true)">
                                                            <span v-if="!treatementEditMode.pd_l">-</span>
                                                            <template v-else><input v-model="forms.create_treatment.pd_l" @blur="checkAndStoreTreatment()" @focus="reset('create_treatment', 'pd_l')" type="text"></template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="pl-6 pr-2 py-2">
                                            <div class="flex items-center">
                                                <span class="subheading">{{ $t('patientsDataTable.remarks') }}</span>
                                                <!--<button class="button-primary-outlined px-6 ml-2 flex items-center"><IconDownload class="mr-1"/> Auswertung herunterladen</button>-->
                                            </div>
                                            <p :class="{ 'opacity-25': !isPatientCreated }" class="mt-3 w-4/6">{{ $t('patientsDataTable.noRegisteredTherapies') }}</p>
                                            <div :class="{ 'opacity-25': !isPatientCreated }" class="flex items-center justify-end">
                                                <button :disabled="!isPatientCreated" class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4" type="submit">
                                                  {{ $t('patientsDataTable.quickEntry') }}
                                                </button>

                                                <button :disabled="!isPatientCreated" class="button-primary button-sm flex items-center ml-3 px-4 py-2 radius-sm">
                                                  {{ $t('patientsDataTable.restartTestSequence') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="panel" v-if="!(createPatientMode || currentPatient)">
                <div v-if="!treatments.length" class="panel patient-info h-96 flex justify-center items-center">
                    <div class="text-center">
                        <p class="heading-3-bold-s text-3 whitespace-pre-wrap">{{ $t('patientsDataTable.useTableDescription') }}</p>
                        <button @click="showPatientCreate()" class="button-primary button-sm inline-flex items-center mt-3 px-4 py-2 radius-sm">
                            <IconPlus class="mr-2" />
                          {{ $t('patientsDataTable.recordPatient') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="patientDeleteConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">

            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">{{ $t('patientsDataTable.deletePatient') }}</h1>
                <p class="w-96 mx-auto mt-7 leading-4 whitespace-pre-line">
                  {{ $t('patientsDataTable.deletePatientText')}}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="deletePatient()" class="button-primary w-96 mx-auto">
                      {{ $t('testOrderView.confirm') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeDeletePatientModal()" class="button-primary-outlined w-96 mx-auto">
                      {{ $t('patientsDataTable.cancel') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="treatmentDeleteConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10 uppercase">{{ $t('patientsDataTable.deleteTreatmentHistory') }}</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                   {{ $t('patientsDataTable.deleteTreatmentText') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="deleteTreatment()" class="button-primary w-96 mx-auto">
                      {{ $t('patientsDataTable.confirm') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeDeleteTreatmentModal()" class="button-primary-outlined w-96 mx-auto">
                      {{ $t('patientsDataTable.cancel') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="welcomeModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">

            <div class="flex flex-col text-center justify-between">
                <p class="w-100 mx-auto mt-7 leading-4 overflow-y-autox px-12 whitespace-pre-line">
                    {{ $t('patientsDataTable.appHelper') }}
                </p>
                <div class="flex items-center justify-between mt-7 mb-3">
                    <button @click="closeWelcomeModal()" class="button-primary w-96 mx-auto">
                        OK
                    </button>
                </div>

            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="perPageConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10 whitespace-pre-line">{{ $t('patientsDataTable.applyHelper') }}</h1>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmSavePerPage(true)" class="button-primary-outlined w-7/12 mx-auto">
                      {{ $t('patientsDataTable.noJustThisTime') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmSavePerPage()" class="button-primary w-7/12 mx-auto">
                      {{ $t('patientsDataTable.yesSetAsDefault') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppModal v-show="isConsentFileUploadModalVisible" v-if="renderConsentFileUploadModal"
        @close="closeConsentFileUploadModal()" class="modal-dimmer xl:w-3/6">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body>
            <div class="flex flex-col w-5/6 mx-auto">
                <h1 class="heading-1-bold-s pt-10 text-center uppercase">{{ $t('patientsDataTable.dateProtection') }}</h1>

                <Uploader
                    v-if="currentPatient"
                    :key="currentPatient.uuid"
                    class="mt-3"
                    :options="{
                        maxConcurrentUploads: 1
                    }"
                    :handlers="getUploadHandlers()"
                    @complete="onConsentUploadComplete"
                />

                <div class="py-6">
                    <!--{{ currentPatient }} -->
                    <div v-if="currentPatient && (currentPatient.data_consent_status === 1 || currentPatient.data_consent_status === 2)"
                        :class="{ 'border-l-8 border-yellow-300': currentPatient.data_consent_status === 1 }"
                        class="uploaded-files bg-white rounded-lg mt-3 p-4 flex items-center justify-between mb-1">
                        <div class="flex items-center h-14">
                            <div class="flex flex-1 flex-shrink-0 w-1/6">
                                <svg class="w-10 h-10 brand" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.75 19.25H16.25C17.3546 19.25 18.25 18.3546 18.25 17.25V9L14 4.75H7.75C6.64543 4.75 5.75 5.64543 5.75 6.75V17.25C5.75 18.3546 6.64543 19.25 7.75 19.25Z"></path>
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 9.25H13.75V5"></path>
                                </svg>
                            </div>
                            <div class="flex flex-col justify-between ml-3">
                                <div class="mb-0">
                                  <div v-if="currentPatient.data_consent_content_type" class="font-medium mr-3 text-gray-700 leading-tight">
                                         Consent file.{{ currentPatient.data_consent_content_type.split('/')[1] }}
                                  </div>
                                  <div class="text-gray-600 text-sm leading-tight">
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex">
                            <div class="flex px-4 text-4">
                                <div v-if="currentPatient.data_consent_user" class="flex items-center mr-2">{{ currentPatient.data_consent_user.name }}</div>
                                <div v-if="currentPatient.data_consent_uploaded_at" class="flex items-center text-gray-300">{{ moment(currentPatient.data_consent_uploaded_at).format('DD.MM.YYYY. hh:mm') }}</div>
                            </div>
                            <a :href="currentPatient.data_consent_url" target="_blank" class="flex items-center text-primary px-2 py-1 border border-transparent hover:opacity-80 rounded">Download</a>
                            <button @click="handleDeleteDataConsent()" class="flex items-center text-gray-800 opacity-80 px-2 py-1 border border-transparent hover:opacity-70 rounded">Delete</button>
                        </div>
                    </div>

                    <div class="p-5 mt-3 surface-brand rounded-md text-white">
                        <div class="documentation-inner max-h-96 overflow-y-auto">
                            <ul class="text-white max-h-32 overflow-y-auto">
                                <li class="flex items-center flex-col pb-6 w-full border-b border-white ">
                                    <p class="mt-0">
                                      {{
                                        $t('patientsDataTable.useData')
                                      }}
                                    </p>
                                    <p class="mt-4">
                                      {{
                                        $t('patientsDataTable.backgroundText')
                                      }}
                                    </p>
                                </li>
                            </ul>

                        </div>
                        <div v-if="currentPatient" class="flex align-items-center pt-4">
                            <a :href="`${options.baseUrl}/api/patients/${currentPatient.uuid}/get-data-consent-template`" target="_blank" class="doc-link-btn inlie-block bg-white rounded">{{ $t('patientsDataTable.downloadEvaluation') }}</a>
                        </div>
                    </div>

                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeDataConsentModal()" class="button-primary w-full mx-auto uppercase">{{ $t('settingsView.save') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppModal>

    <div class=" hidden absolute left-0 bottom-0 p-3 h-56 border-2 bg-gray-50 overflow-y-auto bg-opacity-90 z-10">
        <pre>debug:</pre>
        <pre>{{ forms.create_patient }}</pre>
        <pre>createPatientMode {{ createPatientMode }}</pre>
        <pre>currentPatient <br>{{ currentPatient }}</pre>

        <pre>fullscreen {{ fullscreen }}</pre>
        <!--<pre>patients {{ patients }}</pre> -->
    </div>
</template>

<script setup>

    import { reactive, ref, toRaw, computed, onMounted, watch, nextTick } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import options from '@/options.js'

    import _ from 'lodash'
    import axios from 'axios'
    import VueCookies from 'vue-cookies'

    import moment from 'moment'

    //import 'moment/locale/de'
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'

    //import Datepicker from 'vue3-datepicker'

    import AppDataTable from '@/views/components/common/AppDataTable.vue'
    import AppDropdown from '@/views/components/common/AppDropdown.vue'
    import AppModal from '@/views/components/common/AppModal.vue'
    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'
    import AppTooltip from '@/views/components/common/AppTooltip.vue'
    import Uploader from '@/views/components/common/Uploader.vue'

    import IconChevron from '@/views/components/icons/IconChevron.vue'
    import IconSearch from '@/views/components/icons/IconSearch.vue'
    import IconPlus from '@/views/components/icons/IconPlus.vue'
    import IconInfo from '@/views/components/icons/IconInfo.vue'
    import IconEnlarge from '@/views/components/icons/IconEnlarge.vue'
    import IconShrink from '@/views/components/icons/IconShrink.vue'
    import IconDownload from '@/views/components/icons/IconDownload.vue'
    import IconBasket from '@/views/components/icons/IconBasket.vue'
    import IconCheckIconic from '@/views/components/icons/IconCheckIconic.vue'
    import IconCloseIconic from '@/views/components/icons/IconCloseIconic.vue'
    import IconCloudUpload from '@/views/components/icons/IconCloudUpload.vue'
    import IconContract from '@/views/components/icons/IconContract.vue'


    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const lang = route?.query?.lang || 'de';

    let authUser = computed(() => store.getters['auth/user'])

    let title = computed(() => store.getters['patients/table'])
    let patients = computed(() => store.getters['patients/all'])

    let treatments = computed(() => store.getters['treatments/all'].data )
    let treatmentsOpened = ref([])

    let selectable = false

    let showResults = ref(true)
    let showEmpty = ref(true)

    let createPatientMode = ref(false)
    let isPatientCreated = ref(false)

    let patientStatusInput = ref()
    let patientNameInput = ref()
    let patientBirthdayInput = ref()
    let patientIdInput = ref()


    let editPatientStatusMode = ref(false)
    let editNameMode = ref(false)
    let editBirthdayMode = ref(false)
    let editPatientIdMode = ref(false)


    let fullscreen = ref(false)

    let tabindex = 0

    let refs = {
        birthdayEditDatepicker: ref(null)
    }

    const renderConsentFileUploadModal = ref(true);

    const forceRerenderShowConsentFileUploadModal = async () => {
        renderConsentFileUploadModal.value = false
        await nextTick()
        renderConsentFileUploadModal.value = true
    }

    let isConsentFileUploadModalVisible = ref(false)

    const showConsentFileUploadModal = () => {

        handleDataConsentStart()

        document.body.classList.add('overflow-hidden')
        isConsentFileUploadModalVisible.value = true
    }

    const closeConsentFileUploadModal = () => {
        document.body.classList.remove('overflow-hidden')
        isConsentFileUploadModalVisible.value = false

        forceRerenderShowConsentFileUploadModal()
    }

    let birthdayStartDate = (new Date().getFullYear() +'-01-01')

    let checkForYearPickerInterval = null
    let _yearPickerScrolling = false

    let onBirthdayDatepickerOpen = () => {
        checkForYearPickerInterval = setInterval(() => {
            let yearPickerContainer = document.querySelectorAll('.dp__overlay_cell_active')
            if (yearPickerContainer[0] && !_yearPickerScrolling) {
                try {
                    _yearPickerScrolling = true

                    setTimeout(() => {
                        yearPickerContainer[0].scrollIntoView({ block: "center", behavior: "smooth" })
                    }, 100)

                } catch(e) {}
            }
        }, 50)
    }

    let onBrithdayDatepickerClosed = () => {
        _yearPickerScrolling = false
        clearInterval(checkForYearPickerInterval)
    }
    const forms = reactive({
        search: {
            search_patient_id: '',
            search_name: '',
            search_birthday: ''
        },
        create_patient: {
            name: '',
            first_name: '',
            last_name: '',
            birthday: '',
            birthday_ymd: '',
            patient_id: '',
            status: 1,
            ethnic: 'caucasian',
            gender: 'male',
        },
        update_patient: {
            uuid: '',
            name: '',
            first_name: '',
            last_name: '',
            birthday: '',
            birthday_ymd: '',
            birthday_date: '',
            patient_id: '',
            status: 1,
            ethnic: '',
            gender: '',
        },
        create_treatment: {
            sphere_l: '',
            sphere_r: '',
            cylinder_l: '',
            cylinder_r: '',
            axis_l: '',
            axis_r: '',
            add_l: '',
            add_r: '',
            prism_horizontal_l: '',
            prism_horizontal_r: '',
            prism_horizontal_base_l: '',
            prism_horizontal_base_r: '',
            prism_vertical_l: '',
            prism_vertical_r: '',
            hsa_l: '',
            hsa_r: '',
            prism_vertical_base_l: '',
            prism_vertical_base_r: '',
            pd_l: '',
            pd_r: '',
        },
        update_treatment: {
            uuid: '',
            sphere_l: '',
            sphere_r: '',
            cylinder_l: '',
            cylinder_r: '',
            axis_l: '',
            axis_r: '',
            add_l: '',
            add_r: '',
            prism_horizontal_l: '',
            prism_horizontal_r: '',
            prism_horizontal_base_l: '',
            prism_horizontal_base_r: '',
            prism_vertical_l: '',
            prism_vertical_r: '',
            hsa_l: '',
            hsa_r: '',
            prism_vertical_base_l: '',
            prism_vertical_base_r: '',
            pd_l: '',
            pd_r: '',
        }
    })

    let treatementEditMode = reactive({
            uuid: null,
            sphere_l: false,
            sphere_r: false,
            cylinder_l: false,
            cylinder_r: false,
            axis_l: false,
            axis_r: false,
            add_l: false,
            add_r: false,
            prism_horizontal_l: false,
            prism_horizontal_r: false,
            prism_horizontal_base_l: false,
            prism_horizontal_base_r: false,
            prism_vertical_l: false,
            prism_vertical_r: false,
            hsa_l: false,
            hsa_r: false,
            prism_vertical_base_l: false,
            prism_vertical_base_r: false,
            pd_l: false,
            pd_r: false,
    })

    let errors = reactive({
        search: {
            search_patient_id: [],
            search_name: [],
            search_birthday: []
        },
        create_patient: {
            name: [],
            first_name: [],
            last_name: [],
            birthday: [],
            birthday_ymd: [],
            patient_id: [],
            ethnic: [],
            gender: [],
        },
        update_patient: {
            uuid: [],
            active: [],
            first_name: [],
            last_name: [],
            name: [],
            birthday: [],
            birthday_ymd: [],
            patient_id: [],
            ethnic: [],
            gender: [],
        },
        create_treatment: {
            sphere_l: [],
            sphere_r: [],
            cylinder_l: [],
            cylinder_r: [],
            axis_l: [],
            axis_r: [],
            add_l: [],
            add_r: [],
            prism_horizontal_l: [],
            prism_horizontal_r: [],
            prism_horizontal_base_l: [],
            prism_horizontal_base_r: [],
            prism_vertical_l: [],
            prism_vertical_r: [],
            hsa_l: [],
            hsa_r: [],
            prism_vertical_base_l: [],
            prism_vertical_base_r: [],
            pd_l: [],
            pd_r: [],
        },
        update_treatment: {
            uuid: [],
            sphere_l: [],
            sphere_r: [],
            cylinder_l: [],
            cylinder_r: [],
            axis_l: [],
            axis_r: [],
            add_l: [],
            add_r: [],
            prism_horizontal_l: [],
            prism_horizontal_r: [],
            prism_horizontal_base_l: [],
            prism_horizontal_base_r: [],
            prism_vertical_l: [],
            prism_vertical_r: [],
            hsa_l: [],
            hsa_r: [],
            prism_vertical_base_l: [],
            prism_vertical_base_r: [],
            pd_l: [],
            pd_r: [],
        }
    })

    let checkAndStoreTreatment = function() {
        console.log('checkAndStoreTreatment')
        //before
        // let fieldCount = Object.entries(forms.create_treatment).length

        // let filled = Object.entries(forms.create_treatment).filter(item => {
        //     return item[1].length > 0
        // })
        // console.log(fieldCount)
        // console.log(filled.length)

        // if(filled.length == fieldCount) {
        //     handleStoreTreatment()
        // }
        //end before

        if (forms.create_treatment.pd_l && forms.create_treatment.pd_r) {
            handleStoreTreatment()
        }
    }

    const reset = (form, field) => {
        console.log(field)
        errors[form][field] = [];

        if(form == 'create_patient' && field == 'name') {
            errors[form]['first_name'] = [];
            errors[form]['last_name'] = [];
        }
    }

    const patientDeleteConfirmationModal = ref()
    const treatmentDeleteConfirmationModal = ref()
    const selectedTreatment = ref()

    const rightsChangeConfirmationModal = ref()
    const passwordResetConfirmationModal = ref()
    const welcomeModal = ref()
    const perPageConfirmationModal = ref()

    const nameInput = ref()


    watch(forms.update_treatment, async (oldValue, newValue) => {

        let intregex = /[^\-?0-9]/g
        let decregex = /[^\-?\d+(\.\d{1,2})$]/g
        try {
            forms.update_treatment.sphere_l = newValue.sphere_l.replace(decregex, '')

            forms.update_treatment.sphere_r = newValue.sphere_r.replace(decregex, '')

            forms.update_treatment.cylinder_l = newValue.cylinder_l.replace(decregex, '')

            forms.update_treatment.cylinder_r = newValue.cylinder_r.replace(decregex, '')

            forms.update_treatment.axis_l = newValue.axis_l.replace(intregex, '')

            forms.update_treatment.axis_r = newValue.axis_r.replace(intregex, '')

            forms.update_treatment.add_l = newValue.add_l.replace(decregex, '')

            forms.update_treatment.add_r = newValue.add_r.replace(decregex, '')

            forms.update_treatment.prism_horizontal_l = newValue.prism_horizontal_l.replace(decregex, '')

            forms.update_treatment.prism_horizontal_r = newValue.prism_horizontal_r.replace(decregex, '')

            forms.update_treatment.prism_horizontal_base_l = newValue.prism_horizontal_base_l.replace(intregex, '')

            forms.update_treatment.prism_horizontal_base_r = newValue.prism_horizontal_base_r.replace(intregex, '')

            forms.update_treatment.prism_vertical_l = newValue.prism_vertical_l.replace(decregex, '')

            forms.update_treatment.prism_vertical_r = newValue.prism_vertical_r.replace(decregex, '')

            forms.update_treatment.hsa_l = newValue.hsa_l.replace(decregex, '')

            forms.update_treatment.hsa_r = newValue.hsa_r.replace(decregex, '')

            forms.update_treatment.prism_vertical_base_l = newValue.prism_vertical_base_l.replace(intregex, '')

            forms.update_treatment.prism_vertical_base_r = newValue.prism_vertical_base_r.replace(intregex, '')

            forms.update_treatment.pd_l = newValue.pd_l.replace(decregex, '')

            forms.update_treatment.pd_r = newValue.pd_r.replace(decregex, '')
        } catch(e) {
            console.log(e)
        }
    })

    watch(forms.create_treatment, async (oldValue, newValue) => {

        let intregex = /[^\-?0-9]/g
        let decregex = /[^\-?\d+(\.\d{1,2})$]/g

        try {
            forms.create_treatment.sphere_l = newValue.sphere_l.replace(decregex, '')

            forms.create_treatment.sphere_r = newValue.sphere_r.replace(decregex, '')

            forms.create_treatment.cylinder_l = newValue.cylinder_l.replace(decregex, '')

            forms.create_treatment.cylinder_r = newValue.cylinder_r.replace(decregex, '')

            forms.create_treatment.axis_l = newValue.axis_l.replace(intregex, '')

            forms.create_treatment.axis_r = newValue.axis_r.replace(intregex, '')

            forms.create_treatment.add_l = newValue.add_l.replace(decregex, '')

            forms.create_treatment.add_r = newValue.add_r.replace(decregex, '')

            forms.create_treatment.prism_horizontal_l = newValue.prism_horizontal_l.replace(decregex, '')

            forms.create_treatment.prism_horizontal_r = newValue.prism_horizontal_r.replace(decregex, '')

            forms.create_treatment.prism_horizontal_base_l = newValue.prism_horizontal_base_l.replace(intregex, '')

            forms.create_treatment.prism_horizontal_base_r = newValue.prism_horizontal_base_r.replace(intregex, '')

            forms.create_treatment.prism_vertical_l = newValue.prism_vertical_l.replace(decregex, '')

            forms.create_treatment.prism_vertical_r = newValue.prism_vertical_r.replace(decregex, '')

            forms.create_treatment.hsa_l = newValue.hsa_l.replace(decregex, '')

            forms.create_treatment.hsa_r = newValue.hsa_r.replace(decregex, '')

            forms.create_treatment.prism_vertical_base_l = newValue.prism_vertical_base_l.replace(intregex, '')

            forms.create_treatment.prism_vertical_base_r = newValue.prism_vertical_base_r.replace(intregex, '')

            forms.create_treatment.pd_l = newValue.pd_l.replace(decregex, '')

            forms.create_treatment.pd_r = newValue.pd_r.replace(decregex, '')
        } catch(e) {
            console.log(e)
        }

    })

    let currentPatient = ref()

    onMounted(async () => {
        console.log(router)

        await store.dispatch(`patients/all`)

        if (!$cookies.get('btso_welcome_shown')) {
            showWelcomeModal()
        }

        $cookies.set('btso_welcome_shown', 1, '50y')
    })

    let handleSearch = async function () {

        let payload = toRaw(forms.search)

        if (payload.search_patient_id.length == 0 &&
            payload.search_name.length == 0 &&
            payload.search_birthday
        ) {
            //alert('Please enter text')
            //return
        }

        console.log(payload.search_birthday)
        if(payload.search_birthday) {
            payload.search_birthday = moment(payload.search_birthday ).format('YYYY-MM-DD')
        } else {
            payload.search_birthday = ''
        }

        payload.page = 1

        let patients = await store.dispatch('patients/all', payload)

        let query = route.query

        let perPage = 8

        router.replace({
            query: {
                ...query,
                ...payload,
                per_page: perPage,
                page: 1
            }
        })

        //showResults.value = true
    }

    let debouncedHandleSearch = _.debounce(() => {
      handleSearch()
    }, 600)

    let handleStorePatient = async function () {

        console.log('handleStorePatient')

        let payload = toRaw(forms.create_patient)

        //if(payload.full_name && payload.birthday) {

        let nameParts = payload.name.split(' ')

        if(nameParts.length < 2) {
            //return
        }

        payload.first_name = nameParts[0]
        payload.last_name = nameParts[1]


        payload.birthday = moment(payload.birthday_ymd).format('YYYY-MM-DD')

        // if(payload.birthday) {
        //     try {
        //         payload.birthday_ymd = moment(payload.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
        //     } catch (e) {}
        // }

        console.log(payload)

        if (!isPatientCreated.value) {
            let patient = await store.dispatch('patients/storePatient', payload).catch((err) => {
                console.log('error')
                Object.assign(errors.create_patient, err.errors)
            })

            console.log("patient created")
            console.log(patient)

            if(patient) {
                isPatientCreated.value = true
                createPatientMode.value = false

                reset('create_patient', 'name')
                reset('create_patient', 'first_name')
                reset('create_patient', 'last_name')
                reset('create_patient', 'birthday')
                reset('create_patient', 'patient_id')
                reset('create_patient', 'ethnic')
                reset('create_patient', 'gender')

                //alert('Patient Created Successfully')

                currentPatient.value = patient

                forms.create_patient.first_name = ''
                forms.create_patient.last_name = ''
                forms.create_patient.name = ''
                forms.create_patient.birthday = ''
                forms.create_patient.birthday_ymd = ''
                forms.create_patient.patient_id = ''
                forms.create_patient.ethnic = 'caucasian'
                forms.create_patient.gender = 'male'
                forms.create_patient.status = 1


                forms.update_patient.uuid = currentPatient.value.uuid
                forms.update_patient.first_name = currentPatient.value.first_name
                forms.update_patient.last_name = currentPatient.value.last_name
                forms.update_patient.name = currentPatient.value.name
                forms.update_patient.birthday = currentPatient.value.birthday
                forms.update_patient.birthday_ymd = currentPatient.value.birthday_ymd
                forms.update_patient.patient_id = currentPatient.value.patient_id
                forms.update_patient.ethnic = currentPatient.value.ethnic
                forms.update_patient.gender = currentPatient.value.gender

                //await store.dispatch('treatments/all', patient.uuid)
            }
        }


        //}
    }

    let handleUpdatePatient = async function () {
        console.log('handleUpdatePatient')

        let payload = toRaw(forms.update_patient)

        let nameParts = payload.name.split(' ')

        payload.last_name = nameParts[0]
        payload.first_name = nameParts[1]

        //payload.birthday = payload.birthday_ymd

        payload.birthday = moment(payload.birthday_date).format('YYYY-MM-DD');
        payload.birthday_ymd = payload.birthday

        let patient = await store.dispatch('patients/updatePatient', payload).catch((err) => {
            console.log('error')
            Object.assign(errors.update_patient, err.errors)
        })

        console.log("patient updated")
        console.log(patient)

        if(patient) {

            editPatientStatusMode.value = false
            editNameMode.value = false
            editBirthdayMode.value = false
            editPatientIdMode.value = false

            reset('update_patient', 'first_name')
            reset('update_patient', 'last_name')
            reset('update_patient', 'name')
            reset('update_patient', 'birthday')
            // reset('update_patient', 'patient_id')
            reset('update_patient', 'ethnic')
            reset('update_patient', 'gender')

            currentPatient.value = patient

            forms.update_patient.uuid = patient.uuid
            forms.update_patient.status = patient.status

            forms.update_patient.first_name = patient.first_name
            forms.update_patient.last_name = patient.last_name
            forms.update_patient.name = patient.name
            forms.update_patient.birthday = patient.birthday
            forms.update_patient.birthday_ymd = patient.birthday_ymd
            forms.update_patient.birthday_date = moment(patient.birthday_ymd, 'YYYY-MM-DD').toDate()
            forms.update_patient.patient_id = patient.patient_id
            forms.update_patient.ethnic = patient.ethnic
            forms.update_patient.gender = patient.gender


            //alert('Patient Updated Successfully')
        }

    }

    let handlePatientEditMode = function(event) {

        if(event.key == 'Enter') {
            handleUpdatePatient()

        } else if(event.key == 'Escape') {

            editPatientStatusMode.value = false
            editNameMode.value = false
            editBirthdayMode.value = false
            editPatientIdMode.value = false

            forms.update_patient.status = currentPatient.value.status
            forms.update_patient.first_name = currentPatient.value.first_name
            forms.update_patient.last_name = currentPatient.value.last_name
            forms.update_patient.name = currentPatient.value.name
            forms.update_patient.birthday = currentPatient.value.birthday
            forms.update_patient.birthday_ymd = currentPatient.value.birthday_ymd
            forms.update_patient.patient_id = currentPatient.value.patient_id
            forms.update_patient.ethnic = currentPatient.value.ethnic
            forms.update_patient.gender = currentPatient.value.gender
        }
    }

    let enableEditInput = function(editMode) {

        if(editMode == 'editNameMode') {
            editNameMode.value = true
            nextTick(() => {
                patientNameInput.value.focus()
            })

        } else if(editMode == 'editBirthdayMode') {

            editBirthdayMode.value = true

            //console.log(refs.birthdayEditDatepicker)
            nextTick(() => {
                //patientBirthdayInput.value.focus()
                refs.birthdayEditDatepicker.value.openMenu()

            })

        } else if(editMode == 'editPatientStatusMode') {

            editPatientStatusMode.value = true
            nextTick(() => {
                patientStatusInput.value.focus()
            })

        } else if(editMode == 'editPatientIdMode') {
            editPatientIdMode.value = true
            nextTick(() => {
                patientIdInput.value.focus()
            })
        }
    }

    let handleStoreTreatment = async function () {

        console.log('handleStoreTreatment')

        let payload = toRaw(forms.create_treatment)

        console.log(payload)

        let treatment = await store.dispatch('treatments/storeTreatment', {
            patientId: currentPatient.value.uuid,
            data: payload
        }).catch((err) => {
            console.log('error')
            Object.assign(errors.create_treatment, err.errors)
        })

        console.log("treatment created")
        console.log(treatment)

        if(treatment) {

            reset('create_treatment', 'sphere_l')
            reset('create_treatment', 'sphere_r')
            reset('create_treatment', 'cylinder_l')
            reset('create_treatment', 'cylinder_r')
            reset('create_treatment', 'axis_l')
            reset('create_treatment', 'axis_r')
            reset('create_treatment', 'add_l')
            reset('create_treatment', 'add_r')
            reset('create_treatment', 'prism_horizontal_l')
            reset('create_treatment', 'prism_horizontal_r')
            reset('create_treatment', 'prism_horizontal_base_l')
            reset('create_treatment', 'prism_horizontal_base_r')
            reset('create_treatment', 'prism_vertical_l')
            reset('create_treatment', 'prism_vertical_r')
            reset('create_treatment', 'hsa_l')
            reset('create_treatment', 'hsa_r')
            reset('create_treatment', 'prism_vertical_base_l')
            reset('create_treatment', 'prism_vertical_base_r')
            reset('create_treatment', 'pd_l')
            reset('create_treatment', 'pd_r')

            forms.create_treatment.sphere_l = ''
            forms.create_treatment.sphere_r = ''
            forms.create_treatment.cylinder_l = ''
            forms.create_treatment.cylinder_r = ''
            forms.create_treatment.axis_l = ''
            forms.create_treatment.axis_r = ''
            forms.create_treatment.add_l = ''
            forms.create_treatment.add_r = ''
            forms.create_treatment.prism_horizontal_l = ''
            forms.create_treatment.prism_horizontal_r = ''
            forms.create_treatment.prism_horizontal_base_l = ''
            forms.create_treatment.prism_horizontal_base_r = ''
            forms.create_treatment.prism_vertical_l = ''
            forms.create_treatment.prism_vertical_r = ''
            forms.create_treatment.hsa_l = ''
            forms.create_treatment.hsa_r = ''
            forms.create_treatment.prism_vertical_base_l = ''
            forms.create_treatment.prism_vertical_base_r = ''
            forms.create_treatment.pd_l = ''
            forms.create_treatment.pd_r = ''


            // forms.update_treatment.uuid = treatment.uuid
            // forms.update_treatment.sphere_l = treatment.sphere_l
            // forms.update_treatment.sphere_r = treatment.sphere_r
            // forms.update_treatment.cylinder_l = treatment.cylinder_l
            // forms.update_treatment.cylinder_r = treatment.cylinder_r
            // forms.update_treatment.axis_l = treatment.axis_l
            // forms.update_treatment.axis_r = treatment.axis_r
            // forms.update_treatment.add_l = treatment.add_l
            // forms.update_treatment.add_r = treatment.add_r
            // forms.update_treatment.prism_horizontal_l = treatment.prism_horizontal_l
            // forms.update_treatment.prism_horizontal_r = treatment.prism_horizontal_r
            // forms.update_treatment.prism_horizontal_base_l = treatment.prism_horizontal_base_l
            // forms.update_treatment.prism_horizontal_base_r = treatment.prism_horizontal_base_r
            // forms.update_treatment.prism_vertical_l = treatment.prism_vertical_l
            // forms.update_treatment.prism_vertical_r = treatment.prism_vertical_r
            // forms.update_treatment.hsa_l = treatment.hsa_l
            // forms.update_treatment.hsa_r = treatment.hsa_r
            // forms.update_treatment.prism_vertical_base_l = treatment.prism_vertical_base_l
            // forms.update_treatment.prism_vertical_base_r = treatment.prism_vertical_base_r
            // forms.update_treatment.pd_l = treatment.pd_l
            // forms.update_treatment.pd_r = treatment.pd_r


            //await store.dispatch('treatments/all', currentPatient.value.uuid)

            treatmentsOpened.value.push(treatment.uuid)
        }
    }

    let handleDataConsentStart = async function() {

        let patientId = currentPatient.value.uuid

        store.dispatch('patients/doStartDataConsent', patientId).catch((err) => {
            console.log('error doStartDataConsent')
        })
    }

    let closeDataConsentModal = async function() {
        await handleDataConsentCommit()
        closeConsentFileUploadModal()
    }

    let handleDataConsentCommit = async function() {

        let patientId = currentPatient.value.uuid

        await store.dispatch('patients/doCommitDataConsent', patientId).catch((err) => {
            console.log('error doCommitDataConsent')
        })

        let patient = await store.dispatch(`patients/reloadPatient`, patientId)

        if (patient) {
            currentPatient.value = patient
        }
    }

    let handleDeleteDataConsent = async function() {

        if(!confirm('Are you sure?')) return

        let patientId = currentPatient.value.uuid

        store.dispatch('patients/doDeleteDataConsent', patientId).catch((err) => {
            console.log('error doDeleteDataConsent')
        })
    }

    let handleCreateTreatment = async function() {

      console.log('handleCreateTreatment')

      let treatment = await store.dispatch('treatments/storeTreatment', {
          patientId: currentPatient.value.uuid,
          data: {}
      }).catch((err) => {
          console.log('error')
      })

      await store.dispatch('treatments/all', { patientId: currentPatient.value.uuid, lang })
  }

    let handleCopyTreatment = async function(previousTreatment) {

        console.log('handleCopyTreatment')

        let treatment = await store.dispatch('treatments/storeTreatment', {
            patientId: currentPatient.value.uuid,
            data: {
                previous_treatment_uuid: previousTreatment.uuid
            }
        }).catch((err) => {
            console.log('error')
        })

        await store.dispatch('treatments/all', { patientId: currentPatient.value.uuid, lang })
    }

    let handleUpdateTreatment = async function () {
        console.log('handleUpdateTreatment')

        let payload = toRaw(forms.update_treatment)

        console.log(payload)

        let treatment = await store.dispatch('treatments/updateTreatment', {
            patientId: currentPatient.value.uuid,
            data: payload
        }).catch((err) => {
            console.log('error')
            Object.assign(errors.update_treatment, err.errors)
        })

        console.log("treatment updated")
        console.log(treatment)

        if(treatment) {
            reset('update_treatment', 'uuid')
            reset('update_treatment', 'sphere_l')
            reset('update_treatment', 'sphere_r')
            reset('update_treatment', 'cylinder_l')
            reset('update_treatment', 'cylinder_r')
            reset('update_treatment', 'axis_l')
            reset('update_treatment', 'axis_r')
            reset('update_treatment', 'add_l')
            reset('update_treatment', 'add_r')
            reset('update_treatment', 'prism_horizontal_l')
            reset('update_treatment', 'prism_horizontal_r')
            reset('update_treatment', 'prism_horizontal_base_l')
            reset('update_treatment', 'prism_horizontal_base_r')
            reset('update_treatment', 'prism_vertical_l')
            reset('update_treatment', 'prism_vertical_r')
            reset('update_treatment', 'hsa_l')
            reset('update_treatment', 'hsa_r')
            reset('update_treatment', 'prism_vertical_base_l')
            reset('update_treatment', 'prism_vertical_base_r')
            reset('update_treatment', 'pd_l')
            reset('update_treatment', 'pd_r')

            forms.update_treatment.uuid = ''
            forms.update_treatment.sphere_l = ''
            forms.update_treatment.sphere_r = ''
            forms.update_treatment.cylinder_l = ''
            forms.update_treatment.cylinder_r = ''
            forms.update_treatment.axis_l = ''
            forms.update_treatment.axis_r = ''
            forms.update_treatment.add_l = ''
            forms.update_treatment.add_r = ''
            forms.update_treatment.prism_horizontal_l = ''
            forms.update_treatment.prism_horizontal_r = ''
            forms.update_treatment.prism_horizontal_base_l = ''
            forms.update_treatment.prism_horizontal_base_r = ''
            forms.update_treatment.prism_vertical_l = ''
            forms.update_treatment.prism_vertical_r = ''
            forms.update_treatment.hsa_l = ''
            forms.update_treatment.hsa_r = ''
            forms.update_treatment.prism_vertical_base_l = ''
            forms.update_treatment.prism_vertical_base_r = ''
            forms.update_treatment.pd_l = ''
            forms.update_treatment.pd_r = ''

            resetTreatmentEdit()
        }
    }

    let onRecordClick = async function(patient) {
        currentPatient.value = patient

        createPatientMode.value = false

        resetPatientEdit()

        treatmentsOpened.value = []

        resetTreatmentEdit()

        forms.update_patient.uuid = currentPatient.value.uuid
        forms.update_patient.first_name = currentPatient.value.first_name
        forms.update_patient.last_name = currentPatient.value.last_name
        forms.update_patient.name = currentPatient.value.name
        forms.update_patient.birthday = currentPatient.value.birthday
        forms.update_patient.birthday_ymd = currentPatient.value.birthday_ymd
        forms.update_patient.birthday_date = moment(currentPatient.value.birthday_ymd, 'YYYY-MM-DD').toDate()
        forms.update_patient.patient_id = currentPatient.value.patient_id
        forms.update_patient.ethnic = currentPatient.value.ethnic
        forms.update_patient.gender = currentPatient.value.gender

        console.log(moment(currentPatient.value.birthday_ymd, 'YYYY-MM-DD').toDate())

        reset('update_patient', 'first_name')
        reset('update_patient', 'last_name')
        reset('update_patient', 'name')
        reset('update_patient', 'birthday_ymd')
        // reset('update_patient', 'patient_id')
        reset('update_patient', 'ethnic')
        reset('update_patient', 'gender')

        await store.dispatch('treatments/all', { patientId: patient.uuid, lang })

        //push route uuid TODO

        //console.log(treatments.value)
    }

    let showDeletePatientModal = async function(patient) {

        currentPatient.value = patient

        await patientDeleteConfirmationModal.value.show().catch((err) => {
            //console.log(err)
        })

        patientDeleteConfirmationModal.value.close()
    }

    let closeDeletePatientModal = function() {
        currentPatient.value = null
        patientDeleteConfirmationModal.value.close()
    }

    let deletePatient = async function() {

        let patient = toRaw(currentPatient.value)

        await store.dispatch('patients/deletePatient', patient)

        currentPatient.value = null

        patientDeleteConfirmationModal.value.close()
    }

    let showWelcomeModal = async function() {
        await welcomeModal.value.show()
    }

    let closeWelcomeModal = function() {
        welcomeModal.value.close()
    }

    let onTreatmentClick = function(treatment) {

        toggleTreatmentVisibility(treatment.uuid)
        console.log(treatment.uuid)

        //populateTreatmentForm(treatment)
    }

    let showDeleteTreatmentModal = async function(treatment) {

        selectedTreatment.value = treatment

        await treatmentDeleteConfirmationModal.value.show().catch((err) => {
            //console.log(err)
        })
    }

    let closeDeleteTreatmentModal = function() {
        treatmentDeleteConfirmationModal.value.close()
    }

    let deleteTreatment = async function() {


        await store.dispatch('treatments/deleteTreatment', {
            patientId: currentPatient.value.uuid,
            treatmentId: selectedTreatment.value.uuid
        })

        selectedTreatment.value = null

        closeDeleteTreatmentModal()
    }


    let populateTreatmentForm = function(treatment) {

        if (forms.update_treatment.uuid == treatment.uuid) {
            console.log('ALREADY POPULATED TREATMENT FORM !')
            return
        }

        forms.update_treatment.uuid = treatment.uuid
        forms.update_treatment.sphere_l = treatment.sphere_l
        forms.update_treatment.sphere_r = treatment.sphere_r
        forms.update_treatment.cylinder_l = treatment.cylinder_l
        forms.update_treatment.cylinder_r = treatment.cylinder_r
        forms.update_treatment.axis_l = treatment.axis_l
        forms.update_treatment.axis_r = treatment.axis_r
        forms.update_treatment.add_l = treatment.add_l
        forms.update_treatment.add_r = treatment.add_r
        forms.update_treatment.prism_horizontal_l = treatment.prism_horizontal_l
        forms.update_treatment.prism_horizontal_r = treatment.prism_horizontal_r
        forms.update_treatment.prism_horizontal_base_l = treatment.prism_horizontal_base_l
        forms.update_treatment.prism_horizontal_base_r = treatment.prism_horizontal_base_r
        forms.update_treatment.prism_vertical_l = treatment.prism_vertical_l
        forms.update_treatment.prism_vertical_r = treatment.prism_vertical_r
        forms.update_treatment.hsa_l = treatment.hsa_l
        forms.update_treatment.hsa_r = treatment.hsa_r
        forms.update_treatment.prism_vertical_base_l = treatment.prism_vertical_base_l
        forms.update_treatment.prism_vertical_base_r = treatment.prism_vertical_base_r
        forms.update_treatment.pd_l = treatment.pd_l
        forms.update_treatment.pd_r = treatment.pd_r
    }

    let toggleTreatmentVisibility = function(id) {
        if(treatmentsOpened.value.indexOf(id) > -1) {
            treatmentsOpened.value.splice(treatmentsOpened.value.indexOf(id), 1)
        } else {
            treatmentsOpened.value.push(id)
        }
    }

    let toggleFullscreen = function() {
        fullscreen.value = !fullscreen.value
    }

    let showPatientCreate = async function() {

        await store.dispatch('treatments/clearTreatments')

        currentPatient.value = null
        createPatientMode.value = true
        isPatientCreated.value = false

        resetTreatmentEdit()

        nextTick(() => {
            nameInput.value.focus()
        })
    }

    let cancelPatientCreate = function() {
        createPatientMode.value = false

        forms.create_patient.full_name = ''
        forms.create_patient.first_name = ''
        forms.create_patient.last_name = ''
        forms.create_patient.birthday = ''
        forms.create_patient.birthday_ymd = ''
        forms.create_patient.patient_id = ''
        forms.create_patient.status = 1
        forms.create_patient.ethnic = 'caucasian'
        forms.create_patient.gender = 'male'

        reset('create_patient', 'first_name')
        reset('create_patient', 'last_name')
        reset('create_patient', 'birthday')
        reset('create_patient', 'patient_id')
        reset('create_patient', 'ethnic')
        reset('create_patient', 'gender')
    }

    let cancelPatientEdit = function() {

        editNameMode.value = false
        editBirthdayMode.value = false
        editPatientIdMode.value = false

        reset('edit_patient', 'first_name')
        reset('edit_patient', 'last_name')
        reset('edit_patient', 'birthday')
        reset('edit_patient', 'patient_id')
        reset('edit_patient', 'ethnic')
        reset('edit_patient', 'gender')
    }

    let resetPatientEdit = function() {
        editNameMode.value = false
        editBirthdayMode.value = false
        editPatientIdMode.value = false
    }

    let resetTreatmentEdit = function() {

        setTreatmentAllEditMode(false)

        forms.update_treatment.uuid = ''
        forms.update_treatment.sphere_l = ''
        forms.update_treatment.sphere_r = ''
        forms.update_treatment.cylinder_l = ''
        forms.update_treatment.cylinder_r = ''
        forms.update_treatment.axis_l = ''
        forms.update_treatment.axis_r = ''
        forms.update_treatment.add_l = ''
        forms.update_treatment.add_r = ''
        forms.update_treatment.prism_horizontal_l = ''
        forms.update_treatment.prism_horizontal_r = ''
        forms.update_treatment.prism_horizontal_base_l = ''
        forms.update_treatment.prism_horizontal_base_r = ''
        forms.update_treatment.prism_vertical_l = ''
        forms.update_treatment.prism_vertical_r = ''
        forms.update_treatment.hsa_l = ''
        forms.update_treatment.hsa_r = ''
        forms.update_treatment.prism_vertical_base_l = ''
        forms.update_treatment.prism_vertical_base_r = ''
        forms.update_treatment.pd_l = ''
        forms.update_treatment.pd_r = ''
    }

    let handleTreatmentEditMode = function(fieldName) {
        treatementEditMode[fieldName] = true
    }

    let setTreatmentAllEditMode = function (value, treatment) {

        if(treatment && treatment.finished_at && authUser.value.role != 'admin') {
            return
        }

        treatementEditMode.uuid = treatment ? treatment.uuid : null
        treatementEditMode.sphere_l = value
        treatementEditMode.sphere_r = value
        treatementEditMode.cylinder_l = value
        treatementEditMode.cylinder_r = value
        treatementEditMode.axis_l = value
        treatementEditMode.axis_r = value
        treatementEditMode.add_l = value
        treatementEditMode.add_r = value
        treatementEditMode.prism_horizontal_l = value
        treatementEditMode.prism_horizontal_r = value
        treatementEditMode.prism_horizontal_base_l = value
        treatementEditMode.prism_horizontal_base_r = value
        treatementEditMode.prism_vertical_l = value
        treatementEditMode.prism_vertical_r = value
        treatementEditMode.hsa_l = value
        treatementEditMode.hsa_r = value
        treatementEditMode.prism_vertical_base_l = value
        treatementEditMode.prism_vertical_base_r = value
        treatementEditMode.pd_l = value
        treatementEditMode.pd_r = value

        if(treatment) {
            populateTreatmentForm(treatment)
        }
    }

    let changedPerPage = ''

    let onChangePerPage = function({perPage}) {
        showPerPageConfirmationModal()
        changedPerPage = perPage
    }

    let showPerPageConfirmationModal = async function() {
        perPageConfirmationModal.value.show()
    }

    let closePerPageConfirmationModal = function() {
        perPageConfirmationModal.value.close()
    }

    let confirmSavePerPage = async function(onlyThisTime) {
        if (!onlyThisTime) {
            await savePerPage(changedPerPage)
        }

        closePerPageConfirmationModal()
    }

    let savePerPage = async(perPage) => {
        let payload = { 'per_page': perPage }

        console.log(JSON.stringify(payload))

        let resp = await store.dispatch('auth/saveViewSettings', payload).catch((err) => {
            //
        })
        console.log('resp', resp)
    }

    let goToFastTestSequentce = function(treatmentId) {
        router.push({
            name: 'test-sequence-fast',
            params: {
                ...route.params,
                patientId: currentPatient.value.uuid,
                treatmentId
            },
            query: {
              lang
            }
        })
    }

    let getEvaluationDownloadLink = function(treatmentId) {
        return `${options.baseUrl}/patients/${currentPatient.value.uuid}/treatments/${treatmentId}/evaluation-download`
    }
    let getEvaluationLink = function(treatmentId) {
        let routeData = router.resolve({
            name: 'test-evaluation',
            params: {
                patientId: currentPatient.value.uuid,
                treatmentId
            },
            query: {
                lang
            }
        })
        return routeData.href
    }

    let getUploadHandlers = function() {
        return {
            'image/jpg': {
                endpoint: `/patients/${currentPatient.value.uuid}/upload-data-consent`
            },
            'image/jpeg': {
                endpoint: `/patients/${currentPatient.value.uuid}/upload-data-consent`
            },
            'image/png': {
                endpoint: `/patients/${currentPatient.value.uuid}/upload-data-consent`
            },
            'application/pdf': {
                endpoint: `/patients/${currentPatient.value.uuid}/upload-data-consent`
            },

        }
    }

    let onConsentUploadComplete = async function(data) {
        console.log(data)

        let patientId = currentPatient.value.uuid

        let patient = await store.dispatch(`patients/reloadPatient`, patientId)

    }


</script>
<style >
    .color-paul a{
        background-color: var(--color-primary-1);
    }
    .enlarge-btn {
        padding: 1px 8px;
        color: var(--color-neutral-50);
        background: var(--color-primary-800);
        border: 1px solid transparent;
        border-radius: var(--radius-sm);
    }
    .shrink-btn {
        padding: 1px 8px;
        color: var(--color-primary-800);
        background: var(--color-shade-0);
        border: 1px solid var(--color-primary-800);
        border-radius: var(--radius-sm);
    }

    .input-select {
        border: 1px solid var(--color-primary-500);
        padding: 3px 8px;
        border-radius: var(--radius-sm);
        min-width: 120px;
    }

    .item-header {
        cursor: pointer;
        border-radius: 4px 4px 0 0;
    }

    .sortable {
        cursor: pointer;
    }

    .sort-icon { transition: transform .1s ease-in; }

    .arrow--desc {
        transform: scaleY(-1);
    }

    .table-responsive {
        overflow-y: visible !important;
    }

    .checkbox-lg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .input-search { padding-right: 2rem; }

    .has-error input, .has-error select {
        border: 1px solid var(--color-error-200);
        background-color: var(--color-error-200);
        color: var(--color-primary-800);
    }

    div.dp__input_wrap .dp__pointer {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    div.dp__overlay {
        position: absolute;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        background: var(--dp-background-color);
        top: 0;
        left: 0;
        transition: opacity 1s ease-out;
        z-index: 99999;
        font-family: -apple-system,blinkmacsystemfont,Segoe UI,roboto,oxygen,ubuntu,cantarell,Open Sans,Helvetica Neue,sans-serif;
        color: var(--dp-text-color);
        min-height: 350px !important;
    }

    .dp__button { display: none !important; }

    .dp__overlay_container {
        height: 100%;
        flex-direction: column;
    }

    .dp__select {
        color: var(--color-primary-500);
        border: 1px solid var(--color-primary-500);
    }

    .dp__range_end, .dp__range_start, .dp__active_date {
        background: var(--color-primary-800);
        color: var(--dp-primary-text-color);
    }

    .dp__today {
        border: 1px solid var(--color-primary-800);
    }

    .dp__overlay_cell_active {
        background: var(--color-primary-800);
    }

    .dp__calendar_wrap {
        font-family: 'Overpass' !important;
    }

    div.dp__menu.dp__menu_index {
        box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
    }

    table {
        width: 100%;
    }

    th, td {
        width: 1%;
    }

    .filter-date-picker input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg {
        line-height: 1;
        padding: 10px 15px;
        height: 34px;
        margin: 0;
        padding-left: 37px;
        border: 0.5px solid var(--color-neutral-300);
        font-size: 13px;
        font-family: 'Overpass';
        line-height: 1;
        vertical-align: middle;
    }

    .filter-date-picker input.dp__pointer.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg::placeholder {
        color: var(--color-neutral-600) !important;
    }

    .modal * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    .modal *::-webkit-scrollbar {
        width: 4px;
    }

    .modal *::-webkit-scrollbar-track {
        background: var(--color-primary-600);
        border-radius: 20px;
    }

    .modal *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }



</style>