

<template>
   <!--<pre>{{ localPropertiesDelayed }} </pre>
   <pre>{{ localProperties }} </pre> -->

 <!-- <pre>{{ 'currentTest.properties[0].value ' + currentTest.properties[0].value }}</pre>
 <pre>{{ 'currentTest.properties[1].value ' + currentTest.properties[1].value }}</pre> -->

   <div :class="{ 'test-panel-forbidden': isForbidden }" class="test-panel pt-2 text-center w-full xl:w-3/4">

    <div class="test-item rounded">
        <p class="subheading-bold text-1 pt-2 text-left">{{ $t('monokulareFastView.monocularAccommodationText') }}</p>

        <div v-if="currentTest.method.options" class="flex items-center justify-start mt-2">
            <template v-for="(option, index) in currentTest.method.options" :key="'key-' + currentTest.method_option + '-' + index">

                <label @click.prevent="selectMethodOption(index + 1)" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none mr-3">
                    <input v-model="currentMethodOption" value="none" type="radio" class="hidden cursor-pointer">
                    <IconRadioChecked v-if="currentTest.method_option - 1 == index" />
                    <IconRadio v-else />
                    <span class="mt-1 ml-1">{{ option }}</span>
                </label>
            </template>
        </div>

        <template v-for="(method, index) in currentTest.test.methods">
            <!-- {{ localProperties }} -->
            <template v-if="currentTest.method_id == method.id">
                <div :class="{ 'opacity-25 cursor-not-allowed': currentTest.method_option == 2 }" class="mt-3 w-5/6 flex flex-wrap lg:flex-nowrap gap-8 text-left">
                    <div v-for="(property, index) in currentTest.properties">
                        <!--{{ localProperties[property.uuid] }}
                        {{ property.uuid }} -->
                        <div class="text-caption-upper text-1 py-2">{{ property.property.label }}</div>
                        <select v-model="localProperties[property.uuid]"
                            @change="selectProperty(property.uuid, $event)"
                            class="input-select w-60 surface-1 shadow-md"
                            :disabled="currentTest.method_option == 2">
                            <option value="null">{{ $t('monokulareFastView.notSpecified') }}</option>
                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                        </select>
                        <div v-if="property.property.has_delayed_selection == 1" class="flex flex-col">
                            <!--<div class="text-caption-upper text-5 py-1 mt-2 text-left">Please use input fields placeholder</div>-->
                            <div class="flex mt-1">
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'none')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]"
                                        value="none" type="radio"
                                        class="hidden cursor-pointer"
                                        :disabled="currentTest.method_option == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'none' || !localPropertiesDelayed[property.uuid]" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">{{ $t('monokulareFastView.noDelay') }}</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'plus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]"
                                        value="plus" type="radio"
                                        class="hidden cursor-pointer"
                                        :disabled="currentTest.method_option == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'plus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">{{ $t('monokulareFastView.plusDelayed') }}</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'minus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none ml-3">
                                    <input v-model="localPropertiesDelayed[property.uuid]"
                                        value="minus" type="radio"
                                        class="hidden cursor-pointer"
                                        :disabled="currentTest.method_option == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'minus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">{{ $t('monokulareFastView.minusDelayed') }}</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'plus-minus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]"
                                        value="plus-minus" type="radio"
                                        class="hidden cursor-pointer"
                                        :disabled="currentTest.method_option == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'plus-minus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1">{{ $t('monokulareFastView.plusAndMinusDelayed') }}</span>
                                </label>
                            </div>

                            <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                        </div>

                    </div>

                </div>
            </template>

        </template>

    </div>

   </div>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import IconRadio from '@/views/components/icons/IconRadio.vue'
    import IconRadioChecked from '@/views/components/icons/IconRadioChecked.vue'


    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    let localProperties = ref({})
    let localPropertiesDelayed = ref({})

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''


    let currentTest = computed(() => props.test)

    let currentMethodOption = ref(null) // default null

    let selectMethodOption = async function(option) {
        let t = toRaw(currentTest.value)
        console.log(t)

        currentMethodOption.value = option

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: t.method.id,
                method_option: '' + currentMethodOption.value
            }
        })

    }

    let selectType = async function(type) {

      let t = toRaw(currentTest.value)

      console.log(t)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              distance_type: type,
          }
      })

    //   router.push({
    //       name: 'test-sequence-control',
    //       params: {
    //           ...route.params,
    //           patientId,
    //           treatmentId,
    //           test: test,
    //           subview: 'methods'
    //       }
    //   })
    }

    let selectMethod = async function(method) {

      let t = toRaw(currentTest.value)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              method_id: method.id,
              distance_type: t.distance_type,
          }
      })

    //   router.push({
    //       name: 'test-sequence-control',
    //       params: {
    //           ...route.params,
    //           patientId,
    //           treatmentId,
    //           test: test,
    //           subview: 'properties'
    //       }
    //   })

    }

    let selectProperty = async function(propertyUuid, event) {

       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       let delayed = localPropertiesDelayed.value[propertyUuid]


       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               delayed
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let selectDelayedProperty = async function(propertyUuid, delayed) {

        if (currentMethodOption.value == 2) {
            return
        }

        let t = toRaw(currentTest.value)

        let value = localProperties.value[propertyUuid]
        value = value == 'null' ? null : value

        localPropertiesDelayed.value[propertyUuid] = delayed

        await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               delayed
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let strabismusQuestions = computed(() => store.getters['strabismusquestions/all'])

    let isForbidden = computed(() => {

        let forbidden = false

        if (strabismusQuestions.value.length) {
            forbidden = strabismusQuestions.value[0]['forbidden_answers'].indexOf(strabismusQuestions.value[0]['answer']) > -1
        }

        return forbidden
    })

    onMounted(async () => {
       console.log(treatmentId)
       console.log('Monokulare Akkommodations COMPONENT FAST')
       console.log( route.params)

       //alert(subview)

       await store.dispatch(`tests/setCurrentTitle`, 'Monokulare Akkommodations-Flexibilität ±2 dpt Flipper in Zyklen/Minute')

       currentTest.value.properties.forEach((p) => {

            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value || p.default_value
            }

            if(p.property.has_delayed_selection == 1) {
                localPropertiesDelayed.value[p.uuid] = p.delayed
            }
        })

        currentMethodOption.value = currentTest.value.method_option !== null ? currentTest.value.method_option : currentMethodOption.value

    })

</script>
<style scoped>

   .method-button.active {
       background: var(--color-primary-700);
   }

   .input-select {
       border: 1px solid var(--color-primary-500);
       padding: 8px 12px;
       border-radius: var(--radius-sm);
       min-width: 120px;
   }

   label svg { flex-shrink: 0; }
</style>

