<template>
  <div>
    <UploaderForm
      @chosen="handleFilesChosen"
      :allowedTypes="getAllowedFileTypes()"
      ref="uploaderForm"
    />
    <div v-if="false" class="mb-4 flex justify-between px-4 text-gray-600 text-sm">
      <span>{{ this.uploads.length }} uploads ({{ currentUploadCount }} in progress / {{ completedUploadCount }} complete)</span>
      <span>{{ overallProgress }}% complete</span>
    </div>

    <div v-if="uploads.length" class="bg-white rounded-lg mt-1 p-4 grid gap-y-3">
      <UploaderFile
        v-for="(upload, index) in uploads"
        :endpoint="determineEndpointFor(upload.file.type)"
        :baseURL="options.baseURL"
        :key="index"
        :upload="upload"
        @progress="handleUploadProgress"
        @change="handleUploadChange"
      /> 
    </div>

  </div>
</template>

<script>
  import get from 'lodash'
  import { v4 as uuid } from 'uuid'

  import options from '@/options.js'
  import states from '@/views/components/common/uploader/states.js'

  import UploaderForm from '@/views/components/common/UploaderForm.vue'
  import UploaderFile from '@/views/components/common/UploaderFile.vue'

  import { mapGetters } from 'vuex'

  export default {
    components: {
      UploaderForm,
      UploaderFile
    },

    props: {
      options: {
        required: false,
        type: Object,
        default: () => options
      },

      handlers: {
        required: true,
        type: Object
      }
    },

    data () {
      return {
        uploads: []
      }
    },

    computed: {
      currentUploadCount () {
        return this.uploads.filter((upload) => upload.uploading).length
      },

      completedUploadCount () {
        return this.uploads.filter((upload) => upload.complete).length
      },

      overallProgress () {
        let uploads = this.uploads.filter((upload) => !upload.cancelled && !upload.failed)

        if (uploads.length === 0) {
          return 0
        }

        return parseInt(
          uploads.reduce((a, b) => a + b.progress, 0) / uploads.length
        )
      }
    },

    methods: {
      handleUploadChange (e) {
        switch (e.state) {
          case states.UPLOADING:
            this.uploads = this.uploads.map((upload) => {
              if (e.id === upload.id) {
                upload.uploading = true
              }

              return upload
            })
            break;
          case states.COMPLETE:
            let cnt = this.uploads.length
            let completeCnt = 0

            this.uploads = this.uploads.map((upload) => {
              if (e.id === upload.id) {
                upload.complete = true
                upload.uploading = false
              
                this.$emit('complete', {
                  id: upload.id
                })
              }

              completeCnt++

              return upload
            })

            if (this.uploads.length == completeCnt) {
              this.$refs['uploaderForm'].reset()
            }

            break;
          case states.CANCELLED:
            this.uploads = this.uploads.map((upload) => {
              if (e.id === upload.id) {
                upload.cancelled = true
                upload.uploading = false
              }

              return upload
            })
            break;
          case states.FAILED:
            this.uploads = this.uploads.map((upload) => {
              if (e.id === upload.id) {
                upload.failed = true
                upload.uploading = false
              }

              return upload
            })
            break;
        }
      },

      handleUploadProgress (e) {
        this.uploads = this.uploads.map((upload) => {
          if (e.id === upload.id) {
            upload.progress = e.progress
          }

          return upload
        })
      },

      determineEndpointFor (fileType) {
        return this.handlers[fileType] ? this.handlers[fileType]['endpoint'] : null
      },

      getAllowedFileTypes () {
        let types = []

        Object.keys(this.handlers).forEach(function(key, index) {
          types.push(key.split('/')[1])
        })

        return types
      },

      handleFilesChosen (files) {

        if (true) {
          this.uploads = []
        }

        this.uploads.push(...Array.from(files).map((file) => {
          return {
            id: uuid(),
            progress: 0,
            uploading: false,
            complete: false,
            cancelled: false,
            failed: false,
            queued: true,
            file
          }
        }))
      }
    },

    mounted () {
      
      // console.log(this.handlers)
      // console.log(this.options)

      this.uploads = []

      setInterval(() => {
        if (this.currentUploadCount >= this.options.maxConcurrentUploads) {
          return
        }

        let queued = this.uploads.filter((upload) => upload.queued === true)

        if (queued.length) {
          queued[0].queued = false
        }
      }, 1000)
    }
  }
</script>
