<template>
    <!-- {{ currentTest }} -->

    <!--<pre>{{ currentTest }}</pre>
      far {{ customMethodNameFar }}   {{ currentPropertyFar }} <br>
      near {{ customMethodNameNear}}  {{ currentPropertyNear }}

      <br><br>
    {{ localProperties }}

    <br><br>  -->

    <!-- <pre>currentTest.fixation_far {{ currentTest.fixation_far ? currentTest.fixation_far : 'null' }}</pre>
    <pre>currentTest.fixation_near {{ currentTest.fixation_near ? currentTest.fixation_near : 'null' }}</pre>

    far {{ customMethodNameFar }}   {{ currentPropertyFar }} <br><br><br>
    near {{ customMethodNameNear}}  {{ currentPropertyNear }}<br><br>



    {{ localProperties }} -->

    <!--{{ currentMethod }} -->

    <div class="pt-8 flext justify-center text-center lg:w-3/6 mx-auto">

        <div v-if="subview == undefined || subview == '' || subview == 'methods'">
            <p class="label-soft text-1 py-2 w-9/12 mx-auto">{{ $t('fernophorieNahphorieView.selectAppropriateMethod') }}</p>
            <template v-for="(method, index) in currentTest.test.methods" >
                <button @click="onMethodSelect(method)"
                    :class="{ 'active': method.id == currentTest.method_id, 'mt-3': index > 0 }"
                    class="method-button button-primary w-9/12 block mx-auto shadow-lg">{{ method.name }}</button>

            </template>
        </div>

        <div v-else-if="subview == 'properties'"> <!--or method selected-->

            <template v-for="(method, index) in currentTest.test.methods">

                <template v-if="currentTest.method_id == method.id">

                    <div class="flex items-start justify-evenly">
                        <div class="w-1/2">
                            <button class="mb-8 active method-button button-primary w-9/12 block mx-auto shadow-lg">{{ getMethodNameLabel(method) }} Ferne</button>


                            <input v-if="method.custom == 1"
                                v-model="customMethodNameFar"
                                @blur="handleCustomMethodFarUpdate(method)" @keyup.enter="handleCustomMethodFarUpdate(method)"
                                type="text"
                                class="input input-sm input-border mx-auto w-72"
                                :placeholder="$t('fernophorieNahphorieView.enterTheMethod')">


                            <div class="mt-3">
                                <!-- {{ localProperties }} -->
                                <div class="text-caption-upper text-1 py-1 mt-6 w-9/12 mx-auto">{{ $t('fernophorieNahphorieView.selectAppropriateMethod') }}</div>
                                <template v-for="(property, index) in currentTest.properties">
                                   <!-- {{ property.uuid }} -->
                                    <button v-show="property.distance_type == 'far'"
                                        @click="selectProperty(property, 'far', (currentPropertyFar && currentPropertyFar.distance_type == 'far' && currentPropertyFar.uuid == property.uuid) ? currentPropertyFar.value : property.property.default_value)"
                                        :class="{ 'active': (currentPropertyFar && currentPropertyFar.uuid == property.uuid) }"
                                        class="method-button button-primary w-9/12 block mt-3 mx-auto shadow-lg">{{ property.property.label }}</button>

                                </template>

                                <template v-if="currentPropertyFar" v-for="(property, index) in currentTest.properties">
                                    <div v-show="property.distance_type == 'far' && property.uuid == currentPropertyFar.uuid">

                                        <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.label }}</div>

                                        <input v-if="method.custom == 1"
                                            v-model="customPropertyFar"
                                            @blur="handleCustomProperty(property.uuid, 'far')" @keyup.enter="handleCustomProperty(property.uuid, 'far')"
                                            type="text"
                                            class="input input-sm input-border mx-auto lg:w-72" :placeholder="$t('fernophorieNahphorieView.enterValues')">

                                        <select v-else v-model="localProperties[property.uuid]" @change="selectProperty(property, 'far', $event)" class="input-select w-9/12 surface-1 shadow-md">
                                            <option value="null">{{ $t('fernophorieNahphorieView.notSpecified') }}</option>
                                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                                        </select>

                                        <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 w-9/12 mx-auto">{{ property.info_text }}</div>

                                    </div>

                                </template>

                            </div>

                        </div>

                        <div class="w-1/2">
                            <button class="mb-8 active method-button button-primary w-9/12 block mx-auto shadow-lg">{{ $t('fernophorieNahphorieView.methodProximity', { methodName: getMethodNameLabel(method) }) }}</button>

                            <input v-if="method.custom == 1"
                                v-model="customMethodNameNear"
                                @blur="handleCustomMethodNearUpdate(method)" @keyup.enter="handleCustomMethodNearUpdate(method)"
                                type="text"
                                class="input input-sm input-border mx-auto w-72" :placeholder="$t('fernophorieNahphorieView.enterTheMethod')">

                            <div class="mt-3">
                                <!-- {{ localProperties }} -->
                                <div class="text-caption-upper text-1 py-1 mt-6 w-9/12 mx-auto">{{ $t('fernophorieNahphorieView.selectAppropriateMethod') }}</div>
                                <template v-for="(property, index) in currentTest.properties">
                                   <!-- {{ property.uuid }} -->
                                    <button v-show="property.distance_type == 'near'"
                                        @click="selectProperty(property, 'near', (currentPropertyNear && currentPropertyNear.distance_type == 'near' && currentPropertyNear.uuid == property.uuid) ? currentPropertyNear.value : property.property.default_value)"
                                        :class="{ 'active': (currentPropertyNear && currentPropertyNear.uuid == property.uuid) }"
                                        class="method-button button-primary w-9/12 block mt-3 mx-auto shadow-lg">{{ property.property.label }}</button>

                                </template>

                                <template v-if="currentPropertyNear" v-for="(property, index) in currentTest.properties">
                                    <div v-show="property.distance_type == 'near' && property.uuid == currentPropertyNear.uuid">

                                        <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.label }}</div>

                                        <input v-if="method.custom == 1"
                                            v-model="customPropertyNear"
                                            @blur="handleCustomProperty(property.uuid, 'near')" @keyup.enter="handleCustomProperty(property.uuid, 'near')"
                                            type="text"
                                            class="input input-sm input-border mx-auto md:w-72" :placeholder="$t('fernophorieNahphorieView.enterValues')">

                                        <select v-else v-model="localProperties[property.uuid]" @change="selectProperty(property, 'near', $event)" class="input-select w-9/12 surface-1 shadow-md">
                                            <option value="null">{{ $t('fernophorieNahphorieView.notSpecified') }}</option>
                                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                                        </select>

                                        <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 w-9/12 mx-auto">{{ property.info_text }}</div>

                                    </div>

                                </template>

                            </div>

                        </div>


                    </div>

                </template>

            </template>

            <button v-if="currentTest.saved" @click="resetSaved()" class="mt-4 label-underline-sm text-1">
              {{ $t('fernophorieNahphorieView.resetDefault') }}
            </button>
            <button v-else="currentTest.saved" @click="resetTest()" class="mt-4 text-p-3-underline text-1">
              {{ $t('fernophorieNahphorieView.backToMethodSelection') }}
            </button>

        </div>

    </div>

    <template v-if="!subview || subview == 'methods'">
        <div v-if="currentTest.test.info_text_title" class="documentation-info-panel w-full max-w-6xl mt-4 px-3 py-10 text-white">
            <div class="documentation-inner  max-h-96 overflow-y-auto px-3">

                <h4 class="heading-4-bold-s text-light flex items-center"><IconPdfBig class="w-8 mr-2"/> {{ currentTest.test.info_text_title }}</h4>

                <p v-html="currentTest.test.info_text_content" class="pl-1"></p>

                <a :href="currentTest.test.info_text_url" target="_blank" class="doc-link-btn mt-6 inlie-block bg-white rounded">{{ $t('fernophorieNahphorieView.download') }}</a>

            </div>
        </div>
    </template>
    <template v-if="subview == 'properties'">
        <div class="w-full mt-4 px-3">
          <object :data="currentMethod?.info_text_url" type="application/pdf" width="100%" height="450px">
            <p>Alternative text - include a link <a :href="currentMethod?.info_text_url">to the PDF!</a></p>
          </object>
        </div>
    </template>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="methodConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">{{ $t('fernophorieNahphorieView.saveAsDefault') }}</h1>
                <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                  {{ $t('fernophorieNahphorieView.saveSelectedMethodText') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmMethod(true)" class="button-primary w-7/12 mx-auto">
                      {{ $t('fernophorieNahphorieView.saveAsDefaultAndContinue') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmMethod(false)" class="button-primary-outlined  w-7/12 mx-auto">
                      {{ $t('fernophorieNahphorieView.notSaveSelectionAndContinue') }}
                    </button>
                </div>
                <p v-show="additionalModalText.length" class="w-96 mx-auto mt-7 leading-4 font-bold">
                    {{ additionalModalText }}
                </p>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw, watch } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'
    import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const lang = route?.query?.lang || 'de';

    const props = defineProps({
        'test': Object
    })

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''

    let testLabel = 'Fernphorie und Nahphorie *'

    let currentTest = computed(() => props.test)

    let localProperties = ref({})

    let localPropertiesExo = ref({})

    let localPropertiesEso = ref({})


    const methodConfirmationModal = ref()

    let additionalModalText = ref('')

    let selectedMethod = ref()

    let customMethodNameFar = ref('')
    let customMethodNameNear = ref('')

    let customPropertyFar = ref('')
    let customPropertyNear = ref('')

    let currentPropertyFar = computed(() => {
        let properties = currentTest.value.properties.filter((p) => {
            //let minVal = p.property.name == 'Esophorie' ? -1 : -1000

           // return p.value > minVal && p.distance_type == 'far'

           return (currentTest.value.fixation_far == 'eso' && p.property.name == 'Esophorie' && p.distance_type == 'far') ||
                  (currentTest.value.fixation_far == 'exo' && p.property.name == 'Exophorie' && p.distance_type == 'far')
                    ? true : false
        })

        return properties[0]
    })


    let currentPropertyNear = computed(() => {
        let properties = currentTest.value.properties.filter((p) => {
            // let minVal = p.property.name == 'Esophorie' ? -1 : -1000

            // return p.value > minVal && p.distance_type == 'near'

            return (currentTest.value.fixation_near == 'eso' && p.property.name == 'Esophorie' && p.distance_type == 'near') ||
                  (currentTest.value.fixation_near == 'exo' && p.property.name == 'Exophorie' && p.distance_type == 'near')
                    ? true : false
        })

        return properties[0]
    })

    let handleCustomMethodFarUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name_far: customMethodNameFar.value,
                distance_type: t.distance_type,
            }
        })
    }


    let handleCustomMethodNearUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name_near: customMethodNameNear.value,
                distance_type: t.distance_type,
            }
        })
    }


    let selectType = async function(type) {

       let t = toRaw(currentTest.value)

       console.log(t)

       //alert(t.uuid)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               distance_type: type,
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'methods'
           },
           query: {
             lang
           }
       })
    }

    let onMethodSelect = function(method) {

        if(method.name == 'Prismencover') {
            additionalModalText.value = 'Hinweis: Zu diesem Test sind keine aktuellen Normdaten bekannt. Es kann somit keine Auswertung der Phorie und des AC/A erfolgen'
        } else if(method.name == 'Von Gräfe') {
            additionalModalText.value = 'Hinweis: Dieser Test weist eine schlechte Wiederholbarkeit auf. Es kann somit keine Auswertung der Phorie und des AC/A erfolgen'
        } else {
            additionalModalText.value = ''
        }

        selectedMethod.value = method

        methodConfirmationModal.value.show()
    }

    let confirmMethod = function(remember) {

        if(!selectedMethod.value) return

        selectMethod(selectedMethod.value, remember)

        methodConfirmationModal.value.close()

        selectedMethod.value = null
    }

    let selectMethod = async function(method, remember = false) {

       let t = toRaw(currentTest.value)

       //alert(t.distance_type)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               method_id: method.id,
               distance_type: t.distance_type,
               remember
           }
       })

       customMethodNameFar.value = method.name

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           },
         query: {
           lang
         }
       })

       checkWizardEnabled()

    }

    let selectProperty = async function(property, distanceType, eventOrValue) {
        let propertyUuid = property.uuid
        let t = toRaw(currentTest.value)

        let fixationPropertyName = distanceType == 'far' ? 'fixation_far' : 'fixation_near'

        let data = {
            method_id: t.method_id,
            distance_type: t.distance_type,
            remember: true,
        }

        data[fixationPropertyName] = property.property.name == 'Esophorie' ? 'eso' : 'exo'

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: data
        })


        let value = (eventOrValue && eventOrValue.target) ? (eventOrValue.target.value == 'null' ? null : eventOrValue.target.value) : eventOrValue

        await store.dispatch(`tests/updateTestPoperty`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            propertyId: propertyUuid,
            data: {
                value,
                distance_type: distanceType
            }
        })

        checkWizardEnabled()

        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'properties'
            },
          query: {
            lang
          }
})

    }

    let handleCustomProperty = async function(propertyUuid, distanceType) {

       let t = toRaw(currentTest.value)

       let value = distanceType == 'far' ? customPropertyFar.value : customPropertyNear.value

       //alert(value)

       //return

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               distance_type: distanceType
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           },
         query: {
           lang
         }
       })

    }

    let resetTest = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'methods'
            },
          query: {
            lang
          }
        })
    }

    let resetSaved = async function() {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: null,
                distance_type: t.distance_type,
                remember: false
            }
        })

        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: ''
            },
          query: {
            lang
          }
        })
    }

    let switchToPropertiesView = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'properties'
            },
          query: {
            lang
          }
        })
    }

    let getMethodNameLabel = function(method) {
        if (method.name == 'Modified Thorington Card (Maddox)') {
            return 'Modified Thorington'
        }

        return method.name
    }

    watch(() => toRaw(currentTest.value.properties), (i, properties) => {

        // let isWizardDisabled = false

        // let t = toRaw(currentTest.value)

        // alert(currentPropertyFar.value.value)

        // alert(currentPropertyFar.value.value)


        // if(t.method_id === null || currentPropertyFar.value.value === null || currentPropertyNear.value.value === null) {
        //     isWizardDisabled = true
        // }

        // console.log('DISPATCH')

        // store.dispatch(`app/setWizardDisabled`, isWizardDisabled)


    }, { deep: true })


    let checkWizardEnabled = function() {

        let isWizardDisabled = false

        let t = toRaw(currentTest.value)

        if (!currentPropertyFar.value || !currentPropertyNear.value || t.method_id === null || currentPropertyFar.value.value === null || currentPropertyNear.value.value === null) {
            isWizardDisabled = true
        }

        console.log('DISPATCH')

        store.dispatch(`app/setWizardDisabled`, isWizardDisabled)

    }

    onMounted(async () => {
        console.log(treatmentId)
        console.log('FernophorieNahphorie component')
        console.log(route.params)

        //alert(subview)

        await store.dispatch(`tests/setCurrentTitle`, testLabel)

        //alert(currentTest.value.properties.length)

        currentTest.value.properties.forEach((p) => {
            console.log(p.property.name)

            let minVal = p.property.name == 'Esophorie' ? -1 : -1000

            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value > minVal
                                                ? p.value
                                                : (p.property.name == 'Exophorie' && p.distance_type == 'near' ? -5 : Number(p.property.default_value))
            }

            if(p.value > minVal) {
                if(p.distance_type == 'far') {
                    customPropertyFar.value = p.value
                } else if(p.distance_type == 'near') {
                    customPropertyNear.value = p.value
                }
            }
        })

        customMethodNameFar.value = currentTest.value.method_name_far
        customMethodNameNear.value = currentTest.value.method_name_near

        console.log(toRaw(localProperties.value))

        if(currentTest.value.saved == true && subview == '') {
            switchToPropertiesView()
        }


        checkWizardEnabled()
    })


</script>
<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

    .method-button.active {
        background: var(--color-primary-700);
    }

    .input-select {
        border: 1px solid var(--color-primary-500);
        padding: 8px 12px;
        border-radius: var(--radius-sm);
        min-width: 120px;
    }
</style>