<template>
   <!--<pre>{{ currentTest }} </pre> -->

   <div class="pt-8 flext justify-center text-center w-3/6 mx-auto">

    <div v-if="subview == undefined || subview == '' || subview == 'properties'"> <!--or method selected-->
        <p class="label-soft text-1 py-2">{{ $t('konvergenznahpunktView.selectAppropriateValuesText') }}</p>

        <template v-for="(method, index) in currentTest.test.methods">

            <template v-if="currentTest.method_id == method.id">
                <div class="mt-3">
                    <!-- {{ localProperties }} -->
                    <div v-for="(property, index) in currentTest.properties">
                        <!--{{ localProperties[property.uuid] }}
                        {{ property.uuid }} -->
                        <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.label}}</div>
                        <select v-model="localProperties[property.uuid]" @change="selectProperty(property.uuid, $event)" class="input-select w-96 surface-1 shadow-md">
                            <option value="null" :key="idx">{{ $t('konvergenznahpunktView.notSpecified') }}</option>
                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                        </select>

                        <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                    </div>

                </div>
            </template>

        </template>

    </div>

   </div>

   <template v-if="!subview || subview == 'properties'">
       <div class="w-full mt-4 px-3">
         <object :data="currentTest?.test?.info_text_url" type="application/pdf" width="100%" height="450px">
           <p>Alternative text - include a link <a :href="currentTest?.test?.info_text_url">to the PDF!</a></p>
         </object>
       </div>
    </template>


</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const lang = route?.query?.lang || 'de';

    const props = defineProps({
        'test': Object
    })

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    let localProperties = ref({})

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''


    let currentTest = computed(() => props.test)

    let selectType = async function(type) {

      let t = toRaw(currentTest.value)

      console.log(t)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              distance_type: type,
          }
      })

      router.push({
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'methods'
          },
          query: {
            lang
          }
      })
    }

    let selectMethod = async function(method) {

      let t = toRaw(currentTest.value)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              method_id: method.id,
              distance_type: t.distance_type,
          }
      })

      router.push({
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'properties'
          },
          query: {
            lang
          }
      })

    }

    let selectProperty = async function(propertyUuid, event) {

       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           },
           query: {
             lang
           }
       })

       checkWizardEnabled()

    }


    let checkWizardEnabled = function() {

        let isWizardDisabled = false

        let t = toRaw(currentTest.value)


        if(t.method_id === null ) {
            isWizardDisabled = true
        }

        for (const key in localProperties.value) {

            if(localProperties.value[key] == 'null') {
                isWizardDisabled = true
            }
        }

        store.dispatch(`app/setWizardDisabled`, isWizardDisabled)

    }

    onMounted(async () => {
       console.log(treatmentId)
       console.log('Konvergenznahpunkt COMPONENT')
       console.log( route.params)

       //alert(subview)

       await store.dispatch(`tests/setCurrentTitle`, 'Konvergenz-Nahpunkt mit Rotglas *')

       currentTest.value.properties.forEach((p) => {
            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value === null ? p.default_value : p.value
            }
        })

        checkWizardEnabled()
    })

</script>
<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

   .method-button.active {
       background: var(--color-primary-700);
   }

   .input-select {
       border: 1px solid var(--color-primary-500);
       padding: 8px 12px;
       border-radius: var(--radius-sm);
       min-width: 120px;
   }
</style>

