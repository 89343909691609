import useTests from '@/api/useTests'
import _ from 'lodash'

const { index, update, updateOrder, updateProperty, evaluationData } = useTests()

export default {
    namespaced: true,

    state () {
        return {
            tests: {
                data: []
            },
            currentTitle: '',
            graph: {},
            testData: {},
        }
    },

    getters: {
        all(state) {
            return state.tests.data
        },

        getByName(state) {
            return function (testName) {
                return _.find(state.tests.data, function (test) {
                    return test.test.label == testName
                 })
            }
        },

        currentTitle(state) {
            return state.currentTitle
        },

        graph(state) {
            return state.graph
        },
        
        anamnese(state) {
            return state.anamnese
        },

        testData(state) {
            return state.testData
        }
    },

    actions: {
        async all({ commit }, { patientId, treatmentId, group, lang } ) {

            let tests = await index(patientId, treatmentId, lang, group).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_TESTS', tests.data)
        },

        async updateTest({ commit }, { patientId, treatmentId, testId, data }) {
            console.log(testId)

            let tests = await update(patientId, treatmentId, testId, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_TEST', tests.data)
        },

        async updateTestPoperty({ commit }, { patientId, treatmentId, testId, propertyId, data }) {
            let property = await updateProperty(patientId, treatmentId, testId, propertyId, data).catch((err) => {
                return Promise.reject(err)
            })

            console.log(property)

            commit('UPDATE_TEST_PROPERTY', { testUuid: testId, property: property.data })
        },

        async updateTestSortOrder({ commit }, { patientId, treatmentId, uuids, remember = false }) {
            console.log(uuids)
            console.log(remember)

            let tests = await updateOrder(patientId, treatmentId, uuids, remember).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_TESTS', tests.data)
        },

        async setCurrentTitle({ commit }, title) {
            commit('SET_CURRENT_TITLE', title)
        },

        async graph({ commit }, { patientId, treatmentId, lang }) {
            let data = await evaluationData(patientId, treatmentId, lang).catch((err) => {
                return Promise.reject(err)
            })

            console.log(data)

            commit('SET_GRAPH_DATA', data.graph)
            commit('SET_ANAMNESE_DATA', data.anamnese)
            commit('SET_TEST_DATA', data.testData)
        },
        
    },

    mutations: {
        SET_TESTS(state, tests) {
            state.tests.data = tests
        },

        UPDATE_TEST(state, test) {
            _.assign(_.find(state.tests.data, { uuid: test.uuid }), test)
        },

        UPDATE_TEST_PROPERTY(state, { testUuid, property }) {
            //console.log(testUuid)
            
            let test = _.find(state.tests.data, { uuid: testUuid })

            if(test) {

                // reset property values with same distancy type !!!
                // test.properties.forEach(p => {
                //     if(p.distance_type == property.distance_type) {
                //         p.value = -1
                //     }
                // })


               _.assign(_.find(test.properties, { uuid: property.uuid }), property)
            }
        },

        SET_CURRENT_TITLE(state, title) {
            state.currentTitle = title
        },

        SET_GRAPH_DATA(state, data) {
            state.graph = data
        },

        SET_ANAMNESE_DATA(state, data) {
            state.anamnese = data
        },

        SET_TEST_DATA(state, data) {
            state.testData = data
        },

    }
}