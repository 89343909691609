import axios from 'axios'

export default function useUsers() {

    const index = async(params) => {

        const queryString = '?' + new URLSearchParams(params).toString()

        console.log(queryString)

        let response = await axios.get('/api/users' + queryString ).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let users = response.data

        console.log(users);

        return Promise.resolve(users)
    }

    const update = async(id, payload) => {

        let response = await axios.patch(`/api/users/${id}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let user = response.data.data

        return Promise.resolve(user)
    }

    const store = async(payload) => {

        let response = await axios.post(`/api/users`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let user = response.data.data
    
        return Promise.resolve(user)
    }

    const bulkDelete = async(payload) => {
  
        let response = await axios.post(`/api/users/bulk-delete`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        return Promise.resolve(response.data)
    }

    /*const index = async(page = 1, params = {}) => {
        const users = {
            "data": [
                {
                    "uuid": "14c4a09d-a063-447c-9b20-bd9cb635b792",
                    "email": "nbeahan@example.com",
                    "first_name": "Tracey",
                    "last_name": "Larkin",
                    "name": "Mr. Emory McCullough",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "426f614d-b0c8-4618-9d2c-b0f28948f275",
                    "email": "paufderhar@example.org",
                    "first_name": "Mia",
                    "last_name": "Hill",
                    "name": "Darron Quitzon",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "81ae59ad-4e88-48bb-a3a4-1c32a11c8239",
                    "email": "veronica.glover@example.org",
                    "first_name": "Andreanne",
                    "last_name": "Rippin",
                    "name": "Ettie Denesik",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "4ac989f6-2beb-4c0d-a0a5-f1e054540037",
                    "email": "lamont45@example.com",
                    "first_name": "Braxton",
                    "last_name": "Buckridge",
                    "name": "Ezra Jenkins",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "b9baeeb6-ee76-45f0-abfa-b3655731cd55",
                    "email": "purdy.pierce@example.com",
                    "first_name": "Paul",
                    "last_name": "Mohr",
                    "name": "Mrs. Baby Fay",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "ac16bb71-0eb5-4c6f-a5a2-854ede5b615d",
                    "email": "etha95@example.org",
                    "first_name": "Brionna",
                    "last_name": "Watsica",
                    "name": "Einar Kuvalis II",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "1bf78654-7218-4d2f-90cf-a0c90a57a17a",
                    "email": "orville.jacobi@example.org",
                    "first_name": "Dario",
                    "last_name": "Hermann",
                    "name": "Greyson Bahringer",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                },
                {
                    "uuid": "ca7da7f1-442f-4b4d-aa29-c280bd0de9d9",
                    "email": "xbatz@example.com",
                    "first_name": "Jordon",
                    "last_name": "Koelpin",
                    "name": "Prof. Nick Collier",
                    "role": "user",
                    "status": 0,
                    "company": {
                        "uuid": "3865a23c-443a-418b-a1b0-a9edc7001cf7",
                        "name": "Big Eye",
                        "membership_number": "BE9323252",
                        "status": 1
                    }
                }
            ],
            "links": {
                "first": "http://localhost:8093/api/users?page=1",
                "last": "http://localhost:8093/api/users?page=7",
                "prev": null,
                "next": "http://localhost:8093/api/users?page=2"
            },
            "meta": {
                "current_page": 1,
                "from": 1,
                "last_page": 7,
                "links": [
                    {
                        "url": null,
                        "label": "&laquo; Previous",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=1",
                        "label": "1",
                        "active": true
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=2",
                        "label": "2",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=3",
                        "label": "3",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=4",
                        "label": "4",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=5",
                        "label": "5",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=6",
                        "label": "6",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=7",
                        "label": "7",
                        "active": false
                    },
                    {
                        "url": "http://localhost:8093/api/users?page=2",
                        "label": "Next &raquo;",
                        "active": false
                    }
                ],
                "path": "http://localhost:8093/api/users",
                "per_page": 8,
                "to": 8,
                "total": 51,
                "table": "Big Eye",
                "columnNames": [
                    "user",
                    "email"
                ],
                "displayableColumnNames": [
                    {
                        "name": "user",
                        "display": "User"
                    },
                    {
                        "name": "email",
                        "display": "E-Mail Address"
                    }
                ]
            }
        }
        
       
        return Promise.resolve(users)
    }*/


  
    return {
        index,
        update,
        store,
        bulkDelete
    }

}