<template>
    <!--<pre>{{ currentTest }}</pre>
      far {{ customMethodNameFar }}   {{ currentPropertyFar }} <br>
      near {{ customMethodNameNear}}  {{ currentPropertyNear }}

    <br><br>
    <pre>{{ localProperties }}
    {{ currentMethod }} </pre>
  -->

    <div :class="{ 'test-panel-forbidden': isForbidden }" class="test-panel pt-2 w-full xl:w-3/4">

        <div class="test-item rounded">
            <p class="subheading-bold text-1 py-2 text-left">{{ $t('fernophorieNahphorieFastView.selectMeasurementMethod') }}</p>

            <template v-for="(method, index) in currentTest.test.methods" >
                <button @click="onMethodSelect(method)"
                    :class="{ 'active': method.id == currentTest.method_id }"
                    class="button-primary button-sm mr-3 mt-3 px-5 py-2 radius-sm">{{ method.name }}</button>
            </template>
        </div>

        <div>

            <template v-for="(method, index) in currentTest.test.methods">

                <template v-if="currentTest.method_id == method.id">

                    <div class="">
                        <div class="test-item rounded mt-3">
                            <p class="subheading-bold text-1 py-2 text-left">{{ $t('fernophorieNahphorieFastView.selectMethodForRemote', { currentMethod: currentMethod.name }) }}</p>

                            <input v-if="method.custom == 1"
                                v-model="customMethodNameFar"
                                @blur="handleCustomMethodFarUpdate(method)" @keyup.enter="handleCustomMethodFarUpdate(method)"
                                type="text"
                                class="input input-sm input-border w-72" :placeholder="$t('fernophorieNahphorieFastView.enterMethod')">

                            <div class="mt-3">
                                <!-- {{ localProperties }} -->
                                <pre v-if="false">
                                    value {{ currentPropertyFar.value }}
                                    default_value {{ currentPropertyFar.property.default_value }}
                                    distance_type {{ currentPropertyFar.distance_type }}
                                    property.name {{ currentPropertyFar.property.name }}
                                    selectedPropertiesValuesState {{ selectedPropertiesValuesState }}
                                </pre>

                                <template v-for="(property, index) in currentTest.properties">
                                     <!--<pre v-if="property.distance_type == 'far'">{{ property }}</pre> -->
                                   <!-- {{ property.uuid }} -->

                                    <button v-show="property.distance_type == 'far'"
                                        @click="selectProperty(property, 'far', (currentPropertyFar && currentPropertyFar.distance_type == 'far' && currentPropertyFar.uuid == property.uuid) ? currentPropertyFar.value : (selectedPropertiesValuesState[property.uuid] ? selectedPropertiesValuesState[property.uuid] : property.property.default_value))"
                                        :class="{ 'active': (currentPropertyFar && currentPropertyFar.uuid == property.uuid) }"
                                        class="button-primary button-sm px-5 py-2 radius-sm mr-3">{{ property.property.name }}</button>

                                </template>
                            </div>

                        </div>

                        <div v-if="currentPropertyFar && currentPropertiesFar.length" class="test-item rounded mt-3">
                            <p class="subheading-bold text-1 py-2 text-left">{{ $t('fernophorieNahphorieFastView.methodDistance', { currentMethod: currentMethod.name }) }}</p>
                            <!-- {{ currentPropertiesFar }} -->

                            <div class="flex w-full">
                                <template v-for="(property, index) in currentPropertiesFar">
                                    <div class="mr-8 w-1/2 xl:w-auto">

                                        <div :class="{ 'opacity-30': property.uuid != currentPropertyFar.uuid }" class="text-caption-upper text-1 py-1">{{ property.property.name }}</div>

                                        <input v-if="method.custom == 1 && property.uuid == currentPropertyFar.uuid"
                                            v-model="customPropertyFar"
                                            @blur="handleCustomProperty(property.uuid, 'far')" @keyup.enter="handleCustomProperty(property.uuid, 'far')"
                                            type="text"
                                            class="input input-sm input-border w-72"
                                            :placeholder="$t('fernophorieNahphorieFastView.enterValues')"
                                            :disabled="property.uuid != currentPropertyFar.uuid">

                                        <input v-else-if="method.custom == 1 && property.uuid != currentPropertyFar.uuid"
                                            type="text"
                                            class="input input-sm input-border w-72"
                                               :placeholder="$t('fernophorieNahphorieFastView.enterValues')"
                                            :disabled="property.uuid != currentPropertyFar.uuid">

                                        <select v-else
                                            v-model="localProperties[property.uuid]"
                                            @change="selectProperty(property, 'far', $event)"
                                            :class="{ 'opacity-30': property.uuid != currentPropertyFar.uuid }"
                                            class="input-select w-full xl:w-72 surface-1 shadow-md"
                                            :disabled="property.uuid != currentPropertyFar.uuid">
                                            <option value="null">{{ $t('fernophorieNahphorieFastView.notSpecified') }}</option>

                                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                                        </select>

                                        <div v-if="property.info_text" :class="{ 'invisible': property.uuid != currentPropertyFar.uuid }" class="mt-1 text-caption-upper text-5 w-full xl:w-60">{{ property.info_text }}</div>

                                    </div>

                                </template>
                            </div>

                        </div>

                        <div class="mt-3">
                            <div class="test-item rounded">
                                <p class="subheading-bold text-1 py-2 text-left">{{ $t('fernophorieNahphorieFastView.selectMethodForProximity', { currentMethod: currentMethod.name }) }}</p>

                                <input v-if="method.custom == 1"
                                    v-model="customMethodNameNear"
                                    @blur="handleCustomMethodNearUpdate(method)" @keyup.enter="handleCustomMethodNearUpdate(method)"
                                    type="text"
                                    class="input input-sm input-border w-72" :placeholder="$t('fernophorieNahphorieFastView.enterMethod')">

                                    <!-- {{ localProperties }} -->
                                <div class="mt-3">
                                    <template v-for="(property, index) in currentTest.properties">

                                        <button v-show="property.distance_type == 'near'"
                                            @click="selectProperty(property, 'near', (currentPropertyNear && currentPropertyNear.distance_type == 'near' && currentPropertyNear.uuid == property.uuid) ? currentPropertyNear.value : (selectedPropertiesValuesState[property.uuid] ? selectedPropertiesValuesState[property.uuid] : property.property.default_value))"
                                            :class="{ 'active': (currentPropertyNear && currentPropertyNear.uuid == property.uuid) }"
                                            class="button-primary button-sm px-5 py-2 radius-sm mr-3">{{ property.property.name }}</button>

                                     </template>
                                </div>

                            </div>

                            <div v-if="currentPropertyNear && currentPropertiesNear.length" class="test-item rounded mt-3">
                                <p class="subheading-bold text-1 py-2 text-left">{{ $t('fernophorieNahphorieFastView.methodProximity',{ currentMethod: currentMethod.name }) }}</p>

                                <div class="flex w-full">
                                    <template v-for="(property, index) in currentPropertiesNear">
                                        <div class="mr-8 w-1/2 xl:w-auto">

                                            <div :class="{ 'opacity-30': property.uuid != currentPropertyNear.uuid }" class="text-caption-upper text-1 py-1">{{ property.property.name }}</div>

                                            <input v-if="method.custom == 1 && property.uuid == currentPropertyNear.uuid"
                                                v-model="customPropertyNear"
                                                @blur="handleCustomProperty(property.uuid, 'near')"
                                                @keyup.enter="handleCustomProperty(property.uuid, 'near')"
                                                type="text"
                                                class="input input-sm input-border w-72"
                                                :placeholder="$t('fernophorieNahphorieFastView.enterValues')"
                                                :disabled="property.uuid != currentPropertyNear.uuid">

                                            <input v-else-if="method.custom == 1 && property.uuid != currentPropertyNear.uuid"
                                                type="text"
                                                class="input input-sm input-border w-72"
                                                :placeholder="$t('fernophorieNahphorieFastView.enterValues')"
                                                :disabled="property.uuid != currentPropertyNear.uuid">

                                            <select v-else
                                                v-model="localProperties[property.uuid]"
                                                @change="selectProperty(property, 'near', $event)"
                                                :class="{ 'opacity-30': property.uuid != currentPropertyNear.uuid }"
                                                class="input-select w-full xl:w-72 surface-1 shadow-md"
                                                :disabled="property.uuid != currentPropertyNear.uuid">
                                                <option value="null">{{ $t('fernophorieNahphorieFastView.notSpecified') }}</option>
                                                <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                                            </select>

                                            <div v-if="property.info_text" :class="{ 'invisible': property.uuid != currentPropertyNear.uuid }" class="mt-1 text-caption-upper text-5 w-full xl:w-60">{{ property.info_text }}</div>

                                        </div>

                                    </template>

                                </div>

                            </div>

                        </div>


                    </div>

                </template>

            </template>
            <!--
                <button v-if="currentTest.saved" @click="resetSaved()" class="mt-4 label-underline-sm text-1">Standard zurücksetzen</button>
                <button v-else="currentTest.saved" @click="resetTest()" class="mt-4 text-p-3-underline text-1">Zurück zu Methodenauswahl</button>
            -->
        </div>

    </div>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="methodConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">{{ $t('fernophorieNahphorieFastView.saveAsDefault') }}</h1>
                <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                    {{ $t('fernophorieNahphorieFastView.saveSelectedMethodText') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmMethod(true)" class="button-primary w-7/12 mx-auto">
                      {{ $t('fernophorieNahphorieFastView.saveAsDefaultAndContinue') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmMethod(false)" class="button-primary-outlined  w-7/12 mx-auto">
                        {{ $t('fernophorieNahphorieFastView.notSaveSelectionAndContinue') }}
                    </button>
                </div>
                <p v-show="additionalModalText.length" class="w-96 mx-auto mt-7 leading-4 font-bold">
                    {{ additionalModalText }}
                </p>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''

    let testLabel = 'Fernphorie und Nahphorie'

    let currentTest = computed(() => props.test)

    let localProperties = ref({})

    const methodConfirmationModal = ref()

    let additionalModalText = ref('')

    let selectedMethod = ref()

    let customMethodNameFar = ref('')
    let customMethodNameNear = ref('')

    let customPropertyFar = ref('')
    let customPropertyNear = ref('')

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    // let currentPropertyFar = computed(() => {
    //     let properties = currentTest.value.properties.filter((p) => {
    //         let minVal = p.property.name == 'Esophorie' ? -1 : -1000

    //         return p.value > minVal && p.distance_type == 'far'
    //     })
    //     console.log(toRaw(properties))

    //     return properties[0]
    // })

    // let currentPropertyNear = computed(() => {
    //     let properties = currentTest.value.properties.filter((p) => {
    //         let minVal = p.property.name == 'Esophorie' ? -1 : -1000

    //         return p.value > minVal && p.distance_type == 'near'
    //     })
    //     return properties[0]
    // })

    let currentPropertyFar = computed(() => {
        let properties = currentTest.value.properties.filter((p) => {
            //let minVal = p.property.name == 'Esophorie' ? -1 : -1000

           // return p.value > minVal && p.distance_type == 'far'

           return (currentTest.value.fixation_far == 'eso' && p.property.name == 'Esophorie' && p.distance_type == 'far') ||
                  (currentTest.value.fixation_far == 'exo' && p.property.name == 'Exophorie' && p.distance_type == 'far')
                    ? true : false
        })

        return properties[0]
    })


    let currentPropertyNear = computed(() => {
        let properties = currentTest.value.properties.filter((p) => {
            // let minVal = p.property.name == 'Esophorie' ? -1 : -1000

            // return p.value > minVal && p.distance_type == 'near'

            return (currentTest.value.fixation_near == 'eso' && p.property.name == 'Esophorie' && p.distance_type == 'near') ||
                  (currentTest.value.fixation_near == 'exo' && p.property.name == 'Exophorie' && p.distance_type == 'near')
                    ? true : false
        })

        return properties[0]
    })

    let currentPropertiesFar = computed(() => {
        let properties = currentTest.value.properties.filter((p) =>  p.distance_type == 'far')
        console.log(toRaw(properties))

        return properties
    })

    let currentPropertiesNear = computed(() => {
        let properties = currentTest.value.properties.filter((p) => p.distance_type == 'near')
        return properties
    })

    let handleCustomMethodFarUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name_far: customMethodNameFar.value,
                distance_type: t.distance_type,
            }
        })
    }


    let handleCustomMethodNearUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name_near: customMethodNameNear.value,
                distance_type: t.distance_type,
            }
        })
    }

    let strabismusQuestions = computed(() => store.getters['strabismusquestions/all'])

    let isForbidden = computed(() => {

        let forbidden = false

        if (strabismusQuestions.value.length) {
            forbidden = strabismusQuestions.value[0]['forbidden_answers'].indexOf(strabismusQuestions.value[0]['answer']) > -1
        }

        return forbidden
    })

    let selectType = async function(type) {

       let t = toRaw(currentTest.value)

       console.log(t)

       //alert(t.uuid)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               distance_type: type,
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'methods'
    //        }
    //    })
    }

    let onMethodSelect = function(method) {

        if(method.name == 'Prismencover') {
            additionalModalText.value = 'Hinweis: Zu diesem Test sind keine aktuellen Normdaten bekannt. Es kann somit keine Auswertung der Phorie und des AC/A erfolgen'
        } else if(method.name == 'Von Gräfe') {
            additionalModalText.value = 'Hinweis: Dieser Test weist eine schlechte Wiederholbarkeit auf. Es kann somit keine Auswertung der Phorie und des AC/A erfolgen'
        } else {
            additionalModalText.value = ''
        }

        selectedMethod.value = method

        methodConfirmationModal.value.show()
    }

    let confirmMethod = function(remember) {

        if(!selectedMethod.value) return

        selectMethod(selectedMethod.value, remember)

        methodConfirmationModal.value.close()

        selectedMethod.value = null
    }

    let selectMethod = async function(method, remember = false) {

       let t = toRaw(currentTest.value)

       //alert(t.distance_type)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               method_id: method.id,
               distance_type: t.distance_type,
               remember
           }
       })

       customMethodNameFar.value = method.name

       reloadProperties()

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let selectedPropertiesValuesState = ref({})

    let selectProperty = async function(property, distanceType, eventOrValue) {
        let propertyUuid = property.uuid
        let t = toRaw(currentTest.value)

        let fixationPropertyName = distanceType == 'far' ? 'fixation_far' : 'fixation_near'

        let data = {
            method_id: t.method_id,
            distance_type: t.distance_type,
            remember: true,
        }

        data[fixationPropertyName] = property.property.name == 'Esophorie' ? 'eso' : 'exo'

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: data
        })


        let value = (eventOrValue && eventOrValue.target) ? (eventOrValue.target.value == 'null' ? null : eventOrValue.target.value) : eventOrValue


       //alert(value + ' propertyUuid ' + propertyUuid)

       if (!selectedPropertiesValuesState.value[propertyUuid] || selectedPropertiesValuesState.value[propertyUuid] != value) {
        selectedPropertiesValuesState.value[propertyUuid] = value
       }
       //return

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               distance_type: distanceType
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let handleCustomProperty = async function(propertyUuid, distanceType) {

       let t = toRaw(currentTest.value)

       let value = distanceType == 'far' ? customPropertyFar.value : customPropertyNear.value

       //alert(value)

       //return

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               distance_type: distanceType
           }
       })

    //    router.push({
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }

    let resetTest = function() {
        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: 'methods'
        //     }
        // })
    }

    let resetSaved = async function() {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: null,
                distance_type: t.distance_type,
                remember: false
            }
        })

        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: ''
        //     }
        // })
    }

    let switchToPropertiesView = function() {
        // router.push({
        //     name: 'test-sequence-control',
        //     params: {
        //         ...route.params,
        //         patientId,
        //         treatmentId,
        //         test: test,
        //         subview: 'properties'
        //     }
        // })
    }


    let reloadProperties = function () {
        localProperties.value = {}
        currentTest.value.properties.forEach((p) => {
            console.log(p.property.name)

            let minVal = p.property.name == 'Esophorie' ? -1 : -1000

            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value > minVal
                                                ? p.value
                                                : (p.property.name == 'Exophorie' && p.distance_type == 'near' ? -5 : Number(p.property.default_value))
            }


            if(p.value > minVal) {
                if(p.distance_type == 'far') {
                    customPropertyFar.value = p.value
                } else if(p.distance_type == 'near') {
                    customPropertyNear.value = p.value
                }
            }
        })

        // currentTest.value.properties.forEach((p) => {
        //     localProperties.value[p.uuid] = p.value > -1 ? p.value : Number(p.property.default_value)

        //     if(p.value > -1) {
        //         if(p.distance_type == 'far') {
        //             customPropertyFar.value = p.value
        //         } else if(p.distance_type == 'near') {
        //             customPropertyNear.value = p.value
        //         }
        //     }
        // })
    }

    onMounted(async () => {
        console.log(treatmentId)
        console.log('FernophorieNahphorie component FAST')
        console.log(route.params)

        //alert(subview)

        await store.dispatch(`tests/setCurrentTitle`, testLabel)

        //alert(currentTest.value.properties.length)

        reloadProperties()

        customMethodNameFar.value = currentTest.value.method_name_far
        customMethodNameNear.value = currentTest.value.method_name_near

        console.log(toRaw(localProperties.value))

        // if(currentTest.value.saved == true && subview == '') {
        //     switchToPropertiesView()
        // }
    })

</script>
<style scoped>

    .method-button.active {
        background: var(--color-primary-700);
    }

    .input-select {
        border: 1px solid var(--color-primary-500);
        padding: 8px 12px;
        border-radius: var(--radius-sm);
        min-width: 120px;
    }
</style>