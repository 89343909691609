<template>
  <AppModal class="modal-dimmer w-1/3 px-6 py-2" v-show="true" @close="closeModalAfterTest()" >
    <template v-slot:header>
      <template></template>
    </template>
    <template v-slot:body scope="props">
      <div class="flex flex-col text-center">
        <h1 class="text-xl font-bold pt-2">{{ $t('modalAfterTestPeriodView.header') }}</h1>
        <h2 class="text-xl font-bold w-5/6 mx-auto">{{ $t('modalAfterTestPeriodView.subHeader') }}</h2>
        <p class="text-md w-2/3 mx-auto mt-3 leading-4">
          <i18n-t keypath="modalAfterTestPeriodView.text" tag="p">
            <template v-slot:siteLink>
              <a href="http://www.btso.ch" target="_blank" class="border-b border-blue-700 text-blue-700">www.btso.ch</a>
            </template>
          </i18n-t>
        </p>
        <p class="text-md w-5/6 mx-auto mt-3 leading-4">
          {{ $t('modalAfterTestPeriodView.helper') }}
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <template></template>
    </template>
  </AppModal>
</template>

<script setup>
import AppModal from '@/views/components/common/AppModal.vue'

const closeModalAfterTest = async() => {
  const modalData = document.querySelector('.modal-after-test-wrapper')
  if(modalData) {
    modalData.classList.add('hidden');
  }
};
</script>
