<template>
    <UsersDataTable />
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted,  } from 'vue'
import { useRouter } from 'vue-router'

import UsersDataTable from '@/views/components/UsersDataTable.vue'

const store = useStore()
const router = useRouter()

const form = reactive({
    name: '',
    email: '',
    membership_number: '',
    password: '',
    repeat_password: '',
    accept_terms: false, 
})

let errors = reactive({
    name: [],
    email: [],
    membership_number: [],
    password: [],
    repeat_password: [],
    accept_terms: [], 
})

let tabindex = 0

let showUser = function(user) {
    alert(user.uuid)
}

let isModalVisible = ref(false)

onMounted(() => {
    store.dispatch('app/showMenu', true)
})

const reset = (field) => {
    console.log(field)
    errors[field] = [];
} 

const register = async() => {

    let payload = toRaw(form)

    let resp = await store.dispatch('auth/register', payload).catch((err) => {
        Object.assign(errors, err.errors) 
    })

    if (resp) {
        showModal()
    }

    //router.push({ name: 'login' })
}

const showModal = () => {
    document.body.classList.add('overflow-hidden')
    isModalVisible.value = true
}

const closeModal = () => {
    document.body.classList.remove('overflow-hidden')
    isModalVisible.value = false
}

const handleDropdownInput = (event) => {
    console.log(event)
}

</script>