<template>
  <Transition name="fade">
    <AppModal v-if="isVisible" @close="close" v-bind="$attrs" >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>
    </AppModal>
  </Transition>
</template>

<script setup>
  import { ref } from 'vue'
  import AppModal from './AppModal.vue'

  defineProps({
    data: {
      type: Object,
      required: false
    }
  })

  let isVisible = ref(false)

  let resolvePromise;
  let rejectPromise;

  const emit = defineEmits(['close'])

  const show = () => {
      document.body.classList.add('overflow-hidden')
      isVisible.value = true

      return new Promise((resolve, reject) => {
        resolvePromise = resolve
        rejectPromise = reject
      })
  }

  const close = () => {
      document.body.classList.remove('overflow-hidden')
      isVisible.value = false
      emit('close')
  }

  defineExpose({
    show: show,
    close: close
  })
</script>
