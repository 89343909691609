<template>
  <Transition name="fade">
    <div class="modal-backdrop" @click="closeBd">
      <div class="modal mx-auto" v-bind="$attrs" >
        <header class="modal-header">
          <slot name="header">
            [TITLE]
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
          >
            &times;
          </button>
        </header>

        <section class="modal-body">
          <slot name="body">
            [BODY]
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">
            [FOOTER]
            <button
            type="button"
            class="btn-green"
            @click="close"
          >
            Close Modal
          </button>
          </slot>

        </footer>
      </div>
    </div>
  </Transition>
</template>

<script>
  export default {
    name: 'AppModal',
    inheritAttrs: false,
    methods: {
      close() {
        this.$emit('close')
      },
      closeBd() {
        this.$emit('bd-close')
      }
    },
  }
</script>


<style scoped>

.modal-backdrop {
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: var( --color-shade-light);
  box-shadow: 2px 2px 20px 1px;
  border-radius: var(--radius-sm);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.modal-dimmer {
  background: var(--color-shade-light-softer);
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  /* border-bottom: 1px solid #eeeeee; */
  color:  var(--color-primary-500)B;
  justify-content: space-between;
}

.modal-footer {
  /* border-top: 1px solid #eeeeee; */
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 29px;
  padding: 10px;
  cursor: pointer;
  color: var(--color-primary-500);
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 1366px) {
  .modal {
    width: 70% !important;
    max-width: 70% !important;
    max-height: 100%;
  }
}
</style>
