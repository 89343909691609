import { createI18n } from 'vue-i18n';
import de from '@/i18n/locales/de.json';
import en from '@/i18n/locales/en.json';
import fr from '@/i18n/locales/fr.json';
import it from '@/i18n/locales/it.json';
import es from '@/i18n/locales/es.json';

const params = new URL(document.location.toString()).searchParams;
const urlLang = params.get("lang");
const localStorageLang = window.localStorage.getItem('lang');
const supportedLanguages = import.meta.env.VITE_SUPPORTED_LOCALES.split(',');

let lang = import.meta.env.VITE_DEFAULT_LOCALE;

if (localStorageLang && supportedLanguages.includes(localStorageLang)) {
  lang = localStorageLang;
} else if (urlLang && supportedLanguages.includes(urlLang)) {
  lang = urlLang;
  window.localStorage.setItem('lang', lang);
} else {
  window.localStorage.setItem('lang', lang);
}

export default createI18n({
  locale: lang,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  messages: {
    de,
    en,
    it,
    fr,
    es
  }
});