import useUsers from '@/api/useUsers'
import _ from 'lodash'
import { useRoute } from 'vue-router'

const { index, update, store, bulkDelete } = useUsers()

const route = useRoute()

export default {
    namespaced: true,

    state () {
        return {
            /*users: {
                table: '',
                columnNames: [],
                records: [],
                meta: null
            },*/

            users: {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    links: [],
                    path: "",
                    per_page: 0,
                    to: 0,
                    total: 0,
                    table: "",
                    columnNames: [],
                    displayableColumnNames: []
                }
            },
        }
    },

    getters: {
        // users (state) {
        //     return state.users 
        // },
        all (state) {
            return state.users 
        },

        table (state) {
            return state.users.meta.table
        }
    },

    actions: {
        async all({commit}, params) {
            console.log(params)


            //return

            let users = await index(params).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_USERS', users)
        },

        async storeUser({commit}, data) {
            let user = await store(data).catch((err) => {
                return Promise.reject(err)
            })
            //commit('STORE_USER', user)

            return Promise.resolve(user)
        },

        async updateUser({commit}, data) {
            let id = data.uuid
            console.log(data)

            let user = await update(id, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_USER', user)
        },

        async bulkDelete({commit, dispatch}, uuids) {

            console.log(uuids)
            
            let payload = { uuids } 

            let response = await bulkDelete(payload).catch((err) => {
                return Promise.reject(err)
            })

            return Promise.resolve(response.data)
        },


        // async users({commit}, params) {

        //     console.log(params)


        //     //return

        //     let users = await fetchUsers(params).catch((err) => {
        //         return Promise.reject(err)
        //     })

        //     commit('SET_USERS', users)
        // }
    },

    mutations: {
        SET_USERS (state, users) {
            state.users = users
        },

        STORE_USER (state, user) {
            console.log("state.users.data")
            console.log(state.users.data)

            state.users.data.pop() // necessary to fix pagination and keys
            state.users.data.unshift(user)
        },

        UPDATE_USER (state, user) {
            console.log("state.users.data")
            console.log(state.users.data)
            
            _.assign(_.find(state.users.data, { uuid: user.uuid }), user)
        }
    }
}