import useDocuments from '@/api/useDocuments'
import _ from 'lodash'

const { index } = useDocuments()

export default {
    namespaced: true,

    state () {
        return {
            documents: {
                data: [],
            },
        }
    },

    getters: {
        all (state) {
            return state.documents.data
        },
    },

    actions: {
        async all({ commit }, { treatmentId, lang }) {
            console.log('lang', lang)
            let documents = await index(treatmentId, lang).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_DOCUMENTS', documents)

            return Promise.resolve(documents)
        }
    },

    mutations: {
        SET_DOCUMENTS (state, documents) {
            state.documents = documents
        }
    }
}