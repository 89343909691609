import { createStore } from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import patients from './modules/patients'
import treatments from './modules/treatments'
import users from './modules/users'
//import questions from './modules/questions'
import anamnesequestions from './modules/questions'
import strabismusquestions from './modules/questions'
import documents from './modules/documents'

import tests from './modules/tests'

export default createStore({
    modules: {
        app: app,
        auth: auth,
        users: users,
        patients: patients,
        treatments: treatments,
        /*questions: questions,*/
        anamnesequestions: anamnesequestions,
        strabismusquestions: strabismusquestions,
        tests: tests,
        documents: documents,
    }
})