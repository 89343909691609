<template>
   <!--<pre> {{ currentTest.properties }} </pre>

   <pre>{{ localProperties }}</pre>
   <pre>{{ customInputRefs }}</pre> -->

    <div class="pt-8 flext justify-center text-center xl:w-3/6 mx-auto">

        <div v-if="subview == undefined || subview == '' || subview == 'methods'">
            <p class="label-soft text-1 py-2">{{ $t('visusFerneView.selectPreferredMethod') }}</p>
            <template v-for="(method, index) in currentTest.test.methods" >
                <button @click="onMethodSelect(method)"
                  :class="{ 'active': method.id == currentTest.method_id, 'mt-3': index > 0 }"
                  class="method-button button-primary w-9/12 mx-auto shadow-lg flex items-center justify-between self-center">
                  <span class="self-center flex-1">{{ method.name }}</span>
                  <component v-if="method.icon" :is="methodIcons[method.icon]" class="self-end mr-3 block h-10 -top-1" />
                </button>

            </template>
        </div>

        <div v-else-if="subview == 'properties'"> <!--or method selected-->

            <template v-for="(method, index) in currentTest.test.methods">

                <template v-if="currentTest.method_id == method.id">

                    <button class="mb-8 active method-button button-primary mt-3 w-9/12 mx-auto shadow-lg flex items-center justify-between self-center">
                        <span class="self-center flex-1">{{ method.name }}</span>
                        <component v-if="method.icon" :is="methodIcons[method.icon]" class="self-end mr-3 block h-10 -top-1" />

                    </button>

                    <input v-if="method.custom == 1"
                        v-model="customMethodName"
                        @blur="handleCustomMethodUpdate(method)" @keyup.enter="handleCustomMethodUpdate(method)"
                        type="text"
                        class="input input-sm input-border mx-auto w-72" :placeholder="$t('visusFerneView.enterTheMethod')">

                    <div class="mt-3">
                        <!-- {{ localProperties }} -->
                        <div v-for="(property, index) in currentTest.properties">
                            <!--{{ localProperties[property.uuid] }}
                            {{ property.uuid }} -->
                            <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.label}}</div>

                            <input :tabindex="index" v-if="method.custom == 1"
                                v-model="localProperties[property.uuid]"
                                @blur="handleCustomProperty(property.uuid, index)" @keyup.enter="handleCustomProperty(property.uuid, index)"
                                type="text"
                                class="input input-sm input-border mx-auto w-72" :placeholder="$t('visusFerneView.enterValues')"
                                ref="customInputRefs">

                            <select v-else v-model="localProperties[property.uuid]" @change="selectProperty(property.uuid, $event)" class="input-select w-96 surface-1 shadow-md">
                                <option value="null" :key="idx">{{ $t('visusFerneView.notSpecified') }}</option>
                                <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                            </select>

                            <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                        </div>

                    </div>
                </template>

            </template>

            <button v-if="currentTest.saved" @click="resetSaved()" class="mt-4 label-underline-sm text-1">{{ $t('visusFerneView.resetDefault') }}</button>
            <button v-else="currentTest.saved" @click="resetTest()" class="mt-4 text-p-3-underline text-1">{{ $t('visusFerneView.backToMethodSelection') }}</button>

        </div>

    </div>

    <template v-if="!subview || subview == 'methods'">
        <div v-if="currentTest.test.info_text_title" class="documentation-info-panel w-full max-w-6xl mt-4 px-3 py-10 text-white">
            <div class="documentation-inner  max-h-96 overflow-y-auto px-3">

                <h4 class="heading-4-bold-s text-light flex items-center"><IconPdfBig class="w-8 mr-2"/> {{ currentTest.test.info_text_title }}</h4>

                <p v-html="currentTest.test.info_text_content" class="pl-1"></p>

                <a :href="currentTest.test.info_text_url" target="_blank" class="doc-link-btn mt-6 inlie-block bg-white rounded">{{ $t('visusFerneView.download') }}</a>

            </div>
        </div>
    </template>
    <template v-if="subview == 'properties'">
      <div class="w-full mt-4 px-3">
        <object :data="currentMethod?.info_text_url" type="application/pdf" width="100%" height="450px">
          <p>Alternative text - include a link <a :href="currentMethod?.info_text_url">to the PDF!</a></p>
        </object>
      </div>
    </template>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="methodConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">Als Standard speichern</h1>
                <p class="w-80 mx-auto mt-7 leading-4 whitespace-pre-line">
                  {{ $t('visusFerneView.saveMethodText') }}
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmMethod(true)" class="button-primary w-7/12 mx-auto">
                      {{ $t('visusFerneView.saveAsDefaultAndContinue') }}
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmMethod(false)" class="button-primary-outlined  w-7/12 mx-auto">
                      {{ $t('visusFerneView.notSaveSelectionAndContinue') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'
    import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

    import LandoltringeIcon from '@/views/components/icons/LandoltringeIcon.vue'
    import ZahlenIcon from '@/views/components/icons/ZahlenIcon.vue'
    import BuchstabenIcon from '@/views/components/icons/BuchstabenIcon.vue'
    import ZahlenBuchstabenGemishtIcon from '@/views/components/icons/ZahlenBuchstabenGemishtIcon.vue'
    import LeaSehzeichenIcon from '@/views/components/icons/LeaSehzeichenIcon.vue'


    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const lang = route?.query?.lang || 'de';

    const props = defineProps({
        'test': Object
    })

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''

    let testLabel = 'Visuswerte Ferne'

    let currentTest = computed(() => props.test)

    const customInputRefs = ref([])

    const methodConfirmationModal = ref()

    let selectedMethod = ref()

    let customMethodName = ref('')

    let localProperties = ref({})

    const iconMap = {
        'LandoltringeIcon': LandoltringeIcon,
        'ZahlenIcon': ZahlenIcon,
        'BuchstabenIcon': BuchstabenIcon,
        'ZahlenBuchstabenGemishtIcon': ZahlenBuchstabenGemishtIcon,
        'LeaSehzeichenIcon': LeaSehzeichenIcon,
    }

    let methodIcons = ref({})

    let renderDynamicIcon = function(name) {
        if(name) {
            methodIcons.value[name] = markRaw(iconMap[name])
        }
    }


    let selectType = async function(type) {

       let t = toRaw(currentTest.value)

       console.log(t)

       //alert(t.uuid)

       await store.dispatch(`tests/updateTest`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           data: {
               distance_type: type,
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'methods'
           },
           query: {
             lang
           }
       })
    }

    let onMethodSelect = function(method) {
        selectedMethod.value = method

        methodConfirmationModal.value.show()
    }

    let confirmMethod = function(remember) {

        if(!selectedMethod.value) return

        selectMethod(selectedMethod.value, remember)

        methodConfirmationModal.value.close()

        selectedMethod.value = null
    }

    let selectMethod = async function(method, remember = false) {

        let t = toRaw(currentTest.value)

        //alert(t.distance_type)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                distance_type: t.distance_type,
                remember
            }
        })

        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'properties'
            },
          query: {
            lang
          }
        })

    }

    let handleCustomMethodUpdate = async function(method) {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: method.id,
                method_name: customMethodName.value,
                distance_type: t.distance_type,
            }
        })
    }

    let selectProperty = async function(propertyUuid, event) {

       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           },
         query: {
           lang
         }
       })

    }

    let handleCustomProperty = async function(propertyUuid, index) {

       let t = toRaw(currentTest.value)

       let value = localProperties.value[propertyUuid];

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           },
         query: {
           lang
         }
       })

       //console.log(customInputRefs.value[index + 1])

       customInputRefs.value[index + 1] && customInputRefs.value[index + 1].focus()
    }

    let resetTest = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'methods'
            },
          query: {
            lang
          }
        })
    }

    let resetSaved = async function() {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: null,
                distance_type: t.distance_type,
                remember: false
            }
        })

        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: ''
            },
          query: {
            lang
          }
        })
    }

    let switchToPropertiesView = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'properties'
            },
          query: {
            lang
          }
        })
    }

    onMounted(async () => {
        console.log(treatmentId)
        console.log('VISUS FERNE COMPONENT')
        console.log(route.params)

        store.dispatch(`app/setWizardDisabled`, false)

        //alert(subview)

        await store.dispatch(`tests/setCurrentTitle`, testLabel)

        currentTest.value.properties.forEach((p) => {

            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value === null ? p.default_value : p.value
            }

        })

        customMethodName.value = currentTest.value.method_name

        console.log(localProperties.value)

        if(currentTest.value.saved == true && subview == '') {
            switchToPropertiesView()
        }

        currentTest.value.test.methods.filter((m) => renderDynamicIcon(m.icon))
    })

</script>
<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

    .method-button.active {
        background: var(--color-primary-700);
    }

    .input-select {
        border: 1px solid var(--color-primary-500);
        padding: 8px 12px;
        border-radius: var(--radius-sm);
        min-width: 120px;
    }

    .button-primary span {
        font-family: 'Overpass', sans-serif;
        font-style: normal;
        /* height: 46px; */
        font-weight: 700;
        font-size: 18px;
        /* line-height: 18px; */
        line-height: 46px;
        border-radius: 4px;
        cursor: pointer;
        transition: all .13s ease-in;
    }
</style>