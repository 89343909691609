<template>
  <div :id="'file-' + upload.id" class="flex items-center justify-between h-14">
    <div>
      <UploaderProgress
        class="w-12 h-12"
        :progress="progress"
      />
    </div>
    <div class="flex flex-1 items-center justify-between">
      <div class="mb-2">
        <div class="font-medium mr-3 text-gray-700 leading-tight">
          {{ upload.file.name }}
        </div>

        <div class="text-gray-600 text-sm leading-tight">
          {{ sizeDisplay }} MB
        </div>
      </div>

      <div class="text-gray-600 align-baseline px-2">
        <template v-if="state === states.WAITING">Waiting</template>
        <template v-if="state === states.FAILED"><span class="text-danger">Failed</span></template>
        <template v-if="state === states.CANCELLED">Cancelled</template>
        <template v-if="state === states.COMPLETE">Complete</template>
        <template v-if="state === states.UNSUPPORTED"><span class="text-danger"></span>Sorry, this file type isn't supported</template>
        <template v-if="state === states.UPLOADING">
          <a
            href="#"
            class="brand"
            @click.prevent="cancel"
          >
            Cancel
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import options from '@/options.js'
  import states from '@/views/components/common/uploader/states.js'
  import UploaderProgress from '@/views/components/common/UploaderProgress.vue'

  axios.defaults.withCredentials = true
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  export default {
    components: {
      UploaderProgress
    },

    props: {
      upload: {
        required: true,
        type: Object
      },

      baseURL: {
        required: false,
        type: String,
        default: options.uploadBaseURL
      },

      endpoint: {
        required: true
      }
    },

    data () {
      return {
        state: null,
        progress: 0,

        axios: {
          cancel: null
        },

        states
      }
    },

    computed: {
      sizeDisplay () {
        return (this.upload.file.size / 1000000).toFixed(2)
      }
    },

    watch: {
      'upload.queued' (queued) {
        if (this.state === states.UNSUPPORTED) {
          return
        }

        if (queued === false) {
          this.startUpload()
        }
      },

      progress (progress) {
        this.$emit('progress', {
          id: this.upload.id,
          progress
        })
      },

      state (state) {
        this.$emit('change', {
          id: this.upload.id,
          state
        })

        switch (state) {  
          case states.CANCELLED:
          case states.FAILED:
            this.progress = 0
            break;
        }
      }
    },

    methods: {
      cancel () {
        this.axios.cancel()
      },

      makeFormData (file) {
        const form = new FormData()
        form.append('file', file, file.name)

        return form
      },

      handleUploadProgress (e) {
        this.progress = Math.round((e.loaded * 100) / e.total)
      },

      startUpload () {
        this.state = states.UPLOADING

        axios.post(this.endpoint, this.makeFormData(this.upload.file), {
          baseURL: this.baseURL,
          onUploadProgress: this.handleUploadProgress,
          cancelToken: new axios.CancelToken((token) => {
            this.axios.cancel = token
          })
        }).then(() => {
          this.state = states.COMPLETE
        }).catch((e) => {
          if (e instanceof axios.Cancel) {
            return this.state = states.CANCELLED
          }
          
          this.state = states.FAILED
        })
      }
    },

    mounted () {
      console.log(this.baseURL)
      console.log(this.endpoint)

      if (this.endpoint === null) {
        return this.state = states.UNSUPPORTED
      }

      this.state = states.WAITING
    }
  }
</script>
