<template>
  <main :class="{ 'padding-l-200': showMenu }" class="flex min-h-screen">
  <section class="menu-section" v-if="showMenu">
    <AppMenu />
  </section>

  <RouterView :key="$route.path"/>

  </main>


</template>

<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import AppMenu from '@/views/components/common/AppMenu.vue'


const store = useStore()
const router = useRouter()

onMounted(() => {
  console.log('on mounted')

})

const showMenu = computed(() => store.getters['app/showMenu'])

// remove when we will have app view
//router.push({ name: 'login' })

</script>

<style>
@import '@/assets/app.css';
</style>
