<template>
    <div v-if="disabled" class="custom-select w-36" :class="{ open: open, disabled: disabled }" >
      <div class="selected">
         <div class="selected-inner">{{ selected.name }}

            <div class="icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
            </div>

         </div>
      </div>
    </div>
    <div v-else class="custom-select w-36" :class="{ open: open, disabled: disabled }"  :tabindex="tabindex" @blur="open = false" >
      <div class="selected" :class="{ open: open }" @click="open = !open">
         <div class="selected-inner">{{ selected.name }}

            <div class="icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
            </div>

         </div>
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="

            $emit('input', {
              value: option.value,
              confirmed: function() {
                selected = option;
                open = false;
              },
              notConfirmed: function() {
              }
            })
          "
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true,
      },
      default: {
        type: Object,
        required: false,
        default: null,
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data() {
      return {
        selected: this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0].name
          : null,
        open: false,
      };
    }
  };
  </script>
  
  <style scoped>
  .custom-select {
    position: relative;
    text-align: left;
    outline: none;
    line-height: 47px;
  }
  .custom-select.disabled {
    opacity: 0.4;
  }
  .custom-select.disabled .icon {
    display: none;
  }
  .custom-select.open {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
  }

  .custom-select .selected {
    background-color: var(--color-primary-500);
    border-radius: var(--radius-lg);
    color: var(--color-shade-0);
    padding: 0 15px;
    cursor: pointer;
    user-select: none;
  }
  .custom-select .selected-inner {
    position: relative;
    font-weight: 700;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
  }
 
  .custom-select .selected.open {
    border-radius: 0;
    padding: 10px 10px;
    z-index: 2;
  }

  .custom-select .selected.open .selected-inner {
    border-bottom: 1px solid var(--color-shade-0);
  }
  
  .custom-select .selected-inner .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;
  }
  
  .custom-select .items {
    transition: all .05s ease-in-out;
    color: #fff;
    border-radius: 0px 0px var(--radius-lg) var(--radius-lg);
    overflow: hidden;
    position: absolute;
    background-color: var(--color-primary-500);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    left: 0;
    right: 0;
    z-index: 1;
  }
  
  .custom-select .items div {
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    padding-left: 1em;
    vertical-align: middle;
    height: 32px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  
  .custom-select .items div:hover {
    background-color: var(--color-primary-400);
  }
  
  .selectHide {
    display: none;
  }
  </style>
  